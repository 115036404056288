import CustomerSignUp from "Devices/Mobile/Pages/SignUp";
import BusinessPlan from "pages/Customer/BusinessPlan";
import CustomerProfile from "pages/Customer/Profile";
import MeetingFeedback from "pages/Feedback";
import JobDetail from "pages/JobDetail";
import EditTech from "pages/Technician/EditTech";
import React from "react";
import { isMobile } from "react-device-detect";
import { Route, Switch } from "react-router-dom";
import { CUSTOMER, TECHNICIAN } from "./constants";
import StepFour from "./Devices/Mobile/Pages/JobFlow/Steps/Four";

import StepOne from "./Devices/Mobile/Pages/JobFlow/Steps/One";
import StepThree from "./Devices/Mobile/Pages/JobFlow/Steps/Three";
import StepTwo from "./Devices/Mobile/Pages/JobFlow/Steps/Two";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import EmailVerification from "./pages/Auth/VerifyEmail";
import AcceptJob from "./pages/Customer/AcceptJob";
import CustomerProfileSetup from "./pages/Customer/ProfileSetup";
import JobSummary from "./pages/Customer/ProfileSetup/steps/JobSummary";
import Subscription from "./pages/Customer/Subscription";
import MainPage from "./pages/Dashboard";
import BillingReportsTech from "./pages/Dashboard/steps/billingReports";
import EarningsTech from "./pages/Dashboard/steps/earnings";
import JobReports from "./pages/Dashboard/steps/jobReports";
import NotFound from "./pages/NotFound";
import HelpCenter from "./pages/Support/HelpCenter";
import CustomerConfirmation from "./pages/Technician/CustomerConfirmation";
import JobAlert from "./pages/Technician/JobAlert";
import TechnicianProfile from "./pages/Technician/Profile";
import TechnicianRegister from "./pages/Technician/Register";
import TechUniqeLink from "./pages/Technician/TechnicianUniqueLink";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

const Routes = (props) => (
  <Switch>
    <PublicRoute
      exact
      path="/business-plan"
      component={BusinessPlan}
      props={props}
    />

    <PrivateRoute
      exact
      path="/buy-business-plan"
      component={BusinessPlan}
      props={props}
    />
    {/* { isMobile ? ( */}
    {/*   <PublicRoute */}
    {/*     exact */}
    {/*     path="/" */}
    {/*     component={StepOne} */}
    {/*     // component={CustomerRegister} */}
    {/*     props={props} */}
    {/*   /> */}
    {/* ) : <PublicRoute exact path="/" component={Login} props={props} />} */}
    {/* <PrivateRoute exact path="/" component={LandingPage} props={props} /> */}
    <PublicRoute exact path="/create-job" component={StepOne} props={props} />

    <PublicRoute exact path="/" component={Login} props={props} />
    <PublicRoute exact path="/login" component={Login} props={props} />
    <PublicRoute
      exact
      path="/forgot-password"
      component={ForgotPassword}
      props={props}
    />

    <PublicRoute
      exact
      path="/tech-profile/:uniqueName"
      component={TechUniqeLink}
      props={props}
    />
    <PrivateRoute
      exact
      path="/tech/:uniqueName"
      component={TechUniqeLink}
      props={props}
    />
    {/* <PrivateRoute
      exact
      path="/technician-details-setup"
      component={TechUniqeLink}
      props={props}
    /> */}

    <PublicRoute
      exact
      path="/reset-password"
      component={ResetPassword}
      props={props}
    />

    <PrivateRoute
      exact
      path="/verify-email"
      component={EmailVerification}
      props={props}
    />

    <PublicRoute
      exact
      permission={CUSTOMER}
      path="/customer/start-profile-setup/"
      component={CustomerProfileSetup}
      props={props}
    />

    <PrivateRoute
      exact
      path="/customer/profile-setup"
      permission={CUSTOMER}
      component={CustomerProfileSetup}
      props={props}
    />

    <PrivateRoute exact path="/dashboard" component={MainPage} props={props} />
    
    <PrivateRoute exact path="/dashboard/technician" component={MainPage} props={props} />

    <PrivateRoute
      exact
      path="/job-reports"
      component={JobReports}
      props={props}
    />

    <PrivateRoute
      exact
      path="/tech/earnings"
      component={EarningsTech}
      props={props}
    />

    <PrivateRoute
      exact
      path="/tech/billing-reports"
      component={BillingReportsTech}
      props={props}
    />

    <PublicRoute
      exact
      path="/customer/register/:jobId"
      component={BusinessPlan}
      // component={CustomerRegister}
      props={props}
    />

    <PublicRoute
      exact
      path="/customer/register/:jobId/:schedule"
      component={BusinessPlan}
      // component={CustomerRegister}
      props={props}
    />

    <PublicRoute
      exact
      path="/customer/register"
      component={BusinessPlan}
      // component={CustomerRegister}
      props={props}
    />

    <PublicRoute
      exact
      path="/customer/mobile-sign-up"
      component={CustomerSignUp}
      props={props}
    />

    <PrivateRoute
      exact
      path="/customer/mobile"
      component={CustomerSignUp}
      props={props}
    />

    <PrivateRoute
      exact
      path="/customer/registered"
      component={BusinessPlan}
      // component={CustomerRegister}
      props={props}
    />

    <PrivateRoute
      exact
      path="/customer/accept-job/:jobId"
      permission={CUSTOMER}
      component={AcceptJob}
      props={props}
    />

    <PrivateRoute
      exact
      path="/customer/card-detail-page"
      permission={CUSTOMER}
      component={MainPage}
      props={props}
    />

    <PrivateRoute
      exact
      path="/customer/subscriptions"
      permission={CUSTOMER}
      component={MainPage}
      props={props}
    />

    <PrivateRoute
      exact
      path="/technician/profile-edit"
      permission={TECHNICIAN}
      component={EditTech}
      props={props}
    />

    <PublicRoute
      exact
      path="/technician/register"
      component={TechnicianRegister}
      props={props}
    />

    <PrivateRoute
      exact
      path="/technician/register_steps"
      permission={TECHNICIAN}
      component={TechnicianRegister}
      props={props}
    />

    <PrivateRoute
      exact
      path="/technician/profile"
      permission={TECHNICIAN}
      component={TechnicianProfile}
      props={props}
    />

    <PrivateRoute
      exact
      path="/customer/profile"
      permission={CUSTOMER}
      component={CustomerProfile}
      props={props}
    />

    <PrivateRoute
      exact
      path="/technician/new-job/:jobId"
      permission={TECHNICIAN}
      component={JobAlert}
      props={props}
    />

    <PrivateRoute
      exact
      path="/technician/customer-confirmed"
      permission={TECHNICIAN}
      component={CustomerConfirmation}
      props={props}
    />

    <PrivateRoute
      exact
      path="/meeting-feedback/:jobId"
      component={MeetingFeedback}
      props={props}
    />

    <PrivateRoute
      exact
      path="/job-details"
      component={JobDetail}
      props={props}
    />

    <Route exact path="/help-center" component={HelpCenter} props={props} />

    <PrivateRoute
      exact
      path="/subscription"
      component={Subscription}
      props={props}
    />

    <PublicRoute
      exact
      path="/select-software"
      component={StepTwo}
      props={props}
    />

    <PublicRoute
      exact
      path="/select-problem"
      component={StepThree}
      props={props}
    />

    <PublicRoute
      exact
      path="/select-description"
      component={StepFour}
      props={props}
    />

    <PrivateRoute exact path="/post-job" component={StepOne} props={props} />

    <PrivateRoute
      exact
      path="/select-category"
      component={StepTwo}
      props={props}
    />

    <PrivateRoute
      exact
      path="/select-area"
      component={StepThree}
      props={props}
    />

    <PrivateRoute
      exact
      path="/issue-description"
      component={StepFour}
      props={props}
    />

    <Route component={NotFound} />
  </Switch>
);

export default Routes;
