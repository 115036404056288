import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import CheckBox from "../CheckBox";
import TimeDropDown from "../TimeDropDown";
import { useDetectClickOutside } from "react-detect-click-outside";
import { ReactSVG } from "react-svg";
import Globe from "../../../../../assets/images/globe.svg";
import { EST_DATE_OPTIONS } from '../../../../../constants/index.js'

const ScheduleForLater = ({
  scheduleJobTime,
  setScheduleJobTime,
  showSpinner,
  job,
  user,
  scheduleTimeInLocalTimezone,
  setScheduleTimeInLocalTimezone
}) => {
  const [value, setValue] = useState(scheduleJobTime.date);
  const [compareValue, setCompareValue] = useState();
  const [calendarValue, setCalendarValue] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [counter, setCounter] = useState(0);

  const handelCalender = (e) => {
    setValue(e);
    setScheduleJobTime((prevState) => ({ ...prevState, date: e }));
  };

  const onOutsideClick = () => {
    if (counter % 2 !== 0) setCounter(0);
    if (showCalendar && counter % 2 === 0) setShowCalendar(false);
  };

  const ref = useDetectClickOutside({ onTriggered: onOutsideClick });

  let todayDate = new Date();
  let todaydd = `${todayDate.getFullYear()}${todayDate.getMonth()}${todayDate.getDate()}`;

  useEffect(()=>{
    // console.log("My console for debugging 0", {scheduleJobTime})
    const hours24 = scheduleJobTime.durationType === "AM" ? scheduleJobTime.hours % 12 : (scheduleJobTime.hours % 12) + 12;
    const minutes = scheduleJobTime.minutes
    let selectedScheduleDateAndTime = scheduleJobTime.date
    selectedScheduleDateAndTime = scheduleJobTime.date.setHours(hours24)
    selectedScheduleDateAndTime = new Date(scheduleJobTime.date).setMinutes(minutes)
    // console.log("My console for debugging 1", {hours24, minutes, scheduleJobTime, "EST":new Date(scheduleTimeInLocalTimezone).toLocaleTimeString("en-US", EST_DATE_OPTIONS )})
    setScheduleTimeInLocalTimezone(new Date(selectedScheduleDateAndTime))
  },[scheduleJobTime])

  useEffect(() => {
    let todayCalenderValue = `${todayDate.getFullYear()}${value.getMonth()}${value.getDate()}`;
    setCompareValue(todayCalenderValue);
    setCalendarValue(
      `${value.toString().split(" ")[1]} ${value.toString().split(" ")[2]}`
    );
  }, [value]);

  let minArray = ["00", "15", "30", "45"];

  let hourArray = [];

  for (let i = 1; i <= 12; i++) {
    hourArray.push(String(i));
  }

  const handleTodayClick = () => {
    setShowCalendar(!showCalendar);
    setCounter(counter + 1);
    if (counter % 2 !== 0) setCounter(1);
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="flex-wrap" style={{ width: "100%", maxWidth: "600px" }}>
          <div className="d-flex justify-content-start flex-wrap mb-20">
            <div>
              <div>
                <label className="date-label-div ">Date:</label>
              </div>
              <div className="cursor-pointer">
                <div
                  className={`${
                    !showCalendar === false
                      ? "today-div-true d-flex justify-content-start align-items-center"
                      : "today-div d-flex justify-content-start align-items-center"
                  }`}
                  onClick={handleTodayClick}
                >
                  <span className="date-value ">
                    {compareValue === todaydd ? "Today" : calendarValue}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex mt-10-max-width-600 mb-30-max-width-600">
              <div>
                <div>
                  <label className="date-label-div ">Time:</label>
                </div>
                <div className="time-div d-flex justify-content-center align-items-center">
                  <TimeDropDown
                    dropdownValues={hourArray}
                    name={"hour"}
                    scheduleJobTime={scheduleJobTime}
                    setScheduleJobTime={setScheduleJobTime}
                    showSpinner={showSpinner}
                  />
                  <span className="colon-dropdown"> :</span>
                  <TimeDropDown
                    dropdownValues={minArray}
                    name={"minutes"}
                    scheduleJobTime={scheduleJobTime}
                    setScheduleJobTime={setScheduleJobTime}
                    showSpinner={showSpinner}
                  />
                </div>
              </div>
              <div className="check-box">
                <CheckBox
                  scheduleJobTime={scheduleJobTime}
                  setScheduleJobTime={setScheduleJobTime}
                  showSpinner={showSpinner}
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-8 w-100percent">
            <ReactSVG src={Globe} />
            <div className="d-flex flex-column">
              {/* <span className="localTime text-left" >Geeker Business Hours</span> */}
              <span className="localTime text-left white-space-break" >{`Eastern Standard Time (EST): `+new Date(scheduleTimeInLocalTimezone).toLocaleTimeString("en-US", EST_DATE_OPTIONS )}</span>
            </div>
          </div>

          {showCalendar && (
            <div className="calendar-container" ref={ref}>
              <Calendar
                tileDisabled={({ date }) => [0, 6].includes(date.getDay())}
                tileClassName={({ date, view }) => {
                  if (view === "month" && ![0, 6].includes(date.getDay())) {
                    return "allowed-date";
                  }
                  return "disabled-date";
                }}
                onChange={(e) => {
                  handelCalender(e);
                }}
                value={value}
                maxDate={
                  new Date(new Date().setMonth(new Date().getMonth() + 2))
                }
                minDate={new Date()}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleForLater;
