import apiClient, { meetingApiClient } from "./index";

/**
 * Get Discount List
 * @params : params(Type:Object)
 * @response : Get list of discounts in table
 * @author Sahil
 **/

export async function getReferalDiscountHistory(params) {
  try {
    return apiClient
      .get("/referalDiscount/discount-history", { params })
      .then((response) => {
        return response.data;
      });
  } catch (err) {
    console.log("error in getReferalDiscountHistory :::::: ", err);
  }
}

/**
 * Get Total Referal Amount
 * @params : data(Type:Object)
 * @response : Calls the api which gets the total referal amount
 * @author  Sahil
 */
export async function getTotalReferalAmount(data) {
  try {
    return apiClient
      .post("/referalDiscount//total-referal", data)
      .then((response) => {
        return response.data;
      });
  } catch (err) {
    console.log("Error in getTotalReferalAmount ::: ", err);
  }
}