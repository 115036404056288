import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { Col } from "antd";

const PriorOrExistedUser = ({ priorOrExistedUSer }) => {
  return (
    <React.Fragment>
      <Accordion
        className="priorOrExistedUSer-list-style"
        style={{ boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#FDEDED" }}
        >
          <Typography>
            <InfoIcon style={{ color: "#DD0000" }} /> Some users have already
            been registered or invited.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableListing priorOrExistedUSer={priorOrExistedUSer} />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

const TableListing = ({ priorOrExistedUSer }) => {
  const renameStatusUsingValue = (value) => {
    if (value == "already-existed") {
      return "User with this email already existed.";
    }
    if (value == "already-invited") {
      return "User with this email is already invited.";
    } else {
      return "-";
    }
  };

  return (
    <Col md="12" className="py-4 mt-1 table-responsive">
      <Col xs="12" className="table-structure-outer table-responsive">
        <table className="w-100">
          <thead>
            <tr>
              <th className="label-name">Email</th>
              <th className="label-name">Reason of failure</th>
            </tr>
          </thead>
          <tbody>
            {priorOrExistedUSer &&
              priorOrExistedUSer.length > 0 &&
              priorOrExistedUSer.map((element) => (
                <tr>
                  <td className="cell-value"> {element.email}</td>
                  <td className="cell-value">
                    {renameStatusUsingValue(element.status)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Col>
    </Col>
  );
};

export default PriorOrExistedUser;
