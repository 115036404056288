import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Row, Modal, Button } from "react-bootstrap";
import { Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Spin } from "antd";
import * as JobApi from "../../../../api/job.api";
import { openNotificationWithIcon } from "../../../../utils";

const InternalNote = ({ user, job }) => {
  const [textArea, setTextArea] = useState("");
  const [allNotesData, setAllNotesData] = useState([]);
  const [fetchAllNotes, setFetchAllNotes] = useState([]);
  const [isEditNotes, setIsEditNotes] = useState(false);
  const [updateTextId, setUpdateTextId] = useState(null);
  const notesContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showErrorMess, setShowErrorMess] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState(null);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const [characterCount, setCharacterCount] = useState(textArea.length);
  const [charLeftColor, setCharLeftColor] = useState(false);
  const [count, setCount] = useState(500);
  const [scrollToNotes, setScrollToNote] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (user.id === job.technician.user.id) {
          let updatedNotes = [];
          if (!updateTextId) {
            if (allNotesData && allNotesData.length > 0) {
              let existingJob = await JobApi.retrieveJob(job.id);

              if (
                existingJob &&
                existingJob?.technician_notes.length > 0 &&
                existingJob.technician_notes
              ) {
                const newNotes = allNotesData.filter((newNote) => {
                  return !existingJob.technician_notes.some(
                    (existingNote) => existingNote.textArea === newNote.textArea
                  );
                });
                const isNotesIncluded = existingJob.technician_notes.some(
                  (noteObj) => noteObj.textArea === textArea.trim()
                );
              
                if (isNotesIncluded) {
                  openNotificationWithIcon(
                    "error",
                    "Error",
                    "Note already exist"
                  );
                }
                // console.log("Submit notes>>>>>>>>>>>>>>>  insert")
                updatedNotes = existingJob.technician_notes.concat(newNotes);
              } else {
                // console.log("Submit notes>>>>>>>>>>>>>>> new insert")
                updatedNotes = allNotesData;
              }
              const updateResponse = await JobApi.updateJob(job.id, {
                technician_notes: updatedNotes,
              });
              if (updateResponse && updateResponse.technician_notes) {
                console.log("Response after submit new notes>>>>>>>>>",updateResponse.technician_notes)
                setLoading(false);
                setTextArea("");
                setShowErrorMess(false);
                setCharLeftColor(false);
                setCount(500);
                setCharacterCount(0);
                setFetchAllNotes(updateResponse.technician_notes);
                setScrollToNote(true);
                setAllNotesData([])
              }
            }
          }
        }
      } catch (error) {
        console.error("Error occurred while updating job:", error);
      }
    })();
  }, [allNotesData]);

  useEffect(() => {
    if (scrollToNotes) {
      if (notesContainerRef && notesContainerRef.current) {
        const notesContainer = notesContainerRef.current;
        if (notesContainer) {
          notesContainer.scrollTop = notesContainer.scrollHeight;
          setScrollToNote(false);
        }
      }
    }
  }, [scrollToNotes, allNotesData]);

  useEffect(() => {
    (async () => {
      try {
        if (user?.id === job?.technician?.user?.id) {
          const jobInfoResponse = await JobApi.retrieveJob(job.id);
          // const jobInfo = jobInfoResponse ? jobInfoResponse : {};
          if (
            jobInfoResponse &&
            jobInfoResponse?.technician_notes.length > 0 &&
            jobInfoResponse?.technician_notes
          )
            // console.log(
            //   "technician inside fetch all jobInfoResponse>>>>>>>>>",
            //   jobInfoResponse.technician_notes
            // );
          setFetchAllNotes(jobInfoResponse.technician_notes);
        }
      } catch (error) {
        console.error("Error occurred while fetching job:", error);
      }
    })();
  }, [job]);

  const handleOnChange = async (e) => {
    const data = e.target.value;
    if (data == "") {
      setTextArea("");
      setCharacterCount(0);
    } else if (data.length > 500) {
      setCharLeftColor(true);
    } else {
      setShowErrorMess(false);
      setCharLeftColor(false);
      setCharacterCount(data.length);
      setTextArea(data);
    }
  };

  const customButtonStyle = {
    backgroundColor: "#1bd4d5",
    color: "#fff",
    height: "40px",
    width: "100px",
    border: "none",
  };

  const handleSubmit = async () => {
    setLoading(true);
    const enterText = textArea.trim()
    if (enterText === "") {
      setShowErrorMess(true);
      setLoading(false);
      return;
    } else if (updateTextId) {
      let temp = fetchAllNotes.map((elem, index) => {
        if (elem.id === updateTextId) {
          return { ...elem, textArea: enterText};
        }
        return elem;
      });
      try {
        if (user.id === job.technician.user.id) {
          const updateResponse = await JobApi.updateJob(job.id, {
            technician_notes: temp,
          });
          if (updateResponse && updateResponse.technician_notes) {
            setLoading(false);
            setIsEditNotes(false);
            setTextArea("");
            setShowErrorMess(false);
            setCharLeftColor(false);
            setUpdateTextId(null);
            setCount(500);
            setCharacterCount(0);
            setFetchAllNotes(updateResponse.technician_notes);
            setAllNotesData([]);
          }
        }
      } catch (error) {
        console.error("Error occurred while updating job:", error);
      }
    } else {
      setAllNotesData((prev) => [...prev, { id: prev.length + 1, textArea:enterText }]);
    }
  };

  const handleOnDelete = async (id) => {
    setNoteIdToDelete(id);
    setShowDeleteModal(true);
    setShowErrorMess(false);
  };

  const confirmDelete = async () => {
    setShowDeleteLoader(true);
    const updatedJob = await JobApi.removeInternalNoteById(noteIdToDelete);
    // console.log(
    //   "technician inside RemainingNotes>>>>>>>>>",
    //   updatedJob?.remainingNotes
    // );
    if (updatedJob && updatedJob?.remainingNotes) {
      if (updatedJob && updatedJob?.remainingNotes) {
        setIsEditNotes(false);
        setShowDeleteModal(false);
        setShowDeleteLoader(false);
        setFetchAllNotes(updatedJob?.remainingNotes);
        setAllNotesData([]);
      }
    }
  };

  const handleOnEdit = async (id) => {
    setIsEditNotes(true);
    setUpdateTextId(id);
    const findData = fetchAllNotes.find((prev) => prev.id === id);
    if (findData && findData.textArea !== "") {
      setTextArea(findData.textArea);
      setCharacterCount(findData.textArea.length);
      setShowErrorMess(false);
    }
  };

  const columns = [
    {
      title: "Internal Notes",
      dataIndex: "textArea",
      width: "80%",
      key: "textArea",
      render: (text) => (
        <ul>
          {text ? (
            <>
              {text.split().map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </>
          ) : (
            ""
          )}
        </ul>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) => {
        if (id) {
          return (
            <>
              <div className="tech-notes-action-btn">
                <div
                  className={
                    isEditNotes
                      ? "notes-edit-icon-div mr-3 action-btn-disable"
                      : "notes-edit-icon-div mr-3"
                  }
                  onClick={isEditNotes ? null : () => handleOnEdit(id)}
                  title="Edit Note"
                >
                  <FontAwesomeIcon
                    className={
                      isEditNotes
                        ? "action-btn-disable tech-notes-editIcon"
                        : "tech-notes-editIcon notes-cursor-allowed"
                    }
                    icon={faPencilAlt}
                  />
                </div>
                <div
                  onClick={isEditNotes ? null : () => handleOnDelete(id)}
                  title="Delete Note"
                >
                  <FontAwesomeIcon
                    className={
                      isEditNotes
                        ? "action-btn-disable tech-notes-deleteIcon"
                        : "tech-notes-deleteIcon notes-cursor-allowed"
                    }
                    icon={faTrash}
                  />
                </div>
              </div>
            </>
          );
        }
      },
    },
  ];

  return (
    <>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this note?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
            disabled={showDeleteLoader}
          >
            No
          </Button>

          <Button
            variant="danger"
            onClick={confirmDelete}
            disabled={showDeleteLoader}
          >
            {showDeleteLoader ? (
              <Spin className="spinner spinner-pos" />
            ) : (
              "Yes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        {fetchAllNotes && fetchAllNotes.length > 0 && (
          <div>
            <Col className="ant-table-structure-outer tech-notes-list table-responsive p-0">
              <div
                ref={notesContainerRef}
                style={{ height: `calc(35rem - 110px)`, overflowY: "scroll" }}
              >
                <Table
                  dataSource={fetchAllNotes}
                  pagination={false}
                  columns={columns}
                  rowKey={(record) => record.id}
                  className="tech-notes-table"
                />
              </div>
            </Col>
            <Row>
              <Col>
                <hr className="my-3" />
              </Col>
            </Row>
          </div>
        )}
        <div className="editor-heading-tech-note">Add Your Note Here</div>
        <Row>
          <Col xs={12} className="mt-2">
            <textarea
              className="form-control textArea-field"
              value={textArea}
              onChange={handleOnChange}
              spellCheck="true"
              placeholder="Add Note.."
            ></textarea>
            <div className="text_validation mt-2">
              {showErrorMess && (
                <span style={{ color: "red", fontSize: "medium" }}>
                  Please enter description.
                </span>
              )}
              <span
                className={`characterCounts ${
                  charLeftColor ? "red-character" : " "
                }`}
              >
                {" "}
                ({`${count - characterCount} character left`}){" "}
              </span>
            </div>
          </Col>
        </Row>
        <div className="mt-3 float-right">
          <Button
            style={customButtonStyle}
            className="tech-notes-submit-div"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? (
              <Spin className="spinner spinner-pos" />
            ) : (
              <>{isEditNotes ? "Update" : "Submit"}</>
            )}
          </Button>
        </div>
      </Container>
    </>
  );
};

export default InternalNote;
