import React, { memo, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as Antd from "antd";
import { useHistory } from "react-router-dom";
import H1 from "../../../components/common/H1";
import { useLocation } from "react-router";
import messages from "./messages";
import SubHeadingText from "pages/Customer/BusinessPlan/Components/SubHeadeingText";
import styled from "styled-components";
// import queryString from 'query-string';
import { LayoutMax } from "../../../components/Layout";
import Header from "../../../components/NewHeader";
import FormItem from "../../../components/FormItem";
import InputPassword from "../../../components/AuthLayout/InputPassword";
import Footer from "../../../components/AuthLayout/Footer";
import Link from "../../../components/AuthLayout/Link";
import { useAuth } from "../../../context/authContext";
import { openNotificationWithIcon } from "../../../utils";
import ValidatorModel from "components/AuthComponents/ValidatorModel";
import PasswordValidator from "components/AuthComponents/PasswordValidator";
function ChagePasswordPage() {
  const { ChangePasswordHandler } = useAuth();
  const [pwModelVisible, setPwModelVisible] = useState();
  const [redValidations, setRedValidations] = useState({
    isPasswordValid: false,
    // Add more validation flags as needed
  });
  const [userInfo, setUserInfo] = useState({
    password: "",
  });
  const [alertMessagePassword, setAlertMessagePassword] = useState("");
  // const [token, setToken] = useState('');
  const loading = false;
  // const {t} = useParams();
  const isValidToken = true;
  const location = useLocation();
  const history = useHistory();
  let tk = "";
  const updateValidation = (fieldName, isValid) => {
    setRedValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: isValid,
    }));
  };
  const handleChange = (e) => {
    
 
    if (e.target.name === "password") {
      updateValidation("isPasswordValid", false);
    }
 
    const { name, value } = e.target;
  
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value.trim(),
     
    }));
  };
  const onSendResetRequest = (value) => {
    if (alertMessagePassword.length > 0) {
      openNotificationWithIcon(
        "info",
        "Info",
        "For your security, we need your password to be a minimum of 6 characters, a number, and a special character."
      );
      return;
    } else {
      updateValidation("isPasswordValid", false);
    }
    // console.log(">>>!>>>!!>>>>");
    onResetCall();
 
    if (location.search) {
      let params = new URLSearchParams(location.search);
 
      tk = params.get("t");
      ChangePasswordHandler({ ...value,password:userInfo.password, token: tk });
      openNotificationWithIcon(
        "success",
        "Success",
        "Your Password has been successfully changed"
      );
     history.push("/");
    }
  };
 
  /*const verifyToken = useCallback(
    value => {
    },
    [],
  );*/
 
  const onResetCall = useCallback((value) => {}, []);
 
  return (
    <Container >
      <LayoutMax bg="transparent" style={{display:'block'}}>
        <Header link="/" />
        <LayoutMax.Content className="items-center">
          {loading && (
            <H1>
              <FormattedMessage {...messages.loading} />
            </H1>
          )}
          {!loading && !isValidToken && (
            <H1>
              <FormattedMessage {...messages.notValidToken} />
             
            </H1>
          )}
          {!loading && isValidToken && (
          
            <React.Fragment key="isValidToken">
              <h1>Reset Password</h1>
              <Antd.Form className="items-center" onFinish={onSendResetRequest}>
                
                <div className="mb-20 w-428 max-width-768-w-100per position-relative ">
              <FormItem
                  name="password"
                  label="Password"
                  className="d-flex flex-column  "
                 
                  >
                  <InputPassword
                    id="password-input"
                    name="password"
                    placeholder="Password"
                    type="password"
                    onFocus={() => setPwModelVisible(true)}
                    onBlur={() => setPwModelVisible(false)}
                    value={userInfo.password}
                    onChange={handleChange}
                  
                  />
                  <ValidatorModel visible={pwModelVisible}>
                    <PasswordValidator
                      inputText={userInfo.password}
                      setAlertMessagePassword={setAlertMessagePassword}
                    />
                  </ValidatorModel>
                  </FormItem>
           
                {/* <div className="mb-20 w-428 max-width-768-w-100per position-relative">
                  <InputPassword
                    name="password"
                    size="large"
                    placeholder="Password"
                    border="0px none"
                    borderbottom="1px solid gray"
                    border_radius="0px"
                  />
                   <ValidatorModel visible={pwModelVisible}>
                    <PasswordValidator
                      inputText={userInfo.password}
                      setAlertMessagePassword={setAlertMessagePassword}
                    />
                  </ValidatorModel>
                  </div> */}
 
                <FormItem
                  name="confrim_password"
                  label="Confirm Password"
                  className="d-flex flex-column "
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if ( userInfo.password === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <InputPassword
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    />
                </FormItem>
                </div>
                <button
                  type="Primary"
                  size="large"
                  className="reset-btn app-btn reset-button-handle"
                  htmlType="submit"
                  loading={loading}
                >
                  Reset Password
                </button>
              </Antd.Form>
            </React.Fragment>
          )}
          <div>
            <Footer>
              <span>
                <FormattedMessage {...messages.needAnAccount} />
                &nbsp;
              </span>
              <span  >
              <Link to="/register"  style={{ color: "#1bd4d5" }}>
                <FormattedMessage {...messages.register} />
              </Link>
              &nbsp;
          <span  style={{ color: "#1bd4d5" }} >/</span>
          &nbsp;
            <Link to="/login" style={{ color: "#1bd4d5" }}>
              <FormattedMessage {...messages.btnLogin} />
            </Link>
            </span>
            </Footer>
          </div>
        </LayoutMax.Content>
        <div />
      </LayoutMax>
    </Container>
  );
}
 
const Container = styled.div`
  // width: 40%;
   margin: 0 auto;
`;
 
ChagePasswordPage.propTypes = {};
 
export default memo(ChagePasswordPage);