import "./logrocketSetup";
import "./fullStorySetup";
import React, { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import styled from "styled-components";
import mixpanel from "mixpanel-browser";
import GlobalStyle from "./global-styles";
import Routes from "./router";
import "sanitize.css/sanitize.css";
import "antd/dist/antd.css";
import "./style.css";
import { useAuth } from "./context/authContext";
import { useSocket } from "./context/socketContext";
import { MIXPANEL_KEY, GOOGLE_ANALYTICS_PROPERTY_ID, SECRET_KEY } from "./constants";
import LogRocket from "logrocket";
import ReactGA from "react-ga";
import * as FullStory from "@fullstory/browser";
import Cookies from "js-cookie";
import { getTechnicianDetailesByUserId } from "api/technician.api";

const crypto = require("crypto");
const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY;
var isiDevice = /ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase());

mixpanel.init(MIXPANEL_KEY, { debug: true });
let currSeconds = 0;

const App = (props) => {
  const { user } = useAuth();
  const { socket } = useSocket();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const tech_id = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const medium =  queryParams.get("medium")
  ? queryParams.get("medium")
  : false;
  const checkJobIdFromUrl = queryParams.get("q");
  if(checkJobIdFromUrl){
    localStorage.setItem("shortlink",queryParams.get("q"));

  }
  
  useEffect(()=>{
      if(tech_id  && medium && location.pathname.includes("technician-details-setup")){
        getTechDetails(tech_id)
      }
      let techSearchPage = queryParams.get("page") && queryParams.get("page") === 'tech-search';
    
      console.log("Current page is ",queryParams.get("page")," and is it techSearchPage page ::",techSearchPage,queryParams.get("q"))
      let hasUser = (user && user !== undefined)
      let validPath = (location && location.pathname && (location.pathname === '/' || location.pathname === '/dashboard' || location.pathname === '/dashboard/' || techSearchPage))
      console.log("location.pathname:: ",location.pathname," and path is valid ::",validPath)
      if (hasUser && validPath &&
        !isiDevice &&
        "Notification" in window &&
        "serviceWorker" in navigator &&
        "PushManager" in window
      ) {
        var permission = Notification.permission;
        window.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "d",
            ctrlKey: true,
          })
        );
        console.log("permission>>>>>>>>>>>>",permission)
        
        if (permission === 'default') {
          if ("requestPermission" in Notification) {
            Notification.requestPermission();
          }
        }
      }
  },[user])

  const getTechDetails=async()=>{
    try {
     const user_data = await getTechnicianDetailesByUserId(tech_id);
    //  console.log("user_datauser_datauser_data",user_data)
     const uniqueName = user_data?.uniqueName
     if(uniqueName){
       window.location.href = `/tech/${uniqueName}`;
     }
      
    } catch (error) {
       console.log("error while fetchning unique name",error)
    }
  }

  const setCurrentSeconds = () => {
    currSeconds = currSeconds + 1;
    if (currSeconds === 43200) {
      if (window.localStorage.tetch_token !== "") {
        window.localStorage.tetch_token = "";
        window.location.reload("/");
      }
    }
  };

  // check ConnectionSpeed
  const connectionSpeed = navigator.connection
    ? navigator.connection.downlink
    : null;
  if (connectionSpeed < 2) {
    console.log("Internet connection slow ", connectionSpeed);
    mixpanel.track("Internet Connection - Slow", { speed: connectionSpeed });
  }
  if (connectionSpeed < 8 && connectionSpeed > 2) {
    console.log("Internet connection is average ", connectionSpeed);
    mixpanel.track("Internet Connection - Average ", {
      speed: connectionSpeed,
    });
  }
  if (connectionSpeed > 8) {
    console.log("Internet Connection - Good", connectionSpeed);
  }

  if (connectionSpeed > 8) {
    console.log("Internet Connection - Good", connectionSpeed);
  }
  window.onunload = function (e) {
    // console.log('>i am running');
    e.preventDefault();
    // alert("wait")
    // console.log('wait.......');
  };

  window.document.onload = () => {
    // console.log('>> New on load function >>>');
    currSeconds = 0;
  };
  document.body.onmousemove = () => {
    currSeconds = 0;
  };

  document.body.onclick = () => {
    currSeconds = 0;
  };

  useEffect(() => {
    if (user) {
      socket.emit(
        "loggedIn",
        {
          userId: user.id,
          userType: user.userType,
          user,
        },
        (confirmation) => {
          console.log(
            confirmation,
            "this is the confirmation that the code is running"
          );
        }
      );
    }

    let timer = setInterval(() => {
      setCurrentSeconds();
    }, 1000);
  }, [socket, user]);

  useEffect(() => {
    if (socket && user) {
      socket.on("deleteInviteUsers-backend", (data) => {
        if (data && data.includes(user.email)) {
          window.location.reload();
        }
      });
    }
  }, [user, socket]);

  const theme = "#fff";

  const pageAccessedByReload =
    (window.performance && window.performance === 1) ||
    window.performance
      .getEntriesByType("navigation")
      .map((nav) => nav.type)
      .includes("reload");

  useEffect(() => {
    if (user) {
      mixpanel.identify(user?.email);
      if (pageAccessedByReload) {
        if (pageAccessedByReload && user.userType === "customer") {
          console.log(">>>PageReload>>>> Customer - Reload the page.");
          mixpanel.track(`${user.userType} - Reload the page.`);
        } else {
          console.log(">>>PageReload>>>>  Technician - Reload the page.");
          mixpanel.track(`${user.userType}- Reload the page.`);
        }
      } else {
        console.log(
          ">>>PageReload>>>> Page reload through code for ",
          user.userType
        );
        mixpanel.track("Page reload through code.");
      }
    }
  }, [pageAccessedByReload, user]);

  useEffect(() => {
    if (user) {
      const token = localStorage.getItem(SECRET_KEY);
      Cookies.set("user_access", token, {
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });

      let hash = crypto
        .createHmac("sha256", secretKey)
        .update(user?.email)
        .digest("hex");

      // Init Pendo
      pendoScript(user);
      
      //Init intercom chat
      intercomScript(user, hash);

      //Init Logrocket
      LogRocket.identify(user?.id, {
        name: user?.firstName,
        email: user?.email,
      });

      //Init Fullstory
      FullStory.identify(user?.id, {
        displayName: user?.firstName,
        email: user?.email,
        userType: user?.userType,
      });

      //Init google-analytics
      if (GOOGLE_ANALYTICS_PROPERTY_ID != null) {
        ReactGA.initialize(GOOGLE_ANALYTICS_PROPERTY_ID);
        ReactGA.ga("set", "userId", user?.id);
        ReactGA.ga("set", "dimension1", user?.id);
        ReactGA.ga("send", "pageview", "/dashboard");
      }
    } else {
      //Init intercom chat
      intercomScript({}, null);
    }
  }, []);



  const intercomScript = (user, hash) => {
    try {

      if(process.env.REACT_APP_URL && process.env.REACT_APP_URL === 'https://app.geeker.co'){
        // console.log("Intercom Script called ",user, hash)
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: process.env.REACT_APP_INTERCOM_ID,
          name: user?.firstName + " " + user?.lastName, // Full name
          email: user?.email, // Email address
          created_at: user?.createdAt, // Signup date as a Unix timestamp
          user_hash: hash,
        });
      }
    } catch (err) {
      console.log("error in intercomScript configuration ::::", err);
    }
  };

  const pendoScript = (user) => {
    try {
      if(process.env.REACT_APP_URL && process.env.REACT_APP_URL === 'https://app.geeker.co'){
        window.pendo.initialize({
          visitor: {
            id: user?.id,
            email: user?.email,
            role: user?.userType,
          },
          account: {
            id:
              user?.userType === "customer"
                ? user?.customer?.id
                : user?.technician?.id,
            is_paying: user?.userType === "customer",
            creationDate: user?.createdAt,
          },
        });
      }
    } catch (err) {
      console.log("error in pendoScript configuration ::::", err);
    }
  };

  const appTheme = "ThemeLight";

  return (
    // Commented by @Vinit on 22/03/2023
    // <Provider config={rollbarConfig} >
    <AppWrapper id={appTheme} theme={theme}>
      <GlobalStyle />
      <Routes {...props} />
      {/*<ChatGPTButton />*/}
    </AppWrapper>
    // </Provider>
  );
};

const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  height: 100vh;
  flex-direction: column;
`;

export default withRouter(App);
