import React, { useEffect, useState } from "react";
import { useUser } from "../context/useContext";
import { getPartnerWithShortName } from "api/partner.api";
import { openNotificationWithIcon } from "utils";
import logoimage from "../assets/images/newLogo.png";


const GeekerLogo = (props) => {
  const [usingCoBrandingLogo, setUsingCoBrandingLogo] = useState(false);
  const [logo, setLogo] = useState(() => {
    const coBrandingLogoUrl = localStorage.getItem("coBrandingLogoUrl");
    return (
      coBrandingLogoUrl ||
      props?.logo ||
      // "https://winkit-software-images.s3.amazonaws.com/geeker_logo.png"
      logoimage
    );
  });
  const { user } = useUser();
  const [technicianRegisterPage, setTechnicianRegisterPage] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/technician/register") {
      setTechnicianRegisterPage(true);
    }
  }, []);

  useEffect(() => {
    const fetchCoBrandingLogo = async () => {
      try {
        const coBrandingLogoUrl = localStorage.getItem("coBrandingLogoUrl");
        if (coBrandingLogoUrl) {
          setUsingCoBrandingLogo(true);
        }
        if (!coBrandingLogoUrl) {
          const urlParams = new URLSearchParams(window.location.search);
          const partnerName = urlParams.get("partner") || user?.partner;

          if (partnerName && user?.userType !== "technician") {
            const response = await getPartnerWithShortName(partnerName);
            if (response.success) {
              localStorage.setItem("partner", partnerName);
              const newCoBrandingLogoUrl = response.data.coBrandingLogoUrl;
              setLogo(newCoBrandingLogoUrl);
              localStorage.setItem("coBrandingLogoUrl", newCoBrandingLogoUrl);
              localStorage.setItem(
                "hiddenSoftwares",
                JSON.stringify(response.data.softwares)
              );
              if (response.data.promoCode) {
                localStorage.setItem("partnerPromoCode", response.data.promoCode);
              }
              setUsingCoBrandingLogo(true);
            } else {
              openNotificationWithIcon("error", "Error", "This partner does not exist");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCoBrandingLogo();
  }, []);

  // remove the logo from local storage when user is technician and if when register page is opened in /technician/register
  useEffect(() => {
    if (technicianRegisterPage || user?.userType === "technician") {
      setUsingCoBrandingLogo(false);
      setLogo(props?.logo || logoimage);
      localStorage.removeItem("coBrandingLogoUrl");
      localStorage.removeItem("partner");
      localStorage.removeItem("hiddenSoftwares");
    }
  }, [technicianRegisterPage, user, props?.logo]);

  return (
    <img
      className={props.className}
      src={logo}
      alt="GeekerLogo"
      style={
        usingCoBrandingLogo ?
          { width: props.CoBrandingWidth ? props.CoBrandingWidth : "100%", ...props.styles } :
          { width: props.width ? props.width : "", ...props.styles }
      }
    />
  );
};

export default GeekerLogo;
