import React from "react";
import logo from "../../../../assets/images/newLogo.png";
import GeekerLogo from "utils/GeekerLogo";

const Header = () => {
  return (
    <div>
      <GeekerLogo className="business-plan-header-img" logo={logo} CoBrandingWidth="261px"/>
    </div>
  );
};

export default Header;
