import styled from "styled-components";
import * as Antd from "antd";

const Checkbox = styled(Antd.Checkbox)`
  font-size: 18px !important;
  font-weight: bold;
  .ant-checkbox {
    marginright: 10px;
  }
`;

export default Checkbox;
