import crypto from "crypto";
import React, { createContext, useEffect } from "react";

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY;

const IntercomChatContext = createContext(null);

// Intercom chat
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/wn9wvddg'
(function () {
  let w = window;
  let ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    let d = document;
    let i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    let l = function () {
      let s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/wn9wvddg";
      let x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
})();

const intercomScript = (userInfo, hash) => {
  if(process.env.REACT_APP_URL && process.env.REACT_APP_URL === 'https://app.geeker.co'){
    console.log("Intercom Script called ", userInfo, hash);
    let userFirstName = userInfo && userInfo.firstName ? userInfo.firstName : "";
    let userLastName = userInfo && userInfo.lastName ? userInfo.lastName : "";
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "wn9wvddg",
      name: userFirstName + " " + userLastName, // Full name
      email: userInfo?.email, // Email address
      created_at: userInfo?.createdAt, // Signup date as a Unix timestamp
      user_hash: hash,
    });
  }
};

function IntercomChatProvider({ children, user }) {
  useEffect(() => {
    //User hash for Intercom
    const crypto = require("crypto");
    let hash = null;

    if (user) {
      hash = crypto
        .createHmac("sha256", secretKey)
        .update(user?.email)
        .digest("hex"); // secret key (keep safe!)
      intercomScript(user, hash);
    } else {
      intercomScript({}, null);
    }
    const userIdentifier = user?.email; // user's email address

    if (userIdentifier != null || userIdentifier !== undefined) {
      // console.log("UI::: ", userIdentifier);
      crypto
        .createHmac("sha256", secretKey)
        .update(userIdentifier)
        .digest("hex");
    }
  }, []);

  return (
    <IntercomChatContext.Provider value={user}>
      {children}
    </IntercomChatContext.Provider>
  );
}

function useIntercomContext(props) {
  const context = React.useContext(IntercomChatContext);
  if (context === undefined) {
    throw new Error("useMobile must be used within a MobileProvider");
  }
  return context;
}

export { IntercomChatProvider, useIntercomContext };
