import styled from "styled-components";

const ButtonContainer = styled.div`
  padding: 3px 15px 3px 15px;
  border-radius: 15px;
  max-width: ${document.body.clientWidth / 2 + 55}px;
  background: #ffffff;
  border: 2px solid rgba(1, 212, 213, 0.7);
  display: flex;
  justify-content: center;
  text-align: center;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  svg {
    width: 28px;
    height: 28px;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { ButtonContainer, Group };
