import { getUserIdFromCookie } from "utils";
import apiClient from "./index";
/**
 * To create stripe account
 * @params : data(Type:Object),
 * @response : add data in JobLifecycle table
 * @author : Sahil Sharma
 **/
export async function createStripeAccount(data) {
  try {
    const userId = getUserIdFromCookie()
    return apiClient.post(`/stripeAccount/create?cuId=${userId}`, data).then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
  } catch (err) {
    console.log(err);
  }
}

/**
 * To fetch EarningDetails By Paycycle
 * @params : data(Type:Object),
 * @response : add data in JobLifecycle table
 * @author : Sahil Sharma
 **/
export async function getEarningDetailsByPaycycle(userId, techId) {
  try {
    const userId = getUserIdFromCookie()
    return apiClient
      .get(`/stripeAccount/pacycles/${userId}/${techId}?cuId=${userId}`)
      .then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
  } catch (err) {
    console.log(err);
  }
}

/**
 * To Generate Link For  Stripe Account Onboarding
 * @params : data(Type:String),
 * @response : get Detail Submission Status
 * @author : Sahil Sharma
 **/
export async function generateLink(data) {
  try {
    const userId = getUserIdFromCookie()
    return apiClient
      .post(`/stripeAccount/generate-account-link?cuId=${userId}`, data)
      .then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
  } catch (err) {
    console.log(err);
  }
}

/**
 * To get Detail Submission Status
 * @params : data(Type:String),
 * @response : get Detail Submission Status
 * @author : Sahil Sharma
 **/
export async function getAccountDetailSubmission(data) {
  try {
    const userId = getUserIdFromCookie()
    return apiClient
      .post(`/stripeAccount/account-status?cuId=${userId}`, data)
      .then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
  } catch (err) {
    console.log(err);
  }
}

/**
 * To get login link
 * @params : data(Type:String),
 * @response : get Detail Submission Status
 * @author : Sahil Sharma
 **/
export async function getAccountloginLink(data) {
  try {
    const userId = getUserIdFromCookie()
    return apiClient
      .post(`/stripeAccount/stripe-loginLink?cuId=${userId}`, data)
      .then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
  } catch (err) {
    console.log(err);
  }
}

/**
 * To get coupon info
 * @params : couponId (Type: String),
 * @response : Coupon object
 * @author : Vinit Verma
 **/
export async function getCouponInfo(data) {
  try {
    // console.log("getCouponInfo data", data);
    const userId = getUserIdFromCookie()
    return apiClient
      .post(`/stripeAccount/coupon-info?cuId=${userId}`, data)
      .then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
  } catch (err) {
    console.log(err);
  }
}


/**
 * To get coupon info
 * @params : couponId (Type: String),
 * @response :  Charge user with specific amount on the basis of charge type that is passed as re.body
 * @author : Jagroop
 **/
export async function chargeUser(data) {
  try {
    return apiClient
      .post(`/stripeAccount/charge-amount`, data)
      .then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
  } catch (err) {
    console.log("Erroe while charging user:", {err ,data});
  }
}
