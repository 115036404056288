import { getUserIdFromCookie } from "utils";
import apiClient from "./index";

export async function updateBusinessDetails(id, data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/business-details/update/${id}?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}
