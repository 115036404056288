import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { isMobile } from 'react-device-detect';
import { getGuestUser } from '../../api/auth.api';
import { createJob, createJobAsGuest } from '../../api/job.api';
import * as JobCycleApi from '../../api/jobCycle.api';
import * as JobApi from "../../api/job.api";
import * as CustomerApi from "../../api/customers.api";
import { JobTags } from '../../constants';
import { selectCategory } from '../../stores/devices/mobile/Job/Slice';
import store from '../../stores/global-store';
import { decryptStripeKey, getCookie, openNotificationWithIcon } from '../../utils';

let ip = null;
const dataToSaveCall = async ( history : MemoryHistory, user, technicianUserId, jobId ) => {
  const firstFreePromoCode = localStorage.getItem("firstFreePromoCode");
  const MobileJobState = store.getState();

  if (!MobileJobState.JobSlice) {
    openNotificationWithIcon( 'error', 'There is some issue loading state' );
    history.push( '/' );
    return;
  }

  const getMeasurementId = decryptStripeKey(process.env.REACT_APP_GA_MEASUREMENT_ID).split( '-' )[1] || false;
  const dataToSave = {
    software: MobileJobState.JobSlice.Selected.id,
    softwareName: MobileJobState.JobSlice.Selected.name,
    subOption: MobileJobState.JobSlice.Problem.name,
    issueDescription: MobileJobState.JobSlice.Description,
    level: 'advanced',
    estimatedTime: MobileJobState.JobSlice.Selected.estimatedTime, 
    estimatedPrice: MobileJobState.JobSlice.Selected.estimatedPrice,
    hire_expert: false,
    client_id: `${getCookie( '_ga' ).split( '.' )[2]}.${getCookie( '_ga' ).split( '.' )[3]}`,
    session_id: getCookie( `_ga_${getMeasurementId}` ).split( '.' )[2],
    facebook_fbp: getCookie( '_fbp' ),
    facebook_fbc: getCookie( '_fbc' ),
    status: 'Draft',
    user_agent: navigator.userAgent,
    customer_ip: ip, ownerId: null,
  };
  if(jobId){
    dataToSave["post_again"] = true
    dataToSave["post_again_reference_job"] = jobId
  }
  if(technicianUserId){
    dataToSave["post_again_reference_technician"] = technicianUserId
  }

  let draftJobData;

  // if (isMobile) {
  //   localStorage.setItem( 'mobile', 'true' );
  // }

  localStorage.removeItem( 'isScheduleJob' );
  if(user && user.customer && user.customer.id){
    dataToSave.customer = user.customer.id
    const createJobResponse = await JobApi.createJob(dataToSave);
    if(createJobResponse){
      // console.log("createJobResponse-----------", { createJobResponse })
      const customerInfo = await CustomerApi.checkIfOrganisationHasSubscription({
				user: user,
				liveUser: user.customer.customerType === "live" ? true : false
			});
      
      
      // console.log("customerInfo----------", {customerInfo})
      if(customerInfo.has_card_or_subscription){
       
        if(technicianUserId){
          window.location.replace(`/customer/mobile?jobId=${createJobResponse.id}&page=PhoneNumber&technicianId=${technicianUserId}`)
        }else{
          window.location.replace(`/customer/mobile?jobId=${createJobResponse.id}&page=PhoneNumber`)
        }
      }else{
      
        if(technicianUserId){
          if(firstFreePromoCode){
          window.location.replace(`/customer/mobile?jobId=${createJobResponse.id}&page=PhoneNumber&technicianId=${technicianUserId}`)

          }else{
            window.location.replace(`/customer/mobile?jobId=${createJobResponse.id}&page=AddCard&technicianId=${technicianUserId}`)
          }   
          
        }else{
          if(firstFreePromoCode){
         
            window.location.replace(`/customer/mobile?jobId=${createJobResponse.id}&page=PhoneNumber`)
          }else{
            window.location.replace(`/customer/mobile?jobId=${createJobResponse.id}&page=AddCard`)
          }
          
        }
      }
    }
  }else{
    dataToSave.customer = `guest_${new Date().getTime()}`;
    dataToSave.guestJob = true;
    const res = await getGuestUser();
  
    if (res && res.token) {
      const resCreateJob = await createJobAsGuest( dataToSave, res.token.accessToken );
      mixpanel.track( 'Customer guest Job Created', { JobID: resCreateJob.id } );
  
      await JobCycleApi.create( JobTags.DRAFT_JOB_CREATED, resCreateJob.id );
      // data.loading( false );
      draftJobData = resCreateJob;
      draftJobData.issueDescription = dataToSave.issueDescription;
      // eslint-disable-next-line no-restricted-globals
      // window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${draftJobData.id}`;
      if(technicianUserId){
        window.location.href = `/customer/mobile-sign-up?jobId=${draftJobData.id}&page=InputEmail&technicianId=${technicianUserId}`;
      }else{
        window.location.href = `/customer/mobile-sign-up?jobId=${draftJobData.id}&page=InputEmail`;
      }
    }
  
    return draftJobData;
  }
};

/**
 * Handles the "get help now" functionality.
 *
 * @return {void}
 */
const handleGetHelpNow = async ( history, user, technicianUserId, jobId ) => {
  await dataToSaveCall( history, user, technicianUserId, jobId )
    .then( () => {
      // eslint-disable-next-line no-console
    } );
};

async function getClientIpAddress() {
  try {
    await axios.get( ' https://geolocation-db.com/json/' )
               .then( res => {
                 ip = res.data;
               } );
  } catch (error) {
    return error;
  }

  return ip;
}

const handleSoftware = ( id : Id, history : MemoryHistory, technicianUserId ) => {
  store.dispatch( selectCategory( id ) );
  if(technicianUserId){
    history.push( `/select-software?technicianId=${technicianUserId}` );
  }else{
    history.push( `/select-software` );
  }
};

const handleSoftwareWithUser = ( id : Id, history : MemoryHistory, technicianUserId, jobIdFromUrl, repost ) => {
  store.dispatch( selectCategory( id ) );
  if(jobIdFromUrl && repost && technicianUserId){
    history.push( `/select-category?jobId=${jobIdFromUrl}&repost=${repost}&technicianId=${technicianUserId}` );
    return
  }
  if(jobIdFromUrl && repost){
    history.push( `/select-category?jobId=${jobIdFromUrl}&repost=${repost}` );
    return
  }
  if(jobIdFromUrl){
    history.push( `/select-category?jobId=${jobIdFromUrl}` );
    return
  }
  if(technicianUserId){
    history.push( `/select-category?technicianId=${technicianUserId}` );
  }else{
    history.push( `/select-category` );
  }
};

const handlePostAgain  = (id) => {
  store.dispatch( selectCategory( id ) );
}

export {
  handleGetHelpNow, getClientIpAddress, handleSoftware, handleSoftwareWithUser, handlePostAgain
};
