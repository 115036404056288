import React from "react";
import {
  HeaderDiv,
  BackArrowDiv,
  MobileLogoDiv,
} from "../../styledComponents/header.styled";
import logo from "../../../../../../assets/images/newLogoBigger.png";
import GeekerLogo from "utils/GeekerLogo";

const Header = ({
  mobileSignUpStepNumber,
  setMobileSignUpStepNumber,
  jobIdFromUrl,
  signUpFlowStepsObj,
  page,
  techIdFromUrl
}) => {
  const handleBackButton = () => {
    console.log("page index from header", { page });
    if (page !== "InputEmail" && page !== "SignIn" && page !== "SignUp") {
      const pageIndex = signUpFlowStepsObj[page];
      const prevPage = Object.keys(signUpFlowStepsObj)[pageIndex - 1];
      console.log("page index from header 3", { prevPage });
      if (jobIdFromUrl) {
        if (techIdFromUrl) {
          window.location.href = `/customer/mobile?jobId=${jobIdFromUrl}&page=${prevPage}&reschedule=true&technicianId=${techIdFromUrl}`;
        } else {
          window.location.href = `/customer/mobile?jobId=${jobIdFromUrl}&page=${prevPage}&reschedule=true`;
        }

      } else {
        window.location.href = `/customer/mobile?page=${prevPage}`;
      }
    } else if (page === "SignIn" || page === "SignUp") {
      // console.log("page index from header 4");
      if (jobIdFromUrl) {
        if (techIdFromUrl) {
          window.location.href = `/customer/mobile-sign-up?jobId=${jobIdFromUrl}&page=InputEmail&technicianId=${techIdFromUrl}`;
        } else {
          window.location.href = `/customer/mobile-sign-up?jobId=${jobIdFromUrl}&page=InputEmail`;
        }
      } else {
        window.location.href = `/customer/mobile-sign-up?page=InputEmail`;
      }
    } else if (page === "InputEmail") {
      // console.log("page index from header 5");
      // localStorage.setItem( 'mobile', 'true' );
      // window.location.href = `/customer/start-profile-setup?page=job-summary&jobId=${jobIdFromUrl}`;
      if (jobIdFromUrl) {
        if (techIdFromUrl) {
          window.location.href = `/select-description?technicianId=${techIdFromUrl}`;
        } else {
          window.location.href = `/select-description`;
        }
      } else {
        window.location.href = "/";
      }
    }
  };

  return (
    <>
      <HeaderDiv className="headerDiv">
        {page !== "AddCard" && page !== "PhoneNumber" && (
          // page !== "ScheduleJob" &&
          // page !== "ScheduleJobSummary" &&
          <BackArrowDiv className="backArrowDiv" onClick={handleBackButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="51"
              viewBox="0 0 49 51"
              fill="none"
            >
              <path
                d="M27 30.061L21 24.061L27 18.061"
                stroke="#708390"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BackArrowDiv>
        )}
        <div className="">
          <MobileLogoDiv>
            <GeekerLogo className="logoImage" logo={logo} CoBrandingWidth="200px"/>
          </MobileLogoDiv>
        </div>
      </HeaderDiv>
    </>
  );
};

export default Header;
