import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import './ScheduleJobCancelModal.css'
import { deleteDraftJob, updateJob } from '../../../api/job.api';
import mixpanel from 'mixpanel-browser';
import { openNotificationWithIcon } from '../../../utils';
import CircularProgress from '@mui/material/CircularProgress';

const DeleteDraftJobModal = (props) => {

    const [isDisabled, setIsDisabled] = useState(false)

    const handleClose = () => {
        props.setIsDeleteDraftJobModal(false)
    }

    const handleCancel = async (event) => {
        setIsDisabled(true)

        const response = await deleteDraftJob(props.job.id);

        if (!response.success) {
            setIsDisabled(false)
            openNotificationWithIcon('error', 'Error', `Failed to delete draft job ${response.message}`);
            return;
        }

        props.setIsDeleteDraftJobModal(false)
        openNotificationWithIcon('success', 'Success', 'Draft job deleted successfully');

        event.preventDefault();
        mixpanel.identify(props.user.email);
        mixpanel.track('Customer -  Deleted draft job from dashboard', { 'JobId': props.job.id });

        setTimeout(function () {
            window.location.reload()
        }, 1000)
    };

    return (
        <>
            <div>
                <Modal
                    open={props.isDeleteDraftJobModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="outer-box-modal">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} className="close-icon-btn" style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="text-div mg-b-10">
                            <div className="end-the-job-div">
                                <span className="close-icon-btn-text font-nova" style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    Are you sure you want to delete this draft job?
                                </span>

                            </div>
                        </div>

                        <div className="d-flex justify-content-center mg-b-10"
                            style={{ gap: '1rem' }}
                        >
                            <div className="mg-b-10">
                                <Stack className='margin-right-16px' spacing={2} direction="row">
                                    <Button variant="outlined" className="Outlined-btn-join" onClick={handleClose}>
                                        <span className="join-btn-css">Close</span>
                                    </Button>
                                </Stack>
                            </div>

                            <div className="mg-b-10">
                                <Stack spacing={2} direction="row">
                                    <Button variant="outlined"
                                        className="contained-end-btn-delete"
                                        onClick={handleCancel}
                                        disabled={isDisabled}
                                        style={{ backgroundColor: '#FF0000' }}
                                    >
                                        {isDisabled ? <CircularProgress className='spin-css' /> :
                                            <span className="join-btn-css color-white" >Delete Job</span>
                                        }
                                    </Button>
                                </Stack>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default DeleteDraftJobModal
