import { Modal } from "antd";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory, useLocation } from "react-router";
import { ReactSVG } from "react-svg";
import * as CustomerApi from "../../../../../../api/customers.api";
import * as JobApi from "../../../../../../api/job.api";
import img1 from "../../../../../../assets/images/1.svg";
import img2 from "../../../../../../assets/images/2.svg";
import img3 from "../../../../../../assets/images/3.svg";
import img4 from "../../../../../../assets/images/4.svg";
import img5 from "../../../../../../assets/images/5.svg";
import cross from "../../../../../../assets/images/X.png";
import calander from "../../../../../../assets/images/calendar.svg";
import checkInCircle from "../../../../../../assets/images/check_in_cyan.png";
import footerLadySVG from "../../../../../../assets/images/lady.svg";
import rocket from "../../../../../../assets/images/rocket.svg";
import Loader from "../../../../../../components/Loader";
import { JOB_STATUS, SECRET_KEY } from "../../../../../../constants";
import { useAuth } from "../../../../../../context/authContext";
import { useJob } from "../../../../../../context/jobContext";
import { useSocket } from "../../../../../../context/socketContext";
import { TermsCondition } from "../../../../../../policy-pages/conditions";
import { cookiePolicy } from "../../../../../../policy-pages/cookie-policy";
import { privacyPolicy } from "../../../../../../policy-pages/privacy-policy";
import ScheduleJobCancelModal from "pages/Dashboard/components/ScheduleJobCancelModal";
import { useSelector } from "react-redux";

const Footer = ({
  mobileSignUpStepNumber,
  reScheduleTime,
  setReScheduleTime,
  selectedScheduleTime,
  setSelectedScheduleTime,
  setMobileSignUpStepNumber,
  page,
  user
}) => {
  const { socket } = useSocket();
  const location = useLocation();
  const history = useHistory();
  const { getGuestUser } = useAuth();
  const queryParams = new URLSearchParams(location.search);
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const { fetchJobAsGuest } = useJob();
  const [jobInfo, setJobinfo] = useState();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPrivacyPolicyModalAvailable, setIsPrivacyPolicyModalAvaliable] =
    useState(false);
  const [isCookiesPolicyModalAvailable, setIsCookiesPolicyModalAvailable] =
    useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const MobileJobState = useSelector((state) => state.JobSlice);

  useEffect(() => {
    (async () => {
      if (
        jobIdFromUrl &&
        (page === "InputEmail" || page === "SignUp" || page === "SignIn")
      ) {
        const guestUserRes = await getGuestUser();
        const jobRes = await fetchJobAsGuest(
          jobIdFromUrl,
          guestUserRes.token.accessToken
        );
        if (jobRes) {
          setIsFetchingData(false);
          if (jobRes.status && jobRes.status !== JOB_STATUS.DRAFT) {
            window.location.href = "/";
          }
          setJobinfo(jobRes);
          if(!isMobile){
            localStorage.removeItem(SECRET_KEY);
          }
        }
      } else if (page === "ScheduleJobSummary") {
        const jobRes = await JobApi.retrieveJob(jobIdFromUrl);
        if (jobRes) {
          setIsFetchingData(false);
          setJobinfo(jobRes);
        }
      } else {
        setIsFetchingData(false);
      }
    })();
  }, []);

  // const addMobileVarToLocalstorage = () => {
  //   if (isMobile) {
  //     localStorage.setItem("mobile", "true");
  //   }
  // };

  const handelGoToDashbord = () => {
    // addMobileVarToLocalstorage()
    window.location.href = `/`;
  };

  const handelReschedule = async () => {
    Modal.confirm({
      title: "Are you sure you want to Reschedule this job?",
      okText: "Yes",
      cancelText: "No",
      className: "app-confirm-modal",
      async onOk(e) {
        let updateJob = await JobApi.retrieveJob(jobInfo.id);
        // setMobileSignUpStepNumber(5)
        window.location.href = `mobile?jobId=${jobIdFromUrl}&page=ScheduleJob&reschedule=true`;
        setReScheduleTime(true);
        setSelectedScheduleTime(updateJob.primarySchedule);
        Modal.destroyAll();
      },
    });
  };

  const cancelJobByCustomer = async () => {
    const jobRes = await JobApi.retrieveJob(jobIdFromUrl);
    if (jobRes) {
      // console.log("jobInfo from footer from else if", { jobRes });
      setJobinfo(jobRes);
    }
    setIsCancelModal(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleOkPrivacyModal = () => {
    setIsPrivacyPolicyModalAvaliable(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showPrivacyModal = () => {
    setIsPrivacyPolicyModalAvaliable(true);
  };

  const showCookiesModal = () => {
    setIsCookiesPolicyModalAvailable(true);
  };

  const handleCancelPrivacyModal = () => {
    setIsPrivacyPolicyModalAvaliable(false);
  };
  const handleCancelCookiesModal = () => {
    setIsCookiesPolicyModalAvailable(false);
  };

  const handleOkCookiesModal = () => {
    setIsCookiesPolicyModalAvailable(false);
  };

  return (
    <>
      <ScheduleJobCancelModal
        isCancelModal={isCancelModal}
        setIsCancelModal={setIsCancelModal}
        cancelJobId={jobInfo?.id}
        user={user}
        type={"Customer"}
        job={jobInfo}
        decline_job_by_technician={false}
        setcurrentStep={false}
      />
      <div className="footerMainDiv">
        <Modal
          title="Terms & Conditions"
          className="app-confirm-modal TncIdentifier"
          closable={false}
          footer={[
            <button
              className="btn app-btn"
              key="submit"
              type="primary"
              onClick={handleCancel}
            >
              Close
            </button>,
          ]}
          visible={isModalVisible}
          onOk={handleOk}
        >
          {TermsCondition()}
        </Modal>

        <Modal
          title="Privacy Policy"
          className="app-confirm-modal TncIdentifier"
          closable={false}
          footer={[
            <button
              className="btn app-btn"
              key="submit"
              type="primary"
              onClick={handleCancelPrivacyModal}
            >
              Close
            </button>,
          ]}
          visible={isPrivacyPolicyModalAvailable}
          onOk={handleOkPrivacyModal}
        >
          {privacyPolicy()}
        </Modal>

        <Modal
          title="Cookies Policy"
          className="app-confirm-modal TncIdentifier"
          closable={false}
          footer={[
            <button
              className="btn app-btn"
              key="submit"
              type="primary"
              onClick={handleCancelCookiesModal}
            >
              Close
            </button>,
          ]}
          visible={isCookiesPolicyModalAvailable}
          onOk={handleOkCookiesModal}
        >
          {cookiePolicy()}
        </Modal>

        {page !== "AddCard" && page !== "ScheduleJob" && (
          <>
            {jobIdFromUrl && (
              <ReactSVG
                src={footerLadySVG}
                className={`footerLady ${page === "PhoneNumber" ? "bottom-neg-55" : ""
                  } ${page === "ScheduleJobSummary" ? "bottom-24" : ""}`}
              />
            )}
            <div
              className={`d-flex flex-column footerInfoDiv w-100percent gap-50-min-width-300 gap-5-max-width-280 ${page === "PhoneNumber" ? "pb-55 pr-pl-20-max-width-280" : ""
                } ${page === "ScheduleJobSummary" ? "pb-16-imp" : ""}`}
            >
              {jobIdFromUrl && (
                <div
                  className={`max-width-300-min-width-300 pl-10-max-width-280 pr-10-max-width-280 ${page === "PhoneNumber" ? "what-happens-next-div" : ""
                    } ${page === "ScheduleJobSummary"
                      ? "sch-job-time-footer-div"
                      : "jobInfoDiv"
                    }`}
                >
                  {page === "PhoneNumber" && (
                    <>
                      <div className="d-flex flex-column justify-content-center align-items-center gap-24">
                        <ReactSVG src={img4} className="dotted-arc" />
                        <ReactSVG src={img5} className="dotted-arc-2" />
                        <span className="font-nova happens-next-text">
                          What happens next?
                        </span>
                        <div className="d-flex gap-12">
                          <ReactSVG src={img1} />
                          <span className="font-nova footer-text">
                            We search for a tech to help you asap!
                          </span>
                        </div>
                        <div className="d-flex gap-12">
                          <ReactSVG src={img2} />
                          <span className="font-nova footer-text">
                            You get notified when your expert is ready (in just
                            minutes!)
                          </span>
                        </div>
                        <div className="d-flex gap-12">
                          <ReactSVG src={img3} />
                          <span className="font-nova footer-text">
                            Your call/screen share starts with one click!
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {page === "ScheduleJobSummary" && (
                    <>
                      <ReactSVG src={rocket} />
                      <span className="font-nova sch-time-span">
                        If a Geek accepts your job, you'll get a notification and
                        a meeting link just before your call.
                      </span>
                    </>
                  )}
                  {(page === "InputEmail" ||
                    page === "SignUp" ||
                    page === "SignIn") && (
                      <>
                        <div className="helpInTenMinDiv">
                          <img src={checkInCircle} />
                          <span className="font-nova helpInTenMinText">
                            Get help in 10 minutes
                          </span>
                        </div>
                        <div>
                          <span className="font-nova yourJobText">Your Job</span>
                        </div>

                        {isFetchingData ? (
                          <Loader height={"60px"} />
                        ) : (
                          <>
                            <div className="d-flex align-items-center">
                              <img
                                src={MobileJobState?.Selected?.blob_image}
                                className={"mr-2 footerSoftwareImg"}
                              />
                              <div className="wrap-text-max-width-280">
                                <span className="font-nova softwareName mr-3">
                                  {MobileJobState?.Selected?.name
                                    ? MobileJobState?.Selected?.name
                                    : "NA"}
                                </span>
                                <span className="font-nova subOptionName">
                                  {MobileJobState?.Problem?.name? MobileJobState?.Problem?.name: "NA"}
                                </span>
                              </div>
                            </div>
                            <hr className="jobInfoHr" />
                            <div>
                              <span className="font-nova priceLabel">
                                Price Per min
                              </span>{" "}
                              <span className="amountLabel">
                                {MobileJobState?.Selected?.ratePerMinute
                                  ? "$" + MobileJobState?.Selected?.ratePerMinute
                                  : "NA"}{" "}
                              </span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                </div>
              )}
              
              {(page === "InputEmail" ||
                page === "SignUp" ||
                page === "SignIn") && (
                  <div className="text-center">
                    <span className="font-nova TnCText greyColor">
                      By Signing up I agree to
                    </span>{" "}
                    <span
                      className="font-nova TnCText turquoiseColor"
                      onClick={showModal}
                    >
                      Terms & Conditions,{" "}
                    </span>
                    <span
                      className="font-nova TnCText turquoiseColor"
                      onClick={showPrivacyModal}
                    >
                      {" "}
                      Privacy Policy
                    </span>{" "}
                    <span className="font-nova TnCText greyColor"> & </span>{" "}
                    <span
                      className="font-nova TnCText turquoiseColor"
                      onClick={showCookiesModal}
                    >
                      Cookies Policy
                    </span>
                  </div>
                )}
            </div>
          </>
        )}
        {page === "ScheduleJobSummary" && (
          <div className="action-div">
            <span className="">What else can you do?</span>
            <div className="w-100percent d-flex justify-content-between flex-col-max-width-280">
              <span className="d-flex gap-5">
                <ReactSVG src={calander} />
                <div className="turquoise-border-bottom">
                  <span onClick={handelReschedule} className="options-text">
                    Reschedule
                  </span>
                </div>
              </span>
              <span className="d-flex align-items-center gap-5">
                <img src={cross} className="h-15px" />
                <div className="turquoise-border-bottom">
                  <span onClick={cancelJobByCustomer} className="options-text">
                    Cancel
                  </span>
                </div>
              </span>
              <span className="d-flex align-items-center">
                <div className="turquoise-border-bottom">
                  <span onClick={handelGoToDashbord} className="options-text">
                    Dashboard
                  </span>
                </div>
              </span>
            </div>
          </div>
        )}
        <div className="sign-up-black-footer">
          <span className="font-nova">Geeker.co</span>
        </div>
      </div>
    </>
  );
};

export default Footer;
