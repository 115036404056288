import React from "react";
export const cookiePolicy = () => {
  return (
    <div className="set_content_height">
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker, Inc. Cookie Policy
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Last Updated: January 27, 2022
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Our Privacy Policy explains how we collect and use information from
          and about you when you use our Platform. This Policy explains out how
          we use cookies and your choices concerning cookies.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          What Are Cookies?
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Like most websites, we and our advertising networks use cookies and
          similar technologies (collectively, “cookies”) for a number of
          reasons, including to remember things about you, learn more about your
          and other users’ interaction with the Platform, provide essential
          features, and generally provide you with a better experience. You can
          find more information about our use of cookies in the section
          entitled&nbsp;
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          “How We Use Cookies" below
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          .
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The cookies we use include the following:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cookies are small data files stored on your browser or device.
              They may be served by the entity that operates the website you are
              visiting (“first-party cookies”) or by other companies
              (“third-party cookies”). The use of cookies allows us to improve
              our Platform by, for example, tracking saved searches, remembering
              your settings and preferences, understanding your and other site
              visitors’ use of various features and functionality, delivering
              targeted ads that are more relevant to you, measuring the
              effectiveness of advertising and otherwise creating a consistent
              user experience.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Pixels (also known as clear GIFs or web beacons) are small
              electronic images that assist in delivering cookies, provide
              additional information (such as whether you visited a page, which
              ad you clicked on to arrive at the Properties, or whether you
              opened an email) and allow us to compile aggregated statistics.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "12pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Local storage allows data to be stored locally on your browser or
              device and includes HTML5 local storage and browser cache.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          How We Use Cookies
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The cookies we use (which may be either first-party cookies or
          third-party cookies) typically fall into one of the following
          categories:
        </span>
      </p>
      <div align="left" dir="ltr" style={{ marginLeft: "0pt" }}>
        <table style={{ border: "none", borderCollapse: "collapse" }}>
          <tbody>
            <tr style={{ height: "35.25pt" }}>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Category of Cookies
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Why We Use These Cookies
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "35.25pt" }}>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Essential
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    These cookies are essential for our Platform to function
                    properly and to provide the services you request.
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "21.75pt" }}>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Preferences
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We use these cookies to remember your settings and
                    preferences.
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "21.75pt" }}>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Security
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We use these cookies to help identify and prevent security
                    risks.
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "62.25pt" }}>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Performance
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We use these cookies to collect information about how you
                    interact with our Platform and to help us improve the
                    Platform and services. For example, we may use these cookies
                    to determine if and how you interacted with a certain page.
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "62.25pt" }}>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Analytics
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We use these cookies to help us understand and improve our
                    Platform. For example, we can use these cookies to learn
                    more about which pages and features are the most popular
                    with our users and where we may need to make improvements.
                  </span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "62.25pt" }}>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Advertising
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderLeft: "solid #808080 0.75pt",
                  borderRight: "solid #808080 0.75pt",
                  borderBottom: "solid #808080 0.75pt",
                  borderTop: "solid #808080 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#ffffff",
                  padding: "4pt 4pt 4pt 4pt",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.38",
                    marginTop: "12pt",
                    marginBottom: "12pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Arial",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    We and our advertising partners use these cookies to deliver
                    advertisements, make the ads more relevant and meaningful to
                    visitors to our website and track the efficiency of our
                    advertising campaigns, both on our Platform and on other
                    websites.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#ffffff",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Your Choices
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You have a number of options to control or limit how we and our
          partners use cookies, including for advertising.
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Enabling cookies is not strictly necessary for the website to work
              (except for essential cookies) but it will provide you with a
              better browsing experience.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Although most browsers and devices accept cookies by default,
              their settings usually allow you to manage cookies. The 'Help'
              feature on most browsers will tell you how to prevent your browser
              from accepting new cookies, how to have the browser notify you
              when you receive a new cookie, how to disable/block cookies, and
              when cookies will expire. If you choose to disable cookies, you
              may not be able to access or use some portions and features of the
              Platform (including any that require you to sign in) and you may
              not get the best experience possible. If you choose to delete
              cookies, you will need to log in again on your next visit to the
              Platform properties and re-enter any preferences you have set.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your browser may offer you a "Do Not Track" option, which allows
              you to signal to operators of websites and other properties that
              you do not wish such operators to track certain online activities.
              Our Platform does not support Do Not Track requests.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              For information on how our advertising partners allow you to opt
              out of receiving ads based on your web browsing history, please
              visit&nbsp;
            </span>
            <a
              href="http://optout.aboutads.info/"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#1155cc",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                http://optout.aboutads.info/
              </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To prevent your data from being used by Google Analytics, you can
              install&nbsp;
            </span>
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#1155cc",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Google’s opt-out browser add-on.
              </span>
            </a>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "12pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To opt out of ads on Facebook, Pinterest or Google that are
              targeted to your interests, use your&nbsp;
            </span>
            <a
              href="https://www.facebook.com/ads/settings"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#1155cc",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Facebook
              </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ,&nbsp;
            </span>
            <a
              href="https://help.pinterest.com/en/articles/personalized-ads-pinterest"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#1155cc",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Pinterest
              </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;or&nbsp;
            </span>
            <a
              href="https://adssettings.google.com/"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Arial",
                  color: "#1155cc",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Google Ads
              </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;settings.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Third-Party Cookies
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You may get cookies from our third-party advertisers, tracking
          utilities, Geeker Affiliates, business partners or other third parties
          with links on the Platform properties. This is a standard practice in
          our industry, and we have no control or access to these cookies. Our
          Privacy Policy does not cover the use of these cookies by third
          parties or the use of these cookies on websites or mobile applications
          other than the Platform properties.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Additional Information or Assistance
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Please address comments or questions about this Cookie Policy to
          questions@geeker.com or write to us at the address below.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.284", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker, Inc.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.284", marginTop: "0pt", marginBottom: "0pt" }}
      >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Attn: Cookie Policy
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};
