import React from "react";
export const privacyPolicy = () => {
  return (
    <div className="set_content_height">
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker, Inc. Privacy Policy
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Last Updated: January 27, 2022
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Overview
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          This Privacy Policy describes how Geeker, Inc. (“Geeker,” “we,”)
          collects, uses, and shares information about you. This Privacy Policy
          applies to personal information we collect when you access or use our
          websites, mobile applications, and other online platforms or
          properties (collectively, the “Platform”). &nbsp;Except as otherwise
          explicitly provided herein, this Privacy Policy applies only to
          information collected on or through the Platform, and it does not
          apply to information collected or obtained by or through any other
          means (including, without limitation, information collected offline,
          in person, over the telephone and/or by mail, or from third parties
          outside the Platform). Capitalized terms used but not defined herein
          have the meanings ascribed thereto in the Terms of Use.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          By "personal information," we mean information that uniquely
          identifies an individual, such as your name, email address, or
          government-issued identification number (such as your driver’s license
          number or social security number); your unique financial information,
          such as your credit card number; and information that we link to your
          unique identifier(s) or unique financial information. If information
          is de-linked or de-identified, we do not consider it to be personal
          information. For example, your postal address would not be personal
          information if it were included in a list of addresses without names
          or other unique identifiers. Business information (such as the name of
          a business or the business address or phone number) is not personal
          information.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          References to Geeker (or “we”) also include companies providing
          services to Geeker, any parent, subsidiary or affiliate of Geeker
          (collectively, “Affiliates”), and our business partners, to the extent
          that such parties are collecting, using, or sharing personal
          information for or on behalf of Geeker. This Privacy Policy applies to
          information collected by those companies only when they provide it to
          Geeker.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may change this Privacy Policy from time to time. If we make
          changes, we will notify you by posting the updated Privacy Policy on
          our website and updating the effective date at the top of this Privacy
          Policy. If we make material changes, we may provide you with
          additional notice (such as adding a statement to our website or
          sending you an email notification). We encourage you to review this
          Privacy Policy regularly to stay informed about our information
          practices and the choices available to you.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          If you do not agree with the terms of this Privacy Policy, please do
          not continue using the Platform.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Information We Collect
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Information You Give to Us
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We collect information you provide directly to us. For example, we
          collect your name, email address and other information when you
          register for an account; make a service request or inquiry (or, if you
          are a Service Provider, when you respond to a service request or
          inquiry); make a purchase; request customer support; leave a review
          about your experiences with Geeker, Service Providers or users;
          otherwise upload, link or provide content that includes personal
          information; or subscribe to our promotional marketing programs. If
          you are a Service Provider, we collect government identification and
          other personal information necessary for background, reference or
          other checks and we collect information about your customers in
          connection with the services you provide to them through the Platform.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Information We Collect Automatically
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          When you access or use the Platform or when we send you emails, we and
          our advertisers may use tracking technologies to automatically collect
          information about you. Please see our&nbsp;
        </span>
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Cookie Policy
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;to learn more about these technologies, how we use them, and how
          to manage or disable them. Automatically collected information may be
          associated with your unique browser, device identifier or Internet
          protocol (IP) address, but we do not consider it to be personal
          information unless it is linked to your unique identifier(s).
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Automatically collected information includes:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Log Information. We log information such as IP address, access
              times, operating system, browser type and language, Internet
              service provider (ISP), and the web page you visited before
              navigating to our Platform. We collect information about your use
              of our website and mobile applications, such as clicks, scrolls,
              features accessed, access time and frequency, errors generated,
              performance data, storage utilized, user settings and
              configurations.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Usage and Session Information. We collect usage information, such
              as how many visitors visit a specific page, how long they stay,
              which links they click on, and how, when and how often they order
              particular services, and session information, such as mouse
              actions, pages visited, screen actions and other interaction
              patterns.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Device Information. We collect information about the computer or
              mobile device you use to access our Platform, including the
              hardware model, operating system and version, unique device
              identifiers and mobile network information.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Location Data. If you are using a mobile device, we may collect
              location data directly from your mobile device if your device
              allows us to do so. In addition, your IP address may provide an
              approximate geo-location.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Transaction Information. When you purchase a product or service ,
              whether from us or from a Service Provider, we collect information
              about the transaction, such as the date, time and duration of the
              service.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "12pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Emails. When you receive emails from us, we collect information
              about whether and when you opened the email, whether you clicked
              on the images and links in the email, and whether your computer or
              mobile device is capable of receiving HTML-based email.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Information Collected from Calls
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          When you call us, when we call you, or when we connect calls between
          Consumers and Service Providers, including when you provide virtual
          access to your computer or share your computer screen with Service
          Providers through the Platform, we may monitor and/or record those
          calls for quality assurance and customer satisfaction purposes. In
          those jurisdictions where consent is required for a party to record a
          call, you consent to us recording such calls. We treat the records of
          these calls, when linked to your unique identifier(s), as personal
          information.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Information We Collect from Affiliates and Third Parties
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Our Affiliates may provide Geeker with information they collect from
          their customers and business partners. For example, if you purchase a
          product or service from an Affiliate, that company may provide your
          information to Geeker to allow us to offer our services to you.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may also obtain information from other sources such as companies
          who partner with us to offer discounts, promotions or Platform
          services to their customers or users. If you order a Tech Service, we
          may collect information about you from the Service Provider who
          fulfills the service request. Service Providers may collect and
          provide to us personal information relating to you in connection with
          the services they provide and may include it in a public posting or
          other user submission on the Platform.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker also obtains personal information from third party data
          providers and public sources. Geeker may match the personal
          information you provide directly to us (for example, your email
          address) with information obtained from other sources (such as your
          name, telephone number, and mailing address).
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          When you interact or engage with us on social media sites such as
          Facebook through posts, comments, questions and other interactions, we
          may collect such publicly available information, including profile
          information, to allow us to connect with you, or better understand
          user reactions and issues. Once collected, this information may remain
          with us even if you delete it from the social media site(s).
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Aggregate and Anonymized Information
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may also aggregate (group) or de-link/de-identify (anonymize)
          information. Aggregated, anonymized data is not personal information
          subject to this Privacy Policy, and we may use it to analyze usage of
          the Platform or for any other purpose. We may also share aggregated
          and/or anonymized information with Affiliates and our and their
          business partners. For example, based on the information we have
          obtained from and about individuals, we may compile a list of
          addresses that we believe are associated with an individual in need of
          a computer or software upgrade and provide that list (without any
          unique identifiers for the individuals) to our business partners.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          How We Use the Information We Collect
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          In general, we use the information we collect to operate our business,
          including:
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To provide, maintain, improve and personalize the Platform and the
              services &nbsp;of our Affiliates.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To analyze trends, administer our websites, and track visitor
              navigations on our websites to understand what visitors are
              looking for.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To connect users with Service Providers and other companies that
              receive or help fulfill requests and orders for technology-related
              products and services.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To process transactions, to provide and improve customer service,
              to communicate with you about your account, changes in our
              policies or other service matters, and to otherwise manage
              &nbsp;our relationships with our users.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To market products and services from Geeker and Affiliates, and
              our and their business partners, to optimize marketing campaigns,
              and to analyze their effectiveness, distribution and reach.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To develop new products and services.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To administer contests, promotions or sweepstakes.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To detect, investigate and address technical issues, security
              incidents, fraudulent transactions, abuse, violations of our
              agreements and policies, and illegal activities.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To comply with our legal obligations and legal process.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To create aggregated or de-identified data sets.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To carry out certain short-term activities and other reasonable
              internal purposes related to the Platform, the products or
              services you purchase through the Platform or your ongoing
              relationship with us.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "disc",
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "12pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To perform other business purposes described at the time the
              information is collected or as otherwise set forth in applicable
              data privacy laws.
            </span>
          </p>
        </li>
      </ul>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker will not collect additional categories of personal information
          or use the personal information we collect for materially different,
          unrelated, or incompatible purposes without providing you notice.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          How We Share Information
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Except as described in this Privacy Policy, we do not sell or rent
          your personal information to any third party. If we share your
          personal information, we do so only as follows:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Affiliates
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We share information with Affiliates for marketing purposes, to allow
          them to improve their products and services (such as confirming or
          supplementing information they have about a person or to classify a
          person as a candidate for a particular product or service), in
          connection with the data, technical, legal, accounting, and other
          services shared between Affiliates, and for other internal business
          purposes. Affiliates will be bound by the terms of this Privacy Policy
          with respect to personal information received from Geeker and will use
          that personal information only as described in this Privacy Policy.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Companies Providing Services to Geeker
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We share information with third-party vendors, consultants, and other
          service providers that are providing business services to Geeker. For
          instance, we may share information about you with a third-party
          service provider that hosts the Platform, provides call center or
          other customer service, processes payments, sends out emails, serves
          ads to you on the Platform or on a third-party platform, or monitors
          or analyzes Platform data. Such companies will have access to personal
          information only as necessary to carry out their work and are
          prohibited from using it for any other purpose.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          User-Directed Disclosures
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We share your personal information when you direct us to do something
          that requires its disclosure or when you otherwise provide your
          consent. For example, if you request or inquire about Tech Services
          through the Platform, we may provide your personal information to
          Service Providers and the Service Provider’s information to you.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Partners and Others
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Partner Programs and Promotions
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We share your personal information in connection with our products and
          services offered to you in conjunction with our partners or in
          connection with partner programs or promotions. If you request, access
          or use our Platform services through our partners (which could be an
          Affiliate or a third party) or if you request, access or use their
          services through our Platform, both Geeker and the partner may receive
          your personal information. For example, if you sign up with Geeker,
          request Platform access or consent to receive communications from
          Geeker through a partner-promoted or co-branded website or within a
          partner’s store, Geeker and that partner may share your personal
          information with each other. We may also disclose your personal
          information to partners if you participate in their promotion offered
          through us or you purchase or request information about a partner’s
          products or services from us. For example, if we offer a discount on a
          partner product or service through a marketing email or a website
          link, we may disclose to the partner personal information about our
          users who contact us or visit the partner’s website or their users who
          visit our website.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Direct Marketing
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          With your express consent, we may also share your personal information
          with third parties to market their products or services to you
          directly and otherwise for marketing and promotional purposes.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Companies with Whom You Have an Existing Relationship
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          If you are already a customer of or otherwise have a direct
          relationship with a business partner of Geeker or a Geeker Affiliate
          (an “Existing Relationship Company”), we may disclose information
          about you to that Existing Relationship Company. If you are a match to
          a customer on the Existing Relationship Company customer list, we may
          provide the Existing Relationship Customer with information such as
          the target marketing groups or segments you belong to (such as PC or
          Macintosh user) and demographic information about you (such as
          estimated household income or technology budget), so that the Existing
          Relationship Company can provide you with more relevant advertising,
          products and services. We will not give the Existing Relationship
          Company your contact information, your unique personal identifiers, or
          your unique financial information unless for another purpose permitted
          in this Privacy Policy. However, the Existing Relationship Company may
          already have some or all of that information (because of your existing
          relationship with them) and they will be able to link you with the
          other information about you that we provide to them.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Contests and Sweepstakes
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          When you sign up for and participate in a contest or sweepstakes, we
          may publicly disclose the name, city and state of winners.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Compliance with Laws and Policies; Protection of Rights
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may disclose your personal information if we believe disclosure is
          necessary to comply with any applicable law, regulation, or legal
          process, to cooperate with government or law enforcement, or to avoid
          legal liability; to resolve complaints or disputes; to investigate and
          resolve technical issues, security incidents, fraud, abuse, and
          illegal activities; if we believe your actions are inconsistent with
          the spirit or language of our user agreements or policies; or to
          protect the rights, reputation, safety and property of Geeker or
          others.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Strategic Transactions
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We may disclose personal information in connection with, or during
          negotiations of, any merger, sale of company assets, financing, or
          acquisition of all or a portion of our business or in a bankruptcy,
          liquidation, dissolution or similar transaction. In the event that we
          or all of our assets are acquired in such a transaction, our collected
          information would be one of the transferred assets.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Professional Information
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We do not consider the business information of Service Providers to be
          personal information. Accordingly, we may share Service Providers’
          business names, owner/proprietor names, business contact information
          and other information with third parties for any purpose.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Security
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We take reasonable measures, including administrative, technical, and
          physical safeguards, to restrict access to your personal information
          and help protect it from loss, theft, misuse, and unauthorized access,
          disclosure, alteration, and destruction.&nbsp;
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "#ffff00",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          For example, when you enter a credit card number on the Platform, we
          encrypt that information in transit over the Internet and when stored.
          However, no such measure is 100% secure
        </span>
        <span
          style={{
            fontSize: "8pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          [KM1]&nbsp;
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          .
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Third-Party Websites or Services
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The Geeker website may contain links to other third-party websites and
          services, either directly or through advertisements on Geeker’s
          website. Geeker is not responsible for the content or privacy
          practices of websites operated by third parties, including our
          advertising partners and networks. We encourage you to review the
          privacy policies of third-party websites and services before providing
          any information to them.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Managing Your Personal Information
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Account Information
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You may access, update and correct your online account information at
          any time by logging into your online account and updating your
          preferences. If you wish to delete your online account, please email
          us at&nbsp;
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          info@Geeker.co
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          . Please note that, even if your account is deleted, information that
          you chose to provide in a public area of the Platform (such as
          reviews) will remain visible to the public and may remain in the
          accounts of or be otherwise retained by users, Service Providers and
          other third parties with whom such information has been shared, and we
          may retain your information as required by law or for legitimate
          business purposes. We may also retain cached or archived copies of
          your information.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          You may also request to access information or to update or correct
          inaccurate information by emailing us at&nbsp;
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          info@Geeker.co
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Cookies
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Most web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove or reject browser
          cookies. Please note that if you choose to remove or reject cookies,
          this could affect the availability and functionality of the Platform.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          To opt out of being tracked by Google Analytics when using our
          web-based services, visit https://tools.google.com/dlpage/gaoptout.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Promotional Communications
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Receiving promotional calls, text (SMS) messages, emails and direct
          mail from Geeker is completely voluntary, and you may opt out at any
          time. To opt out of promotional communications from Geeker, you can
          contact Geeker customer service at (____) ________ or email&nbsp;
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          info@Geeker.co
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          . You can also opt out of receiving promotional emails by clicking the
          unsubscribe link in the email, and you can opt out of promotional text
          (SMS) messages by texting STOP. You may receive a final email or text
          message confirming your opt-out. Message and data rates may apply.
          Please allow up to 10 business days to process any opt-out request.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Please note that even if you opt out, we may still send you
          non-promotional communications, such as emails about site outages,
          your online account or service requests that you have submitted.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "italic",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Communications By Third Parties
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          To opt out from receiving promotional communications from an Affiliate
          or other third party, or to make requests regarding your personal
          information or other information held by them, you should contact them
          directly.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Children and Our Platform
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Our Platform is not directed to children, and you may not use our
          Platform if you are under the age of 18. If we learn that we have
          collected personal information of a child under 18, we will make
          commercially reasonable efforts to delete such information from our
          files.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Retention
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker retains personal information as long as it is needed for
          business or legal purposes.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Governing Law; Visitors Residing Outside the United States
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker’s website and services are hosted and performed in the United
          States. This Privacy Policy is subject the laws of the United States
          of America and the State of New York, without regard to conflict of
          law principles. If you reside outside of the United States, you are
          responsible for complying with local laws in connection with your use
          of the Platform, and you consent to the collection, transfer, storage,
          and use of your personal information in the United States in
          accordance with this Privacy Policy.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          The laws governing processing of personal information vary depending
          on the jurisdiction in which you reside. To the extent the laws of
          your jurisdiction are more restrictive than the terms of this Privacy
          Policy, it is Geeker’s policy to follow the requirements of those laws
          to the extent those laws are applicable to Geeker’s activities or your
          personal information.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Notice For Nevada Residents
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Under Nevada law, certain Nevada consumers may opt out of the sale of
          “personally identifiable information” for monetary consideration to a
          person for that person to license or sell such information to
          additional persons. “Personally identifiable information” includes
          first and last name, address, email address, phone number, Social
          Security Number, or an identifier that allows a specific person to be
          contacted either physically or online.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          We do not engage in such activity, and we do not believe that our
          activities presently subject us to that provision of Nevada law;
          however, if you are a Nevada resident who has registered for an
          account on the Platform, you may submit a request to opt out of any
          potential future sales under Nevada law by send an email to&nbsp;
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "underline",
            WebkitTextDecorationSkip: "none",
            textDecorationSkipInk: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          NevadaOptOut@geeker.co
        </span>
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          . Please note we will take reasonable steps to verify your identity
          and the authenticity of the request. Once verified, we will maintain
          your request in the event our practices change.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Additional Information or Assistance
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.38", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Please address comments or questions about this Privacy Policy
          to&nbsp;
        </span>
        <a href="mailto:info@Geeker.co" style={{ textDecoration: "none" }}>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Arial",
              color: "#1155cc",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            info@Geeker.co
          </span>
        </a>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: "1.284", marginTop: "12pt", marginBottom: "12pt" }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          Geeker, Inc
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};
