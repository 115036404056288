import { createAsyncThunk } from "@reduxjs/toolkit";
import * as SoftwareApi from "../../../../api/software.api";
import * as SoftwareCategoryApi from "../../../../api/softwareCategory.api";

const fetchSoftwareList = createAsyncThunk(
  "job/fetchSoftwareList",
  async () => {
    const response = await SoftwareApi.getSoftwareList();
    return response.data;
  }
);

const fetchSoftwareCategoryList = createAsyncThunk(
  "job/fetchSoftwareCategoryList",
  async () => {
    const response = await SoftwareCategoryApi.getSoftwareCategoryList();
    return response.data;
  }
);

export { fetchSoftwareList, fetchSoftwareCategoryList };
