// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "hgroup {\n  margin: 0 0 5px 0;\n  line-height: 19.5px;\n}\n\n.two_h2_title__UpH0D {\n  font-size: 18px !important;\n  margin: 20px auto 0 auto !important;\n  max-width: 280px;\n}\n\n.two_paragraph_subtitle__hDF8D {\n  font-size: 0.91rem !important;\n  color: #708390;\n  margin: 0 auto 10px auto;\n  max-width: 290px;\n}\n", ""]);
// Exports
exports.locals = {
	"h2_title": "two_h2_title__UpH0D",
	"paragraph_subtitle": "two_paragraph_subtitle__hDF8D"
};
module.exports = exports;
