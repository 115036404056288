import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import * as JobApi from '../../../../api/job.api';
import { openNotificationWithIcon } from 'utils';
import { useTools } from 'context/toolContext';
import { useAuth } from "context/authContext";


const ReChargePayment = ({ user, setRecharge, reCharge, setHideRecharge, setJobRecords,jobRecords = [], setMakepayment }) => {
    const [confirmCharge, setConfirmCharge] = useState(false);
    const [customerIdToCharge, setCustomerIdToCharge] = useState(null);
    const { setChildAccountInactive} = useTools();
    const [disableCharge, setDisableCharge] = useState(true);
    const { refetch } = useAuth();


    const handleCustomerCharge = async (customerId) => {
        setConfirmCharge(true);
        setCustomerIdToCharge(customerId);
    };

    const handleConfirmCharge = async () => {
        if (customerIdToCharge) {
            setDisableCharge(false); 
            let response = await JobApi.paymentFailedJobs(customerIdToCharge, true);
            if (response && response.status === 'Successful') {
                const updatedJobRecords = jobRecords.filter(record => record.customerId !== customerIdToCharge);
                setJobRecords(updatedJobRecords);
                openNotificationWithIcon('success', "Successfully Recharged");
                
                if (updatedJobRecords.length === 0) {
                    setRecharge(false);
                    setMakepayment(false);
                    setChildAccountInactive(false);
                }
                setHideRecharge(true);
            } else {
                openNotificationWithIcon('error', response.message);
            }
            setDisableCharge(true);
            refetch('recharge');
        }
        setConfirmCharge(false);
    };

    const handleNoClick = () => {
        setConfirmCharge(false);
        setRecharge(false);
    };
    const handleNoConfirm = () => {
        setConfirmCharge(false);
    
    };
    return (
        <>
            <Modal
                open={reCharge}
                onClose={handleNoClick}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ width: '90%', maxWidth: 600, bgcolor: 'background.paper', p: 2, borderRadius: '8px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Recharge Job Records
                        </Typography>
                        <CloseIcon onClick={handleNoClick} style={{ cursor: 'pointer' }} />
                    </div>
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ minWidth: '120px' }}>Email</th> 
                                    <th scope="col" style={{ minWidth: '80px' }}>Role</th> 
                                    <th scope="col" style={{ minWidth: '80px' }}>Cost</th> 
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobRecords.map((record, index) => (
                                    <tr key={index}>
                                        <td>{record.email}</td>
                                        <td>
                                            {record.roleStatus === 'owner' ? 'owner (you)' : record.roleStatus}
                                        </td>
                                        <td>{record.totalcost}</td>
                                        <td>
                                            <Button variant="contained" onClick={() => handleCustomerCharge(record.customerId)} sx={{
                                                fontSize: { xs: '12px', sm: 'inherit' },
                                                minWidth: '80px',
                                                minHeight: '32px',
                                                p: { xs: '4px 8px', sm: '8px 16px' },
                                                mt: { xs: '4px', sm: 0 }, // Adjust top margin for smaller screens
                                            }} style={{backgroundColor: '#01D4D5',fontWeight: 600,color: '#FFFFFF'}}>Charge</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <Button variant="contained" onClick={handleNoClick} style={{backgroundColor: '#92A9B7',fontWeight: 600,color: '#FFFFFF' }}>Close</Button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={confirmCharge}
                onClose={handleNoConfirm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: '-30%',
                    left: '0',
                    width: '100%', // Set width to cover the entire viewport
                    height: '100%', // Set height to cover the entire viewport
                    zIndex: 2000,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                }}
            >
                <Box sx={{ width: '90%', maxWidth: 500, bgcolor: 'background.paper', p: 2, borderRadius: '8px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Confirm Charge
                        </Typography>
                        <CloseIcon onClick={handleNoConfirm} style={{ cursor: 'pointer' }} />
                    </div>
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>

                        <Typography>
                            Are you sure you want to charge?
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <Button variant="contained" onClick={handleConfirmCharge} style={{backgroundColor: '#01D4D5',fontWeight: 600,color: '#FFFFFF', marginRight: '8px' }} disabled={!disableCharge} >Yes</Button>
                        <Button variant="contained" onClick={handleNoConfirm} style={{backgroundColor: '#92A9B7',fontWeight: 600,color: '#FFFFFF' }}>No</Button>
                    </div>
                </Box>
            </Modal>
    </>
);
};

export default ReChargePayment;
