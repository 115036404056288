import styled from "styled-components";

const RoundBox = styled.div`
  display: flex;
  width: 18.75rem;
  padding: 1.34825rem 1.125rem;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  z-index: 2;

  border-radius: 0.4815rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #f4f4f4;
  top: -45px;

  div {
    h4 {
      margin: 10px 0 0 0 !important;
      font-size: 15px;
      color: gray;
      text-align: left;
    }
  }
`;

const RoundBoxContent = styled.div`
  display: flex;
  text-align: left;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;

  .job_icon__wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;

    hgroup {
      display: flex;
      align-items: center;
      margin: 0;

      h4 {
        margin: 0 !important;
      }

      p {
        margin: 0 0 0 5px;
        line-height: 0.2rem;
      }
    }
  }

  h4 {
    font-size: 1rem;
    margin: 0 0 0 5px !important;
  }
`;

const InfoTagWithIcon = styled.div`
  display: flex;
  padding: 0.27806rem 0.69513rem;
  align-items: center;
  gap: 0.34756rem;

  position: absolute;
  left: 4.69794rem;
  top: -0.64031rem;

  border-radius: 1.39031rem;
  background: #fff;
  box-shadow: 0 2.22449px 4.44898px 0 rgba(0, 0, 0, 0.15);

  svg {
    display: flex;
    width: 0.83419rem;
    height: 0.83419rem;
    flex-direction: column;
    align-items: center;
    gap: 0.17381rem;
  }

  span {
    color: var(--Blue-Grey, #2f3f4c);
    font-family: "nova", serif;
    font-size: 0.69513rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 0.83419rem */
  }
`;

const DetailJobBox = styled.div`
  display: flex;
  padding: 10px 0;
  z-index: 2;
  position: relative;

  div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  p {
    font-size: 0.7rem;
    max-width: fit-content;
    margin: 5px 0 0 0;
    text-align: left;
    strong {
      margin-left: 5px;
    }
  }
`;

export { RoundBox, InfoTagWithIcon, RoundBoxContent, DetailJobBox };
