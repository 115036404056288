import React,{ useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

const TooltipMessage = ( ) => {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseEnter = () => {
        setIsHovering(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovering(false);
      };
  return (
    <span
      className="estimate-info-round-div ml-1 position-relative"
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
      role="button"
      onTouchStart={handleMouseEnter}
      onFocus={() => setIsHovering(true)}
      onBlur={() => setIsHovering(false)}
    >
      <FaInfoCircle className="icon-of-i ml-0" />
      {isHovering && (
    <div className="estimatePriceInfo-div">
        <div className="reactiveAccount-triangle"></div>
        <div className="reactiveAccount-inner-div">
        <h6 className="custom-h6">
    <span className="custom-span">Why was my card declined?</span>
    <div className="custom-div">
        Your card can be declined for many reasons, but here are some common steps you can take to avoid this:
        <br />
                    
                    <div className = "mt-2 reactiveAccount-point "> 
                        - Please make sure you have sufficient funds in your<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;account to pay the balance<br/>
                        - Please check for a notification from the card issuer<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;asking if you want to authorize this payment<br/>
                        - If this card is not working, try adding a different card<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;to your account instead
                    </div>
                </div>
            </h6>   
        </div>
    </div>
)}

      
        
    </span>
  );
};

export default TooltipMessage;
