import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import GeekerLogo from "utils/GeekerLogo";

export default function Header({ link, display = false, linked_logo = true }) {
  const handleLogoLink = (e) => {
    if (link) {
      window.localStorage.removeItem("CurrentStep");
      window.location.href = link;
    }
  };

  return (
    <Row
      align="middle"
      style={{
        width: "100%",
        height: "auto",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Col align="center" md={12} xs={12}>
        {linked_logo && (
          <Link to={() => false} onClick={handleLogoLink}>
            <GeekerLogo styles={{ marginTop: "2%", width:"200px"}} className="geeker-logo geeker-logo-new-header" />
          </Link>
        )}

        {!linked_logo && (
          <Link to={() => false} style={{ cursor: "unset" }}>
            {" "}
            <GeekerLogo className="geeker-logo geeker-logo-new-header" styles={{ marginTop: "2%", width:"200px"}} />
          </Link>
        )}
      </Col>
    </Row>
  );
}

