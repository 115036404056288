import { decryptResponse } from "utils";
import apiClient from "./index";
// import { SESSION_EXPIRE_URL } from '../constants';

/**
 * Following API will fetch customer count on the basis of params
 * @params : params (Type: Object)
 * @return : data (Type: Object)
 * @author : Vinit
 **/
export async function getTotalCustomerCount(params) {
  return apiClient.get("/public", { params }).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function getSubscriptionPlan(planId) {
  return apiClient.get(`/public/get-a-plan/${planId}`).then((response) => {
    if (response) {
      if(response?.data?.succes == false || response?.data?.status == false){
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
    }
    return Promise.reject();
  });
}

export async function getShortenUrl(shortId) {
  try {
    const response = await apiClient.get(`/public/get-redirect-url/${shortId}`);
    if (response.data.success === false || response.data.status === false) {
      return response.data;
    }
    return response.data;
  } catch (error) {
    console.error('Error in getShortenUrl', error);
    return Promise.reject(error);
  }
}
export async function updateShortenUrl(shortId) {
  try {
    const response = await apiClient.get(`/public/update-redirect-url/${shortId}`);
    if (response.data.success === false || response.data.status === false) {
      return response.data;
    }
    return response.data;
  } catch (error) {
    console.error('Error in getShortenUrl', error);
    return Promise.reject(error);
  }
}

