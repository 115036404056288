import React, { Fragment, useState } from "react";
import RecordingIcon from "../../../../assets/images/recording.svg"
import DownloadIcon from "../../../../assets/images/download.svg"
import "./jobrecordings.css"
import JobRecordingAction from "../JobRecordingAction/JobRecordingAction";
import ViewRecordings from "./ViewRecordings";
import Button from '@mui/material/Button';

// This component will list recordings and disable them when not purchased ( or not VIP member), as well as display action buttons when clickability is disabled. ~ Jagroop
const JobRecordingPanel = React.memo(({ meetingRecordingDetail, job, user, setMeetingRecordingDetail }) => {
  const { meetingRecordings = [], isLocked = true } = meetingRecordingDetail;
  
  return (
    <>
      <div className={isLocked ? "disable-recording-style" : ""} >
        {meetingRecordings?.length > 0 && meetingRecordings.map((item, index) => (
          <RecordingList item={item} key={index} isLocked={isLocked} />
        ))}
      </div>
      {isLocked && <JobRecordingAction job={job} user={user} meetingRecordingDetail={meetingRecordingDetail} setMeetingRecordingDetail={setMeetingRecordingDetail} />}
    </>
  );
});

// This will list down recordings with download option in Job details page ~ Jagroop
const RecordingList = React.memo(({ item, isLocked }) => {
  const [open, setOpen] = useState(false);

  const formatVideoLink = (videoUrl) => {
    if (!isLocked) {
      const updatedUrl = videoUrl.replace("http://", "https://");
      return updatedUrl;
    }
    return null; // Returning null if locked
  };

  const handleOpen = () => setOpen(true);

  const handleDownload = (videoUrl) => {
    if (videoUrl) {
      fetch(videoUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'video.mp4');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
    } else {
      // Handle case where video is locked
      console.log("Video is locked. Cannot download.");
    }
  };

  return (
    <>
      <div className="p-2 d-flex flex-wrap align-items-center" style={{ gap: "20px" }} key={item?.id}>
        <div className="col-md-6 d-flex flex-row justify-content-start">
          <img src={RecordingIcon} alt="recording-icon" height="25.18px" width="32px" />
          <p className="recording-title-style">{item?.recordingName}</p>
        </div>
        <Button disabled={isLocked} variant="outlined" onClick={() => handleDownload(formatVideoLink(item.videoUrl))}>Download</Button>
        <Button disabled={isLocked} variant="outlined" onClick={handleOpen}>View</Button>
        <ViewRecordings handleOpen={handleOpen} open={open} setOpen={setOpen} videoUrl={formatVideoLink(item.videoUrl)} />
      </div>
      <hr style={{ margin: "0px", padding: "0px" }} />
    </>
  );
});

export default JobRecordingPanel;
