import React from 'react';
import './style.css';

const SubscriptionCard = () => {

    const handleButtonClick = () => {
        window.open('https://geeker.co/contact/', '_blank');
      };

    return (
        <div className="subscription-flip-card-front d-flex flex-column justify-content-between w-100p min-height-580px">
            <div className="d-flex flex-column">
                <span className="subscription-flip-card-heading-platinum mb-30">
                    Platinum
                </span>
                <div className='style-text-div'>

                    <span className="subscription-flip-card-price-platinum">
                        Contact us for a customized quote
                    </span>
                    <div className="mb-30">
                        <span className="subscription-flip-card-features-platinum">
                            ✔ For teams over 150
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-100p btn-div">
                <button
                    className="basic-button-platinum"
                    style={{
                        height: '60px',
                        width: 'inherit',
                        background: '#01D4D5',
                        color: 'white'
                    }}
                    onClick={handleButtonClick}
                >
                    Contact Sales
                </button>
                {/* <div className="learn-more-platinum">
                    <a href="#">Learn more</a>
                </div> */}
            </div>
        </div>
    );
};

export default SubscriptionCard;
