import { getUserIdFromCookie } from "utils";
import apiClient from "./index";
// import { SESSION_EXPIRE_URL } from '../constants';

export async function createEarningDetails(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/earning-details?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function earningDetailsList(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/earning-details/list?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function getEarningDetails(id) {
  const userId = getUserIdFromCookie()
  return apiClient.get(`/earning-details/${id}?cuId=${userId}`).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function getEarningDetailsByJob(jobId) {
  const userId = getUserIdFromCookie()
  // console.log('jobId in api ::',jobId)
  return apiClient.get(`/earning-details/by-job/${jobId}?cuId=${userId}`).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

/* Commented by @Vinit on 21-12-2023 since it's not in use */
/**
 * This api updates the earning detail on the given id param with the given data object.
 * @params : id (Type:String), data (Type:Object)
 * @response: returns updated earning detail
 * @author : Manibha
 **/
// export async function updateEarningDetails(id, data) {
//   const userId = getUserIdFromCookie()
//   return apiClient.put(`/earning-details/${id}?cuId=${userId}`, data).then((response) => {
//     if (response) {
//       return response.data;
//     }
//     return Promise.reject();
//   });
// }
