import { useMutation, useQuery, useQueryClient } from "react-query";
import apiClient from "./index";
import { getUserIdFromCookie } from "utils";
export const fetchInvites = async () => {
  const userId = getUserIdFromCookie()
  let { data } = await apiClient.get(`/invite?cuId=${userId}`);
  return data;
};


const fetchInvite = async (inviteCode) => {
  let { data } = await apiClient.get(`/invite/get/${inviteCode}`);

  return data?.data;
};
export const useFetchInvites = () => useQuery("invites", fetchInvites);

const sendInvite = async (inviteData) => {
  let { data } = await apiClient.post("/invite/new", inviteData);
  return data;
};

export const useInviteUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((inviteData) => sendInvite(inviteData), {
    onSuccess: (data) => {
      if (data.success) {
        queryClient.invalidateQueries("invites");
      }
    },
    onError: (data) => {
      console.log("error", data);
    },
  });
};

/**
 *
 * @param {*} list of uploaded users
 * @description : This API will take the list of all the users extracted from excel and send email to them
 * @author : Jagroop
 */
export async function inviteListOfUsers(data) {
  return apiClient.post("/invite/users-list", data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export const useFetchInvite = (inviteId) =>
  useQuery(["invite", inviteId], () => fetchInvite(inviteId));
