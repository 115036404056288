import { getUserIdFromCookie } from "utils";
import apiClient from "./index";
// import { SESSION_EXPIRE_URL } from '../constants';

export async function createBillingDetails(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/billing-details?cuId=${userId}`, data).then((response) => {
    // console.log("response billing-details :::",response)
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function billingDetailsList(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/billing-details/list?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

// export async function getBillingDetails(id) {
//   const userId = getUserIdFromCookie()
//   return apiClient.get(`/billing-details/${id}?cuId=${userId}`).then((response) => {
//     if (response) {
//       return response.data;
//     }
//     return Promise.reject();
//   });
// }
export async function getBillingDetailsByJob(jobId) {
  const userId = getUserIdFromCookie()
  // console.log('jobId in api ::',jobId)
  return apiClient.get(`/billing-details/by-job/${jobId}?cuId=${userId}`).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}