import { getUserIdFromCookie, openNotificationWithIcon } from "utils";
import apiClient from "./index";
// import { SESSION_EXPIRE_URL } from '../constants';

export async function getAllPlans(params) {
  const userId = getUserIdFromCookie()
  return apiClient.get(`/subscriptions?cuId=${userId}`, { params }).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

/* Commented by @Vinit on 21-12-2023 since it's not in use */
/**
 * Following API will fetch details for a single subscription plan
 * @params : params (Type: Object)
 * @return : data (Type: Object)
 * @author : Vinit
 **/
// export async function getAPlan(params) {
//   const userId = getUserIdFromCookie()
//   return apiClient
//     .get(`/subscriptions/get-a-plan?cuId=${userId}`, { params })
//     .then((response) => {
  //       if (response) {
    //         return response.data;
    //       }
    //       return Promise.reject();
    //     });
    // }
    
    export async function buySubscription(params) {
      const userId = getUserIdFromCookie()
      return apiClient
      .post(`/subscriptions/buy-subscription?cuId=${userId}`, { params })
      .then((response) => {
        if (response) {
          return response?.data;
        }
        return Promise.reject();
      })
      .catch(() => {
        console.log("Axios Error from console");
        // console.log("Axios Error from console", error)
      });
    }
    
    export async function cancelSubscription(params) {
      const userId = getUserIdFromCookie()
      return apiClient
      .post(`/subscriptions/cancel-subscription?cuId=${userId}`, { params })
      .then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
    }
    
/* Commented by @Vinit on 21-12-2023 since it's not in use */
// export async function cancelPendingSubscription(params) {
//   const userId = getUserIdFromCookie()
//   return apiClient
//     .post(`/subscriptions/cancel-pending-subscription?cuId=${userId}`, { params })
//     .then((response) => {
//       if (response) {
//         return response.data;
//       }
//       return Promise.reject();
//     });
// }

export async function createSubscriptionHistory(params) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/subscriptions/subscription-history?cuId=${userId}`, { params })
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Following function is used to fetch subscrition history of a specific user.
 * @params =  customer id
 * @response : subscription history
 * @author : Vinit
 */
export async function getSubscriptionHistory(params) {
  const userId = getUserIdFromCookie()
  return apiClient
    .get(`/subscriptions/fetch-subscription-history?cuId=${userId}`, { params })
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}
