import { Modal } from "antd";
import { Button } from 'react-bootstrap';
import React from "react";

export const ExtraCheckBeforePostJobModal = ({closePendingModal,customerConfirm,customerConfirmSameTech,customerConfirmDraftJob,setCustomerConfirm,setCustomerConfirmSameTech,setCustomerConfirmDraftJob,postAgainFunction,message}) => {


    const functionCallDecider = () => {
      if (customerConfirm) {
        postAgainFunction("standard");
      }
      if (customerConfirmSameTech) {
        postAgainFunction("sameTech");
      }
      if (customerConfirmDraftJob) {
        postAgainFunction("draft");
      }
    };

    const resetStates =()=>{
        setCustomerConfirm(false);
        setCustomerConfirmSameTech(false);
        setCustomerConfirmDraftJob(false);
    }


  return (
    <>
      <Modal
        style={{ top: 40 }}
        closable={true}
        onCancel={closePendingModal}
        visible={
          customerConfirm || customerConfirmSameTech || customerConfirmDraftJob
        }
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        bodyStyle={{ height: 170, paddingTop: 50 }}
        footer={[
          <Button
            className="btn app-btn app-btn-light-blue modal-footer-btn"
            onClick={() => resetStates()}
            key="no"
          >
            <span></span>Back To Dashbord
          </Button>,

          <Button
            id="confirm-create-new-job-btn"
            className="btn app-btn job-accept-btn modal-footer-btn"
            onClick={() => functionCallDecider()}
            key="yes"
          >
            <span></span>Create New
          </Button>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {message}
          </span>
        </div>
      </Modal>
    </>
  );
};


export const InProgressJobModal = ({closePendingModal,pendingJobModal,InprogressJobMsg}) => {
  return (
    <>
      <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={pendingJobModal}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              id="confirm-create-new"
              className="btn app-btn job-accept-btn modal-footer-btn"
              onClick={closePendingModal}
              key="yes"
            >
              <span></span>Close
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {InprogressJobMsg}
          </span>
        </div>
      </Modal>
    </>
  );
};