import React, { useState, useEffect } from "react";
import { useUser } from "../../../../../context/useContext";
import * as TechnicianApi from "../../../../../api/technician.api";
import mixpanel from "mixpanel-browser";



export default function JobTakenPreferences({ setTechProfile, techProfile }) {
    const { user } = useUser();
    const [status, setStatus] = useState(user?.technician?.profile?.jobTakenNotification);

    useEffect(() => {
        const temptechProfile = { ...techProfile };
        console.log('jobTakenPreference ', user, techProfile);
        temptechProfile.
            jobTakenPreferences
            .complete = true;
        setTechProfile(temptechProfile);
    }, []);

    const toggleJobTakenPreference = async () => {

        if (status) {

            mixpanel.identify(user.email);
            mixpanel.track("Technician - Job Taken notification Inactive", { user: user.email });

            await TechnicianApi.updateTechnicianWithParams(
                user.technician.id,
                { "profile.jobTakenNotification": false }
            );
        } else {
            //mixpanel code
            mixpanel.identify(user.email);
            mixpanel.track("Technician - Job Taken notification Active", { user: user.email });

            await TechnicianApi.updateTechnicianWithParams(
                user.technician.id,
                { "profile.jobTakenNotification": true }
            );
        }
        setStatus(!status);

    };

    return (
        <>
            <div
                style={{
                    height: "120px",
                    display: "flex",
                    paddingTop: "5px",
                    paddingBottom: "10px",
                    marginTop: "10px",
                    // borderRadius: "11px",
                    // boxShadow: "9px 9px 15px 1px rgb(170 170 170 / 75%)",
                    backgroundColor: "#F6FBFF",
                    width: "90%",
                }}
            >
                <CardItem
                    onSwitchClick={toggleJobTakenPreference}
                    title="Job Taken Notification"
                    switchText={status ? "ON" : "OFF"}
                    isSwitchActive={status}
                />
            </div>

        </>
    );
}

const CardItem = ({
    switchText,
    title,
    style,
    showSwitch = true,
    isSwitchActive,
    onSwitchClick,
}) => {
    return (
        <div style={{ flex: 1, marginLeft: "30px", ...style }}>
            <h5
                style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                    color: "#475258",
                }}
            >
                {title}
            </h5>
            <Switch
                onSwitchClick={onSwitchClick}
                text={switchText}
                showSwitch={showSwitch}
                isSwitchActive={isSwitchActive}
            />
        </div>
    );
};

const Switch = ({ text, showSwitch, isSwitchActive, onSwitchClick }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {showSwitch && (
                <div
                    onClick={onSwitchClick}
                    style={{
                        cursor: "pointer",
                        background: isSwitchActive ? "#1bd4d5" : "#dce7ed",
                        justifyContent: isSwitchActive ? "flex-end" : "flex-start",
                        display: "flex",
                        height: "30px",
                        borderRadius: "24px",
                        width: "60px",
                        padding: "0.2rem",
                    }}
                >
                    <div
                        style={{
                            width: "45%",
                            background: isSwitchActive ? "#ffffff" : "#dce7ed",
                            borderRadius: "50%",
                            height: "100%",
                            boxShadow:
                                !isSwitchActive && "0px 0px 1px 2px rgb(170 170 170 / 75%)",
                        }}
                    ></div>
                </div>
            )}
            <h5
                style={{
                    fontSize: "15px",
                    color: isSwitchActive ? "#1fc7c8" : "#72838d",
                    fontWeight: "bold",
                    marginLeft: showSwitch ? "20px" : 0,
                    marginBottom: 0,

                    marginTop: showSwitch ? 0 : "0.5rem",
                }}
            >
                {text}
            </h5>
        </div>
    );
};

