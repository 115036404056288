import React, { useEffect, useState } from "react";
import { showChatIconOrNot } from "utils/dashboardUtilities";
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import TextsmsIcon from '@mui/icons-material/Textsms';
import * as JobApi from "../../../api/job.api"
import { useTools } from "context/toolContext";
import { useJob } from "context/jobContext";
import { useSocket } from "context/socketContext";

const ChatButton =({job, user})=>{
    const { setJobId,setTypeForDetails,setStepDeciderDashboard} = useTools()
    const { socket } = useSocket();
    const {fetchJob} = useJob();
    const [showChatBtnType, setChatBtnType] = useState({
      shouldRenderIconButtonOnSocket: false,
      shouldRenderIconButtonWithRedDot: false,
      shouldRenderIconButton: false,
    });
    const [jobIdFromMessage, setJobIdFromMessage] = useState([])
    useEffect(() => {
      const dataArray = []
      socket.on("open-chat-panel-talkjs", async (data) => {
        
         if ((data?.technicianId === user?.technician?.id) || (data?.technicianId === user?.id) ) {
          dataArray.push(data?.jobId);
          setJobIdFromMessage((prevData) => [...prevData, data?.jobId])
          JobApi.updateJob(data?.jobId, { tech_message_dashbord: true })
            .then(response => {
              // Handle the response or perform any necessary actions
              // console.log("Job updated successfully:", response);
            })
            .catch(error => {
              // Handle any errors that occurred during the update process
              console.error("Error updating job:", error);
            });
        }
      })
  
      socket.on("open-chat-panel-talkjs-for-customer", async (data) => {
       
        if (data?.customerId === user?.customer?.id) {
          setJobIdFromMessage(data?.jobId)
          await JobApi.updateJob(data?.jobId, { tech_message_dashbord: true })
        }
      })
    }, [socket])

    useEffect(()=>{
      if(job && user){
         const result = showChatIconOrNot(job,user,jobIdFromMessage);
        //  console.log("ChatButtonChatButtonChatButtonChatButton",result)
         setChatBtnType((result))
      }

    },[])


    const pushToChatDetails = async (e) => {
      e.stopPropagation();
      const jobid = job?.id;
      await setJobId(jobid);
      await fetchJob(jobid)
      await JobApi.updateJob(jobid, { tech_message_dashbord: false });
      let jobResponse = await JobApi.retrieveJob(jobid);
      setTypeForDetails("details");
      const scheduleAccepted = jobResponse.schedule_accepted == false;
      const techDeclinedJob =
        jobResponse?.tech_declined_ids.includes(user?.technician?.id) == false;
      const isTech = user && user?.userType == "technician";
      if (scheduleAccepted && isTech && techDeclinedJob) {
        setTypeForDetails("apply");
      }
      setStepDeciderDashboard(6);
    };


    return<>
    {
        (showChatBtnType?.shouldRenderIconButtonOnSocket || showChatBtnType.shouldRenderIconButton) ?
          <IconButton name={job.id}
            onClick={pushToChatDetails}
            title="Click to see job details." aria-label="delete">
            {showChatBtnType.shouldRenderIconButtonWithRedDot ?
            <Badge sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "red"
              }
            }} variant="dot">
              <TextsmsIcon style={{ fontSize: '30px', color: '#1bd4d5' }} />
            </Badge> : <TextsmsIcon style={{ fontSize: '30px', color: '#1bd4d5' }} /> }

          </IconButton>
          : <></> }
    </>
}



export default ChatButton
