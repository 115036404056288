import { decryptResponse } from "utils";
import apiClient from "./index";

export async function retrieveMeetingRecording(data,jobId) {
  return apiClient.post(`/recording/${jobId}`,data).then((response) => {
    if (response) {
      if(response?.data?.success == false){
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
    }
    return Promise.reject();
  });
}