import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import { Player } from "@lottiefiles/react-lottie-player";
import { useAuth } from "../../../../context/authContext";
import * as SoftwareApi from "../../../../api/software.api";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const ExamFail = () => {
  const { logout } = useAuth();
  const [additionalSoftwareList, setAdditionalSoftwareList] = useState([]);

  /**
   * Return All active additional softwarelist
   * @author : Mritunjay Chaurasia
   **/
  useEffect(() => {
    (async () => {
      const additionalSoftwareListResponse =
        await SoftwareApi.getOtherSoftwareList();
      // console.log("Addtional Software List : ", additionalSoftwareListResponse);
      if (
        additionalSoftwareListResponse &&
        additionalSoftwareListResponse?.data
      ) {
        setAdditionalSoftwareList(
          additionalSoftwareListResponse?.data.filter(
            (software) => software.status === "Active"
          )
        );
      }
    })();
  }, []);

  /**
   * Function that handles the logout button to logout the user
   * @author : Kartik
   **/
  const Logout = useCallback(() => {
    Modal.confirm({
      title: "Logout Now?",
      okText: "Logout",
      cancelText: "Cancel",
      className: "logout-modal",
      onOk() {
        logout();
      },
    });
  }, [logout]);

  return (
    <>
      <div className="w-100p">
        <div className="exam-fail-logout-btn-div w-100p d-flex justify-content-end">
          <Stack spacing={2} direction="row">
            <Button
              className="logout-btn"
              style={{ textTransform: "none" }}
              onClick={Logout}
              variant="text"
            >
              Logout
            </Button>
          </Stack>
        </div>
        <div className="exam-fail-animation-div w-100p">
          <Player
            autoplay={true}
            loop={true}
            keepLastFrame={true}
            src="https://assets9.lottiefiles.com/packages/lf20_ckcn4hvm.json"
            className="exam-fail-animation"
            speed={0.5}
          ></Player>
        </div>
        <div className="exam-fail-message-div w-100p">
          <h1 className="mt-0 mb-2 ">
            Thank you for taking the time to sign up for Geeker.
          </h1>
          <h5>Unfortunately, you did not meet the requirements to proceed.</h5>
        </div>
        <div className="exam-fail-message-div w-100p mt-5">
          <div>
            <h5 className="mb-3">
              Have other expertise? You can try to qualify for those instead.
            </h5>
            <div className="additionalSoftwaresList">
              {additionalSoftwareList &&
                additionalSoftwareList.length > 0 &&
                additionalSoftwareList.map((additionalSoftwareList, i) => {
                  return (
                    <img
                      className="additionalSoftwareImage"
                      alt={additionalSoftwareList.name}
                      src={additionalSoftwareList.blob_image}
                      title={additionalSoftwareList?.name}
                    />
                  );
                })}
            </div>
          </div>
          <h5 className="mt-5">
            If you feel there is a mistake, or if you are experiencing a
            technical issue, please email us at{" "}
            <a href="mailto:info@geeker.co">
              <span className="geekerMailInfo">info@geeker.co</span>
            </a>{" "}
            and someone will assist you.
          </h5>
        </div>
      </div>
    </>
  );
};

export default ExamFail;
