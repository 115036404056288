import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ClearIcon from '@mui/icons-material/Clear';
// import VideoRecorder from 'react-video-recorder'
import * as UploadApi from "../../../../../api/upload.api"
import { SERVER_URL } from '../../../../../constants/index';
import * as Techapi from "../../../../../api/technician.api";
import { Spin } from 'antd';
import { useUser } from 'context/useContext';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
let RANDOM_STRING = (Math.random() + 1).toString(36).substring(7);

export default function VideoRecordingModal({startRecording,setVideoStates,user}) {

  const [videoRecStates, setVideoRecStates] = useState({videoBlobDetails:[], showUploadBtn : false})
  const [loader , setLoader]=useState(false)
  const { refetch } = useUser();
  const { id = ""} = user?.technician;

  // window.onbeforeunload = function() {
  //   return "Data will be lost if you leave the page, are you sure?";
  // };

  const uploadResumeVideo =async()=>{
    setLoader(true)
    if(videoRecStates.videoBlobDetails){
        const formData = new FormData();
        formData.append("user", `${user?.id}_video_resume`);
        formData.append("file", videoRecStates.videoBlobDetails);
        let uploadedResponse = await UploadApi.uploadVideo(formData);
        if(uploadedResponse){
            const videoFileURL = `${SERVER_URL}/videos/${uploadedResponse}`;
            await Techapi.updateTechnicianWithParams(id, {
                "profile.videoResume": videoFileURL,
              });
            let fileName = videoFileURL.split("/")
            fileName = fileName[fileName.length-1]
            setVideoStates((prev) => ({ ...prev, videoUrl: videoFileURL,startRecording:false ,fileName:fileName }))
            refetch("callPageVdoResume")

        }
    }
    setLoader(false)
  }

  //  Update Blob of recoded video and store it in the field.
  const videoBlobDetails = (videoBlob) => {
    const resumeVideo = blobToFile(
      videoBlob,
      `${user?.id}_video_resume${RANDOM_STRING}`
    );
    console.log("resumeVideoresumeVideo", resumeVideo);
    setVideoRecStates((prev) => ({
      ...prev,
      videoBlobDetails: resumeVideo,
      showUploadBtn: true,
    }));
  };

  // This will update blob in required format ~ Jagroop
  function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.filename = fileName;
    return theBlob;
  }

  const clossModal=()=>{
    setVideoStates({videoBlobDetails:[], showUploadBtn : false})
  }

  

  return (
    <div>
      <Modal
        open={startRecording}
        onClose={!startRecording}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="record-video-style" sx={style}>
          {!(videoRecStates?.showUploadBtn) && <span className='video-cross-icon-btn-style' ><ClearIcon onClick={clossModal} style={{cursor : "pointer"}} /> </span>}
          {loader && (
            <div className="loader-overlay">
              <Spin style={{ color: "#17D6D7" }} size="large" />
            </div>
          )}
          {/* <VideoRecorder
            timeLimit={180000}
            onRecordingComplete={(videoBlob) => {
              videoBlobDetails(videoBlob);
            }}
          /> */}
          {videoRecStates?.showUploadBtn && (
            <Button id="upload-resume-video-style" onClick={uploadResumeVideo}>
              Upload Video
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}