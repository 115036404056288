import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckInCircle from "components/CheckInCircle";
import React, { useEffect, useState } from "react";
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { IconButton } from '@material-ui/core';
// import { Close as CloseIcon } from '@material-ui/icons';
import { useMediaQuery } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Space, Upload, message } from "antd";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useHistory } from "react-router";
import {
  openNotificationWithIcon,
  setMixpanelEntry,
  videoExtensionUpdater,
  videoNameExtractor,
} from "utils";
import * as UploadApi from "../../../../../api/upload.api";
import {
  FILE_SIZE_MSG,
  FILE_TYPE_NOT_SUPPORTED,
  ONE_FILE_VALIDATION,
  SECRET_KEY,
  SERVER_URL,
  SOMETHING_WENT_WRONG_MSG,
  VDO_REMOVE_MSG,
  VDO_SUCCESS_MSG,
  VIDEO_FILE_TYPES,
  VIDEO_RESUME_TITLE,
} from "../../../../../constants/index";
import VideoRecordingModal from "./VideoRecordingModal";
import "./register-style.css";
const VideoResumeUpload = ({ user, refetch, setVideoUploadInProgress, videoUploadInProgress, setVideoUploaded }) => {
  const [videoStates, setVideoStates] = useState({
    videoFile: [],
    videoUrl: "",
    startRecording: false,
    fileName: "",
    privateVideoUrl: ""
  });
  const [isHovering, setIsHovering] = useState(false);
  const [placeHolder, setPlaceHolder] = useState('right');
  const { Dragger } = Upload;
  const { id = "", profile = {} } = user?.technician;
  const history = useHistory();
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed
  const [isOpenTooltip, setIsOpenTooltip] = useState(true);

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleTouchStart = () => {
    setIsHovering(true);
  };

  const handleTouchEnd = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    const isValidPath = history.location.pathname == "/technician/register_steps"
    if (isValidPath && videoUploadInProgress) {
      window.onbeforeunload = function () {
        return "Data will be lost if you leave the page, are you sure?";
      };
    }
    return () => {
      window.onbeforeunload = null;
    };

  }, [videoUploadInProgress, history])

  const props = {
    name: "file",
    accept: VIDEO_FILE_TYPES,
    multiple: false,
    videoFile: videoStates.videoFile,
    action: `${SERVER_URL}/api/uploads/videoResume`,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem(SECRET_KEY)}`,
    },
    data: { user: `${user?.id}` },
    headers: {
      "Authorization": `Bearer ${localStorage.getItem(SECRET_KEY)}`,
    },
    async onRemove() {
      await removeVideo();
    },
  };

  // This function is used to validate the uploaded file ~ Jagroop
  const beforeUploadValidation = (file) => {
    try {
      if (videoStates?.videoFile?.length > 1) {
        openNotificationWithIcon("error", "Warning", ONE_FILE_VALIDATION);
        return false;
      }
      let extension = (file?.type).split("/")[1]
      extension = videoExtensionUpdater(extension)
      console.log("extesion", extension)
      console.log("VIDEO_FILE_TYPES", { VIDEO_FILE_TYPES, type: file.type, extension })
      if (!VIDEO_FILE_TYPES.includes(`.${extension}`)) {
        openNotificationWithIcon("error", "Warning", FILE_TYPE_NOT_SUPPORTED);
        return false;
      }

      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 100) {
        openNotificationWithIcon("error", "Warning", FILE_SIZE_MSG);
        return false;
      }
      return true;
    } catch (error) {
      console.log("error while validating resume video upload", error);
      openNotificationWithIcon("error", "Warning", SOMETHING_WENT_WRONG_MSG);
      return false;
    }
  };

  // This function is responsible for uploading video resume to server and create a sharable link and update link in technician profile ~ Jagroop
  const uploadVideo = (info) => {
    try {

      const { status, response } = info.file;
      if (status !== "uploading") {
        console.log(
          "Video Upload : Non Uploading State",
          info.file,
          info.fileList
        );
      }
      if (status === "uploading") {
        setVideoUploadInProgress(true)
        setVideoStates((prev) => ({ ...prev, videoFile: [info.file] }));
      }
      if (status === "done" && response["publicUrl"] && response["privateURL"]) {
        // Set Success Message and upload the video URL to Techncian Profile
        message.success(`${info?.file?.name} file uploaded successfully.`);
        setVideoStates((prev) => ({ ...prev, videoFile: [info.file], fileName: info?.file?.name }));
        const videoFileURL = response.publicUrl;

        console.log("tech video file url:", videoFileURL);
        setVideoStates((prev) => ({ ...prev, videoFile: [info.file], videoUrl: videoFileURL, privateVideoUrl: response?.privateURL }));
        setVideoUploaded(true)
        setMixpanelEntry(user, "Technician - uploaded profile video");
        setVideoUploadInProgress(false)
      } else if (status === "error") {
        message.error(`${info?.file?.name} file upload failed.`);
        setVideoUploadInProgress(false)
        setVideoUploaded(false)
      }
      return true;
    } catch (error) {
      console.log("error while uploading resume video upload", error);
      setVideoUploadInProgress(false)
      setVideoUploaded(false)
      return false;
    }
  };

  // This function is used to remove video from server and technician profile as well ~ Jagroop
  const removeVideo = async () => {
    try {
      const videoUrl = videoStates.privateVideoUrl || profile.videoResume;
      if (videoUrl) {
        const videoId = videoNameExtractor(videoUrl);
        await UploadApi.removeVideoResume({ techId: id, videoId: videoId });
        setVideoStates({ videoFile: [], videoUrl: "", fileName: "" });
        setVideoUploadInProgress(false)
        setVideoUploaded(false)
        return true;
      } else {
        setVideoStates({ videoFile: [], videoUrl: "", fileName: "" });
        setVideoUploadInProgress(false)
        setVideoUploaded(false)
        return false;
      }

    } catch (error) {
      console.log("error while removing resume video upload", error);
      setVideoStates({ videoFile: [], videoUrl: "", fileName: "" });
      setVideoUploadInProgress(false)
      setVideoUploaded(false)
      return false;
    }
  };



  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: '15px 20px', 
      position: 'relative',
      maxWidth: 300, 
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      position: 'absolute',
      top: '100%', 
      left: '50%', 
      transform: 'translateX(-50%)', 
      '&::before': {
        border: `1px solid #e6cfcf`,
      },
    },
  }));

  useEffect(() => {
    if (isMobile) {
      setPlaceHolder('bottom');
      console.log('isMobile is true 1', isMobile);
    }
  }, [isMobile]);

  const deleteVideo = async () => {
    const delResponse = await removeVideo();
    if (delResponse) {
      refetch("callPageVdoResume")
      message.success(VDO_REMOVE_MSG);
    }
  }

  //  This function is used to check if video resume is already  uploaded or not ~ Jagroop
  const isResumeUploaded = () => {
    const haveVideoFile =
      videoStates?.videoFile?.length > 0 && videoStates?.videoFile[0]?.filename;
    const haveVideoURL = videoStates?.videoUrl;
    const isAlreadyHaveVdo = profile?.videoResume;

    if (haveVideoFile || haveVideoURL || isAlreadyHaveVdo) {
      return true;
    }
    return false;
  };

  return (
    <>
      <LightTooltip
        title={
          <div className="introContainer" >
            Please introduce yourself and briefly describe your experience and expertise.
            <IconButton className="closeButton" onClick={() => { setIsOpenTooltip(false) }} >
              <CloseIcon className="closeIcon" />
            </IconButton>
          </div>

        }
        arrow
        open={isOpenTooltip}
        placement={placeHolder}
      >
        <div className=" upload-div d-flex justify-content-center align-items-center flex-column">


          <div className="d-flex justify-content-start align-items-start w-100p check-in-circle-div">
            <CheckInCircle bgColor={isResumeUploaded() ? "cyan" : "grey"} />
            <span className="finalise-upload-text">{VIDEO_RESUME_TITLE}</span>
            <span
              className="video-resume-tooltip ml-1 position-relative"
              onMouseOver={handleMouseEnter}
              onMouseOut={handleMouseLeave}
              role="button"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              onFocus={() => setIsHovering(true)}
              onBlur={() => setIsHovering(false)}
            >
              <FontAwesomeIcon
                icon={faInfo}
                className="icon-of-i-video"
              />
              {isHovering ? (
                <div className="estimatePriceInfo-div">
                  <div className="video-resume-tooltip-triangle"></div>
                  <div className="video-resume-tooltip-inner ">
                    <p className="estimate-price">
                      File limit - 100MB<br />Accepted File Type - ".mp4 .mov .mkv .webm"
                    </p>
                  </div>
                </div>
              ) : null}
            </span>

          </div>

          {videoStates.startRecording ? (
            <VideoRecordingModal startRecording={videoStates.startRecording} setVideoStates={setVideoStates} user={user} />
          ) : (
            <>

              <div className={`${profile?.videoResume ? "disable-image" : ""}`} style={{ maxWidth: "250px" }}>
                <Dragger {...props} fileList={videoStates.videoFile} onChange={uploadVideo} style={(isResumeUploaded() || videoUploadInProgress) ? { pointerEvents: "none", cursor: "default" } : ""} beforeUpload={beforeUploadValidation} >
                  {isResumeUploaded() ? (
                    <div className="uploadResume d-flex justify-content-center align-items-center flex-column  ">
                      <span>{VDO_SUCCESS_MSG}</span>
                    </div>
                  ) : (
                    <div className="uploadResume d-flex justify-content-center align-items-center flex-column  ">
                      <div className="cloud-upload">
                        <FaCloudUploadAlt className="cloud-icon" />
                      </div>
                      <span className=" drag-drop drag-drop-text">
                        Drag & Drop or &nbsp;
                        <span className="drag-drop-upload">Upload</span>
                      </span>
                    </div>
                  )}
                </Dragger>
              </div>

            </>
          )}
          <div className="delete-resume-Button">
            {isResumeUploaded() ? (
              (videoStates?.fileName == "" || profile?.videoResume != "") && <ReusbaleBtn onClickEvent={deleteVideo} btnTitle="Delete Video" />
            ) : <></>
              // <>
              //   {videoStates?.startRecording ? (
              //      <ReusbaleBtn onClickEvent={toggleVideoRecording} btnTitle="Stop Recording" />
              //   ) : (
              //     <ReusbaleBtn onClickEvent={toggleVideoRecording} btnTitle="Start Recording" />
              //   )}
              // </>
            }
          </div>
        </div >
      </LightTooltip>

    </>
  );
};

const ReusbaleBtn = ({ onClickEvent, btnTitle }) => {
  return (
    <>
      <Space wrap>
        <Button type="text" onClick={onClickEvent} size="small">
          {btnTitle}
        </Button>
      </Space>
    </>
  );
};

export default React.memo(VideoResumeUpload);
