import apiClient from "./index";

export async function getPartnerWithShortName(data) {
  try {
    return apiClient
      .get(`/partner/getPartnerWithShortName/${data}`)
      .then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
  } catch (err) {
    console.log(err);
  }
}
