import React from "react";
import logo from "../../../../assets/images/newLogo.png";
import { useTools } from "../../../../context/toolContext";
import GeekerLogo from "utils/GeekerLogo";

const LogoWithoutClick = ({ fromJobFlow }) => {
  const { useTimer, jobFlowStep } = useTools();
  return (
    <div
      style={{
        backgroundColor:
          useTimer === 0 && jobFlowStep == 3 ? "#DCE6ED" : "transparent",
      }}
    >
      <a>
      <GeekerLogo className={
          "logo-class max-width-1750-and-min-width-575-resize-logo " +
          (fromJobFlow ? "resizeLogo" : "")
        } logo={logo}
          CoBrandingWidth="280px"
        />
      </a>
    </div>
  );
};

export default LogoWithoutClick;
