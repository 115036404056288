import { decryptResponse, getUserIdFromCookie } from "utils";
import apiClient from "./index";

export async function retrieveCustomer(customerId) {
  const userId = getUserIdFromCookie()
  return apiClient.get(`/customers/${customerId}?cuId=${userId}`).then((response) => {
    if (response) {
      if (response?.data?.message == "Unauthorised") {
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
      // return response.data;
    }
    return Promise.reject();
  });
}

export async function retrieveCustomerByParams(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/retrieveCustomerByParams?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        // console.log('response>>>>>>>>>..',response)
        return response.data;
      }
      return Promise.reject();
    });
}

export async function updateCustomer(customerId, data) {
  const userId = getUserIdFromCookie()
  return apiClient.put(`/customers/${customerId}?cuId=${userId}`, data).then((response) => {
    if (response) {
      if (response?.data?.message == "Unauthorised") {
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
      // return response.data;
    }
    return Promise.reject();
  });
}


export async function createCustomerStripe(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/add-customer-to-stripe?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function addCardToCustomerStripe(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/add-card-to-customer?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function getStripeCustomerCardsInfo(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/get-stripe-customer-cards?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response;
      }
      return Promise.reject();
    });
}

export async function chargeCustomer(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/customers/charge-customer?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function retrieveCharge(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/customers/retrieve-charge?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function updateDefaultCard(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/update-default-card?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function removeCard(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/remove-customer-card?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function getCustomerSubscription(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/getCustomerSubscription?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function meetingEndEmails(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/meeting-closed-emails?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function checkIfOrganisationHasSubscription(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/check-organisation-subscription?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}


export async function takeChargeFromCustomer(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`customers/take-charge-from-customer?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Api to handle Discount from referal
 * @params : data(Type:Object)
 * @response : if have referal discount then update the referal table
 * @author : Manibha
 **/
export async function handleReferralDiscount(data) {
  const userId = getUserIdFromCookie()
  // console.log("handleReferralDiscount ::::::::: ", data);
  return apiClient
    .post(`customers/handle-referal-discount?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Api to handle pre authorization of $100 before any job.
 * @params : data(Type:Object)
 * @response : result(Type:Object)
 * @author : Kartik
 **/
export async function cardPreAuthorization(data) {
  const userId = getUserIdFromCookie()
  // console.log("data>>>>>>>>>>>", data);
  return apiClient
    .post(`/customers/card-pre-authorization?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Api to hold charge of $100 before any job.
 * @params : data(Type:Object)
 * @response : result(Type:Object)
 * @author : Jagroop, Nafees
 **/
let isRequesting = false;
export async function holdChargeFromCustomer(data) {
  const userId = getUserIdFromCookie()
  if (isRequesting) {
    console.log(
      "API request is already in progress. Will handle after completion."
    );
    isRequesting = false;
    return Promise.reject(
      "API request is already in progress. Will handle after completion."
    );
  }

  isRequesting = true;
  return apiClient
    .post(`/customers/hold-charge-from-customer?cuId=${userId}`, data)
    .then((response) => {
      isRequesting = false;
      return response.data;
    })
    .catch((error) => {
      console.error("API request failed:", error);
      isRequesting = false;
      return Promise.reject(error);
    });
}

/**
 * Api to deduct or refund holded money.
 * @params : data(Type:Object)
 * @response : result(Type:Object)
 * @author : Jagroop
 **/
export async function deductOrRefundHoldMoney(data) {
  const userId = getUserIdFromCookie()
  // console.log("deductOrRefundHoldMoney function API data:", data);
  return apiClient
    .post(`/customers/deduct-or-refund-holded-money?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Api to deduct $24.99 from holded amount.
 * @params : data(Type:Object)
 * @response : result(Type:Object)
 * @author : Nafees
 **/
export async function deductCancelJobPayment(data) {
  const userId = getUserIdFromCookie()
  // console.log("deductCancelJobPayment function API data:", data);
  return apiClient
    .post(`/customers/deduct-cancel-job-payment?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function saveCustomerVipVote(customerId, data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/customers/vip-vote/${customerId}?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}