// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/Proxima Nova Font.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ":root {\n  --novatheme: \"nova\";\n}\n\n@font-face {\n  font-family: \"nova\";\n  /*Can be any text*/\n  src:\n    local(\"ProximaNovaFont\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n\nhgroup {\n  margin: 0 0 5px 0;\n  line-height: 19.5px;\n}\n\n.four_h2_title__1wk6X {\n  font-size: 18px !important;\n  margin: 20px auto 0 auto !important;\n  max-width: 280px;\n}\n\n.four_paragraph_subtitle__3aAy1 {\n  font-size: 0.91rem !important;\n  color: #708390;\n  margin: 0 auto 20px auto;\n  max-width: 280px;\n}\n\ntextarea {\n  width: 90%;\n  padding: 20px;\n  height: 160px;\n  border: solid 2px #1fb9b0;\n  border-radius: 15px;\n  margin: 0 auto;\n  font-size: 1.1rem;\n}\n\n/* textarea:focus {\n  border: solid 2px #1FB9B0;\n  outline: solid 2px #1FB9B0;;\n} */\n\n.four_getHelpNowButton__1Ckhg {\n  display: flex;\n  height: 3.5rem;\n  padding: var(--3, 1.5rem) var(--4, 2rem);\n  justify-content: center;\n  align-items: center;\n  grid-gap: var(--1, 0.5rem);\n  gap: var(--1, 0.5rem);\n  align-self: stretch;\n  font-size: 17px !important;\n  font-weight: 600 !important;\n  font-family: var(--novatheme) !important;\n  border-radius: 10px !important;\n  background: var(--Dark-Turquoise, #13bcbd) !important;\n  border: none !important;\n}\n\n.four_getHelpNowButton__1Ckhg:focus {\n  background: #13bcbd !important;\n}\n\n.four_getHelpNowButton__1Ckhg:active {\n  background: #13bcbd !important;\n}\n\n@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 480px) {\n  .four_getHelpNowButton__1Ckhg {\n    width: 50%;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"h2_title": "four_h2_title__1wk6X",
	"paragraph_subtitle": "four_paragraph_subtitle__3aAy1",
	"getHelpNowButton": "four_getHelpNowButton__1Ckhg"
};
module.exports = exports;
