import React, { useState } from 'react';
import * as JobFileApi from "../../../api/jobFile.api";
import { openNotificationWithIcon } from "../../../utils";
import { Pagination, Spin } from 'antd'
import { meetingSocket } from '../../../context/authContext'
import mixpanel from 'mixpanel-browser';

const FileTable = ({ user, job, files = [], setFiles, socket }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 7; // Number of items per page
    const [isDeleting, setIsDeleting] = useState(null);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    let filesToShow = files.slice(startIndex, endIndex);
    if (filesToShow.length == 0) {
        filesToShow = files
    }
    filesToShow.sort((a, b) => new Date(b.updated) - new Date(a.updated));
    const handleDelete = async (fileNameToDelete) => {

        try {
            setIsDeleting(fileNameToDelete);
            let response = await JobFileApi.deleteFile({
                fileName: fileNameToDelete,
                userType: user.userType,
            });

            if (response?.status) {
                openNotificationWithIcon('success', 'Success', response?.message);
                mixpanel.identify(user.email);
                mixpanel.track(
                    "File delete successfully",
                    {
                        JobId: job.id,
                        userId: user.id,
                        userType: user.userType
                    }
                );
                setIsDeleting(null);
                const updatedFiles = files.filter(file => file.name !== fileNameToDelete);
                setFiles(updatedFiles);

                meetingSocket.emit('upload-file-from-frontend', { jobId: job.id,job:job, userType: user.userType,deleteFile:true })
                const totalPages = Math.ceil(updatedFiles.length / pageSize);
                if (currentPage > totalPages) {
                    setCurrentPage(totalPages); // Set to last non-empty page
                }

            } else {
                openNotificationWithIcon('error', 'Error', response?.message);
            }
        } catch (error) {
            console.error("Error deleting file:", error);
            openNotificationWithIcon('error', 'Error', `Failed to delete file ${fileNameToDelete}.`);
        }
    }
    const handleDownload = async (fileName) => {

        try {
            let response = await JobFileApi.downloadFile({
                fileName: fileName,

            });
            if(response?.status ) {
            const link = document.createElement('a');
            link.href = response.url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
                openNotificationWithIcon('success', 'Success', response?.message);
                mixpanel.identify(user.email);
                mixpanel.track(
                    "File download successfully",
                    {
                        JobId: job.id,
                        userId: user.id,
                        userType: user.userType
                    }
                );
            } else {
                openNotificationWithIcon('error', 'Error', `Failed to download file`,response?.message);
            }
        } catch (error) {
            console.error("Error download file::", error);
            openNotificationWithIcon('error', 'Error', `Failed to download file ${fileName}.`);
        }
    }
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    return (
        <div >
            {filesToShow.length === 0 ? (
                <p style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>No files available</p>
            ) : (
                <div>
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th>File Name</th>
                                <th style={{ textAlign: 'center' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filesToShow.map((file, index) => (
                                <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                                    <td className='uploadFileName'>{file.jobFileData[0].fileName}</td>
                                    <td>
                                        <div className='container-upload-button'>
                    
                                            {!(file.jobFileData[0].uploadedBy === 'customer' && user.userType === 'technician') && !(file.jobFileData[0].uploadedBy === 'technician' && user.userType === 'customer') && !(job.is_transferred && job.transfer_reference_job === file.jobFileData[0].job) && (
                                                <button className="deleteFile-button" onClick={() => handleDelete(file.name)} disabled={isDeleting}>
                                                    {isDeleting === file.name ? (
                                                        <Spin size="small" />
                                                    ) : (
                                                        <span>Delete</span>
                                                    )}</button>
                                            )}

                                            {user.userType === 'customer' && job.is_transferred && job.transfer_reference_job === file.jobFileData[0].job && (
                                                <button className="deleteFile-button" onClick={() => handleDelete(file.name)} disabled={isDeleting}> {isDeleting === file.name ? (
                                                    <Spin size="small" />
                                                ) : (
                                                    <span>Delete</span>
                                                )}</button>
                                            )}
                                            <button className="deleteFile-button" style={{ backgroundColor: '#4EE1E2' }} onClick={() => handleDownload(file.name)}>Download</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ display: "flex" }}>
                        {files.length > pageSize && (
                            <Pagination
                                current={currentPage}
                                total={files.length}
                                pageSize={pageSize}
                                onChange={onPageChange}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileTable;
