import {
  NO_PREVUOUS_JOB_CUSTOMER,
  NO_PREVUOUS_JOB_TECH,
} from "constants/expandableJobContants";
import { Col, Button } from "react-bootstrap";
import Box from "../../common/Box";
import React from "react";
import { checkPendingStatus } from "utils";
import mixpanel from "mixpanel-browser";
import { klaviyoTrack } from "api/typeService.api";
import { isMobile, isTablet } from "react-device-detect";

const EmptyDashboard = ({ userType,user }) => {

  /**
	 * Function will check if there are any pending jobs of the customer.if pending then open modal and run push_to_profile_setup function
	 * @author : Nafees
	 */
  const push_to_profile_setup = async (e) => {
    console.log('>>>>>>>>>>>>push_to_profile_setup ::::::: ');
    if (user) {
      mixpanel.identify(user?.email);
      mixpanel.track('Customer - Post a job');
      mixpanel.people.set({
        $first_name:user?.firstName,
        $last_name:user?.lastName,
      });
    }
    if (window.localStorage.getItem('extraMin')) {
      window.localStorage.removeItem('extraMin');
    }
    if (window.localStorage.getItem('secs')) {
      window.localStorage.removeItem('secs');
    }
    
    if (user.customer?.customerType === 'live') {
      let partnerName = '';
      let partnerStatus = false;
      if (user && user?.partner) {
        partnerName = user?.partner;
        partnerStatus = true;
      }
      const klaviyoData = {
        email:user?.email,
        event: 'Job Post Button Click',
        properties: {
          $first_name:user?.firstName,
          $last_name:user?.lastName,
          $partnerName: partnerName,
          $partnerStatus: partnerStatus,
        },
      };
      await klaviyoTrack(klaviyoData);
    };
    if (isMobile || isTablet) {
      window.location.href = "/post-job";
    }else{
      window.location.href = '/customer/profile-setup?page=select-software';
    }
  };
  
  return (
    <div>
      {userType == "customer" ? (
        <Col span={24} align="middle">
          <Box>
            <div className="divarea">
              <p>{NO_PREVUOUS_JOB_CUSTOMER}</p>
              <Button
                key={"btn-post-job"}
                disabled={false}
                onClick={push_to_profile_setup}
                className="btn app-btn"
              >
                <span />
                Get Help Now
              </Button>
            </div>
          </Box>
        </Col>
      ) : (
        <Col span={24} align="middle">
          <Box>
            <div className="divarea">
              <p>{NO_PREVUOUS_JOB_TECH}</p>
            </div>
          </Box>
        </Col>
      )}
    </div>
  );
};

export default EmptyDashboard;
