// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button_with_icon_h1__1X7Zx {\n  font-size: 1.0625rem !important;\n  margin: 0 !important;\n  /* padding: 15px 0 15px 10px; */\n}\n", ""]);
// Exports
exports.locals = {
	"h1": "Button_with_icon_h1__1X7Zx"
};
module.exports = exports;
