import React, { useState } from "react";
import { Input, Spin, Modal } from "antd";
import { Row, Col, Button } from "react-bootstrap";
import { updateJob, cancelScheduleJob } from "../../../api/job.api";
import mixpanel from "mixpanel-browser";
import {
  openNotificationWithIcon,
  checkCustomerHaveSubscriptionMinutes,
} from "../../../utils";
import { useSocket } from "../../../context/socketContext";
import * as CustomerApi from "../../../api/customers.api";
import * as UserApi from "../../../api/users.api";
import * as TwilioApi from "../../../api/twilioChat.api";
import * as JobCycleApi from "../../../api/jobCycle.api";
import { JobTags } from "../../../constants/index.js";
const JobCancelFrom = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [reason, setReason] = useState("");
  const [isEmptyReason, setIsEmptyReason] = useState(false);
  const { socket } = useSocket();

  /**
   * cancel job by tech and customer.
   * @params : we are using use state variables in this function
   * @returns: reload page after timeout
   * @author : Ridhima Dhir
   */
  const handleCancel = async (event) => {
    if (reason) {
      setIsDisabled(true);
      // Block native form submission.
      event.preventDefault();
      // console.log(
      //   " cancelJobId:::: ",
      //   props.cancelJobId,
      //   " ::: reason :::",
      //   reason
      // );
      // check type is customer then emit socket scheduleCancelledByCustomer
      // updatejob status and schedule_accepted to false
      //console.log("handleCancel",props,reason)
      if (props.type == "Customer") {
        mixpanel.identify(props.user.email);
        mixpanel.track(
          "Customer -  Cancelled the schedule job from dashboard",
          { JobId: props.cancelJobId }
        );
        if (!props?.job?.schedule_accepted) {
          await JobCycleApi.create(
            JobTags.CUSTOMER_DECLINED_SCHEDULE_JOB,
            props.cancelJobId,
            props.user.id
          );
        }
        // This will refund the hold money from customer account

        let ownerStripeId = "";
        const ownerId = props?.job?.customer?.user?.ownerId;
        if (ownerId) {
          const ownerStripeRes = await UserApi.getUserById(ownerId);
          if (ownerStripeRes?.customer?.stripe_id) {
            ownerStripeId = ownerStripeRes?.customer?.stripe_id;
          }
        }
        const stripeId = ownerId
          ? ownerStripeId
          : props?.job?.customer?.stripe_id;
        // Here payment_hold_id is not-applicable-here because we want to send only when we are Adding card as in that case of card no payment_hold_id is stored somewhere  but in normal cases like this all the data is stored in the form of object and may have more than one stripe id
        const obj = {
          payment_hold_id: "not-applicable-here",
          isDeduct: false,
          jobId: props.job?.id,
          stripe_id: stripeId,
        };
        const obj2 = {
          jobId: props.job?.id,
          stripe_id: stripeId,
          isScheduleCancel: true,
        };
        let scheduleCancelResponse;
        if (props?.job?.schedule_accepted) {
          await JobCycleApi.create(
            JobTags.CUSTOMER_CANCEL_ACCEPTED_SCHEDULE_JOB,
            props.job?.id
          );
          scheduleCancelResponse =
            await CustomerApi.deductCancelJobPayment(obj2);
          if (
            props?.job &&
            props?.job?.customer_holded_payments &&
            props?.job?.customer_holded_payments.length > 0
          ) {
            await CustomerApi.deductOrRefundHoldMoney(obj);
          }

          if (scheduleCancelResponse?.status === "Successful") {
            await JobCycleApi.create(
              JobTags.PAYMENT_DEDUCTED_FROM_CUSTOMER,
              props.job?.id
            );
            openNotificationWithIcon(
              "success",
              "Success",
              "Job has been cancelled."
            );
          } else {
            openNotificationWithIcon(
              "error",
              "Error",
              scheduleCancelResponse.message
            );
          }
        } else {
          await CustomerApi.deductOrRefundHoldMoney(obj);
          openNotificationWithIcon(
            "success",
            "Success",
            "Job has been cancelled."
          );
        }
        console.log("deductCancelJobPayment", scheduleCancelResponse);

        // await CustomerApi.deductOrRefundHoldMoney(obj);
        await socket.emit("scheduleCancelledByCustomer", {
          jobId: props.cancelJobId,
          reason: reason,
        });
        await updateJob(props.cancelJobId, {
          status: "Declined",
          schedule_accepted: false,
          tech_message_dashbord: false,
        });
        await TwilioApi.updateTwilioConversation(
          props.job?.twilio_chat_service?.sid
        );
        socket.emit("refresh-job-after-decline-by-user", props.job);
        await cancelScheduleJob(props.cancelJobId, {
          calcellationBy: props.type,
          reason: reason,
          user: props.user,
        });

        //Modal close
        props.setIsCancelModal(false);

        //Reload current location
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }

      // check type is Technician then call function decline_job_by_technician
      if (props.type == "Technician") {
        mixpanel.identify(props.user.email);
        mixpanel.track("Technician - Job declined from dashboard", {
          JobId: props.cancelJobId,
        });
        await props.decline_job_by_technician(props.cancelJobId, true, reason);
        await JobCycleApi.create(
          JobTags.TECH_CANCEL_SCHEDULE_JOB,
          props.cancelJobId,
          props.user.id
        );

        // Update the custCancellation or techCancellation object of job as per cancellation type (customer or tech)
        //	custCancellation object update if type is Customer and push object in techCancellation field if type is tech
        // and update job
        await cancelScheduleJob(props.cancelJobId, {
          calcellationBy: props.type,
          reason: reason,
          user: props.user,
        });

        //Modal close
        props.setIsCancelModal(false);

        //Reload current location
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    } else {
      // console.log("Cancelling errrored");
      // show error message if reason is empty
      event.preventDefault();
      setIsEmptyReason(true);
    }
  };

  const changeReason = (e) => {
    setReason(e.target.value);
  };

  return (
    <Modal
      title="Cancel Schedule Job"
      visible={props.isCancelModal}
      onOk={() => {}}
      onCancel={() => {
        props.setIsCancelModal(false);
      }}
      closable={true}
      className="customCloseButton"
      footer={[
        <Button
          key="back"
          onClick={() => {
            props.setIsCancelModal(false);
          }}
          className="btn app-btn app-btn-light-blue modal-footer-btn"
        >
          <span></span>No
        </Button>,
        <Button
          key="cancel"
          className={
            "btn app-btn modal-footer-btn " + (isDisabled ? "disabled-btn" : "")
          }
          disabled={isDisabled}
          onClick={handleCancel}
        >
          <span></span>
          {isDisabled ? <Spin /> : <>Cancel Job</>}
        </Button>,
      ]}
    >
      <Col md="12" className="pb-4 m-auto add-card-form-outer text-left">
        {props.user.userType === "customer" ? (
          (props.user?.roles[0] !== "owner" && props.user?.roles[0] !== "admin") ?
          <span>
            A tech has already accepted your job, so your company may incur a small fee for no-shows or last-minute cancellations. Are you sure you want to cancel your schedule job?
          </span>
          :
          <span>
            A tech has already accepted your job, so you will be charged $24.99
            if you cancel this job. Are you sure you want to cancel your
            schedule job?
          </span>
        ) : null}
        <form>
          <h6>
            <span className="red-text">*</span>Please provide reason for
            cancellation.
          </h6>
          <Row>
            <Col md="12" className="card-element-outer mt-2 mb-4">
              <Col xs="12" className="card-element-inner pb-3 iframe-outer">
                <Input.TextArea
                  rows={3}
                  placeholder="Please tell us reason for cancellation"
                  onChange={changeReason}
                  className={isEmptyReason ? "red-border" : ""}
                  required
                />
              </Col>
            </Col>
          </Row>
        </form>
      </Col>
    </Modal>
  );
};

export default JobCancelFrom;

