import React, { useEffect, useState } from "react";
import Heading from "../components/Heading";
import SubHeading from "../components/SubHeading";
import * as JobApi from "../../../../../api/job.api";
import * as UserApi from "../../../../../api/users.api";
import Loader from "../../../../../components/Loader";
import { isMobile } from "react-device-detect";
import { JOB_STATUS } from "../../../../../constants";
import { ReactSVG } from "react-svg";
import Globe from "../../../../../assets/images/globe.svg";
import { scheduleJobTimeToLocalTimeZone } from "../../../../../utils";

const ScheduleJobSummary = ({ jobIdFromUrl }) => {
  const [userInfo, setUserInfo] = useState();
  const [jobInfo, setJobInfo] = useState();
  const [scheduleDate, setScheduleDate] = useState();
  const [scheduleTime, setScheduleTime] = useState();
  const [localScheduleJobTime, setLocalScheduleJobTime] = useState("")

  useEffect(() => {
    (async () => {
      if (jobIdFromUrl) {
        console.log(`Job ScheduleJobSummary ${jobIdFromUrl}`);

        const jobData = await JobApi.retrieveJob(jobIdFromUrl);
        if (jobData.status !== JOB_STATUS.SCHEDULED) {
          window.location.href = "/";
        }
       
        if (jobData) {
          const scheduleJobTimeInLocalTimeZone = scheduleJobTimeToLocalTimeZone(new Date(jobData.primarySchedule), jobData.customer.timezone)
          const timeInLocalTimezone = scheduleJobTimeInLocalTimeZone.scheduleJonTimeToDisplay.split(":")
          let hoursInlocalTimezone = timeInLocalTimezone[0]
          let minutesInlocalTimezone = String(new Date(scheduleJobTimeInLocalTimeZone.scheduleJobTimeInLocalTimeZone).getMinutes())
          if(minutesInlocalTimezone.length === 1){
            minutesInlocalTimezone = "0" + minutesInlocalTimezone
          }
          let dayduartion = "AM"
          if(hoursInlocalTimezone > 12) {
            hoursInlocalTimezone -= 12
            dayduartion = "PM"
            if(String(hoursInlocalTimezone).length === 1 ){
              hoursInlocalTimezone = "0" + hoursInlocalTimezone
            }
          }
         
          setLocalScheduleJobTime(String(hoursInlocalTimezone)+ ":" +minutesInlocalTimezone+ " " + dayduartion)
          // setLocalScheduleJobTime(scheduleJobTimeInLocalTimeZone.scheduleJonTimeToDisplay)
          setJobInfo(jobData);
          if (jobData.customer.user.id) {
            const userData = await UserApi.getUserById(
              jobData.customer.user.id
            );
            if (userData) {
              // console.log("looking for userData", { userData });
              setUserInfo(userData);
            }
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    // console.log(
    //   "userInfo job info :: :: :: :: ",
    //   new Date(jobInfo?.primarySchedule)
    // );
    if (jobInfo?.primarySchedule) {
      const inputDate = new Date(jobInfo?.primarySchedule);
      const options = {
        day: "2-digit",
        month: "short",
        weekday: "short",
      };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        inputDate
      );
      // console.log("formatted date ::::", formattedDate);
      if (formattedDate) {
        setScheduleDate(formattedDate.split(" "));
      }

      const inputTime = new Date(jobInfo?.primarySchedule);
      const optionsForTime = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      const formattedTime = new Intl.DateTimeFormat(
        "en-US",
        optionsForTime
      ).format(inputTime);
      // console.log("formated date :::: 2", formattedTime);
      if (formattedTime) {
        setScheduleTime(formattedTime.split(" "));
      }
    }
  }, [jobInfo]);

  // useEffect(() => {
  //   // if (scheduleDate)
  //   //    console.log('my fav time 1',scheduleTime)
  //   //   console.log("my fav time 2", scheduleDate)
  // }, [scheduleDate]);

  // const addMobileVarToLocalstorage = () => {
  //   if (isMobile) {
  //     localStorage.setItem("mobile", "true");
  //   }
  // };

  // Redirect the user to the profile setup page when the "Get Help Now" button is clicked.
  const redirectToProfileSetup = () => {
    try {
      window.location.href = "/post-job";
    } catch (error) {
      console.error("An error occurred while redirecting:", error);
      // You can choose to handle the error in a specific way, e.g., showing an error message to the user.
    }
  };

  return (
    <div className="headingDiv pl-20-max-width-280 pr-20-max-width-280">
      <div className="d-flex flex-column heading-div">
        <Heading text={" Hooray! Your call is requested!"} />
        <SubHeading text={"You'll be notified if/when a Geek accepts."} />
      </div>
      {scheduleDate && scheduleTime ? (
        <div className="w-100percent sch-job-summary-div">
          <SubHeading text={"Your requested time"} />
          <div className="sch-job-time-div">
            {scheduleDate && (
              <span className="sch-job-date font-nova">{`${scheduleDate[0]} ${scheduleDate[2]} ${scheduleDate[1]}`}</span>
            )}

            {scheduleTime && (
              <div className="d-flex align-items-baseline">
                <span className="sch-job-time font-nova">
                  {scheduleTime[0]}
                </span>
                <span className="sch-job-time-am-pm font-nova">
                  {scheduleTime[1].toLowerCase()}
                </span>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center gap-8">
            <ReactSVG src={Globe} />
            {/* <span className="localTime" >{`${jobInfo.customer.user.timezone} Time Zone (${localScheduleJobTime})` }</span> */}
            <div className="d-flex flex-column">
              {/* <span className="localTime text-left" >Geeker Business Hours</span> */}
              <span className="localTime" >{`Eastern Standard Time (EST): ${localScheduleJobTime}`}</span>
            </div>
          </div>
          <div className="get-help-now-a-div">
            <span className="get-help-now-text font-nova`">
              Want help right away?
            </span>
            <span
              onClick={redirectToProfileSetup}
              className="get-help-now-a font-nova"
            >
              Post a job
            </span>
          </div>
        </div>
      ) : (
        <Loader height="200px" />
      )}
    </div>
  );
};

export default ScheduleJobSummary;
