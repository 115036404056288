import { Upload, message, notification, Space } from "antd";
// import profileImg from "../../../../../assets/users/user.png";
import PlaceHolderImageMale from "../../../../../assets/users/technicianProfileImageMale.png";
import PlaceHolderImageFemale from "../../../../../assets/users/technicianProfileImageFemale.png";
// import { InboxOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
// import { FaUserCircle } from "react-icons/fa";
// import { Button } from "react-bootstrap";
import { useUser } from "../../../../../context/useContext";
import { SECRET_KEY, SERVER_URL } from "../../../../../constants";
// import * as TechnicianService from "../../../../../api/technician.api";
// import { useSocket } from "../../../../../context/socketContext";
import { useAuth } from "../../../../../context/authContext";
import { useTools } from "context/toolContext";
import * as UploadApi from "../../../../../api/upload.api";
import BasicButton from "components/common/Button/BasicButton";
import { videoNameExtractor } from "utils";
let r = (Math.random() + 1).toString(36).substring(7);
const UploadAvatar = ({
  setTechProfile,
  techProfile,
  setProfilePicUpdated,
}) => {
  const { user, setUser } = useUser();
  const { refetch } = useAuth();
  // const { socket } = useSocket();
  const { id = "" } = user?.technician;
  const [showEditor, setShowEditor] = useState(false);
  const [geekImage, setGeekImage] = useState();
  const [profileImage, setProfileImage] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [status, setStatus] = useState();
  const { imageupload, setImageupload, imageChange, setImageChange } = useTools();
  const defaultImage = user?.gender === "female" ? PlaceHolderImageFemale : PlaceHolderImageMale;

  const openNotificationWithIcon = (nType, header, nMessage) => {
    notification[nType]({
      message: header,
      description: nMessage,
    });
  };

  useEffect(() => {
    const temptechProfile = { ...techProfile };
    temptechProfile.profileImage.complete = true;
    setTechProfile(temptechProfile);
  }, []);

  useEffect(() => {
    if (user && user?.technician?.profile?.image)
      setGeekImage(
        user && user.technician.profile.image.length > 0
          ? user.technician.profile.image
          : ""
      );
  }, [user]);
  const fileTypes = ".png, .jpg, .jpeg";
  const { Dragger } = Upload;
  // let fileNameImage = `${user.id}_userProfile_${r}`;
  let profileId = "";
  if (geekImage) {
    profileId = videoNameExtractor(geekImage);
  }
  const props = {
    name: "file",
    multiple: false,
    fileList: fileList,
    accept: fileTypes,
    action: `${SERVER_URL}/api/uploads/profilePicture`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(SECRET_KEY)}`,
    },
    data: { user: `${user?.id}`, profileId: `${profileId}` },
    maxCount: 1,
    beforeUpload: (file) => {
      if (
        !(
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/jpeg"
        )
      ) {
        openNotificationWithIcon("error", "Warning", "File Type Not Supported");
        return false;
      }

      if (file.size / 1048576 > 10) {
        openNotificationWithIcon(
          "error",
          "Warning",
          "file should be smaller than 10mb"
        );
        return false;
      }
    },
    async onChange(info) {
      const { status, response } = info.file;
      console.log("info.file ::::::", info.file);
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "uploading") {
        setFileList([info.file]);
      }
      if (status === "done" && response["publicUrl"]) {
        setStatus(status);
        setTechProfile((prev) => ({
          ...prev,
          profileImage: {
            ...prev.profileImage,
            complete: true,
          },
        }));
        openNotificationWithIcon(
          "success",
          "Success",
          `${info.file.name} file uploaded successfully.`
        );

        // let finalImage = `${fileNameImage}-.${info.file.type.split("/").pop()}`;
        // console.log(">>>>>>>>>>>>>>file name :::", finalImage);
        // let techUpdate = await TechnicianService.updateTechnicianWithParams(
        //   user.technician.id,
        //   { "profile.image": `${SERVER_URL}/images/${finalImage}` }
        // );
        setImageupload(true);
        setImageChange(response["publicUrl"]);
        setFileList([info.file]);
        setGeekImage(response["publicUrl"]);
        setShowEditor(false);
        setProfileImage(true);
      } else if (status === "error") {
        setFileList([]);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove() {
      const profileUrl = geekImage;
      if (profileUrl) {
        const profileId = videoNameExtractor(profileUrl);
        await UploadApi.removeProfilePic({ techId: id, profileId: profileId });
        openNotificationWithIcon(
          "success",
          "Success",
          "Image deleted succesfully"
        );
        setFileList([]);
        setImageChange("");
        setImageupload(false);
        setGeekImage("");
        refetch("callPage23");
        r = (Math.random() + 1).toString(36).substring(7);
      }
    },
  };
  const deleteImage = async () => {
    try {
      const profileUrl = geekImage;
      if (profileUrl) {
        const profileId = videoNameExtractor(profileUrl);
        await UploadApi.removeProfilePic({ techId: id, profileId: profileId });
        openNotificationWithIcon(
          "success",
          "Success",
          "Image deleted succesfully"
        );
        setFileList([]);
        setImageChange("");
        setImageupload(false);
        setGeekImage("");
        refetch("callPage23");
      } else {
        setGeekImage("");
      }
    } catch (error) {
      openNotificationWithIcon("err", "err", "cant delete");
    }
  };
  return (
    <div className="col-12 mx-auto dragger-container">
      <>
        <Dragger {...props}>
          {geekImage ? (
            <img src={geekImage} className="geekImageSetting"></img>
          ) : (
            <img src={defaultImage} className="geekImageSetting"></img>
          )}
          <p className="ant-upload-text">
            Click this area to upload your Image
          </p>
          <style>{`
    
  `}</style>
        </Dragger>

        {geekImage && (
          <Space wrap>
            <BasicButton
              onClick={deleteImage}
              btnTitle={" Delete image"}
              height={"50px"}
              width={"166px"}
              background={"#01D4D5"}
              color={"#FFFFFF"}
              style={{marginTop:'15px'}}
              
            />
          </Space>
        )}
      </>
    </div>
  );
};

export default UploadAvatar;
