import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactPlayer from "react-player/lazy";
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

export default function ViewRecordings({
    videoUrl, setOpen, open
}) {
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: 'flex', justifyContent: 'end' }} >
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <ReactPlayer
                            url={videoUrl}
                            className="react-player"
                            controls
                        />
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
