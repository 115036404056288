import React, { lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectSubcategory } from "../../../../../stores/devices/mobile/Job/Slice";
import commonStyles from "../../../../css.module/common.module.css";
import styles from "../../../../css.module/two.module.css";
import { IT_CATEGORY, SOFTWARE_CATEGORY } from "../../../../../constants";
import { useUser } from "../../../../../context/useContext";
import { useLocation } from "react-router";
import * as JobService from "../../../../../api/job.api";

const ButtonWithIcon = lazy(
  () => import("../../../components/Buttons/ButtonWithIcon")
);
// const SeeMoreLink = lazy(() => import('../../../../Components/styled.components/link.styled').then());

export default function StepTwo(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const technicianUserId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const softwareIdFromUrl = queryParams.get("softwareId")
    ? queryParams.get("softwareId")
    : false;
  const repost = queryParams.get("repost") ? queryParams.get("repost") : false;
  const dispatch = useDispatch();
  const history = useHistory();
  const JobSliceState = useSelector((state) => state.JobSlice);
  // const [maxList, setMaxList] = useState(3);
  const { user } = useUser();
  const [selectedSoftwareId, setSelectedSoftwareId] = useState();

  useEffect(() => {
    (async () => {
      if (jobIdFromUrl) {
        const jobData = await JobService.retrieveJob(jobIdFromUrl);
        if (jobData) {
          console.log("Software id from previous job :: ", jobData.software.id);
          setSelectedSoftwareId(jobData.software.id);
        }
      }
    })();
  }, []);

  const handleProblem = async (indexOfList) => {
    await dispatch(selectSubcategory(indexOfList));
    if (user) {
      //Handling posting a job using technician's unique url while logged in.
      if (softwareIdFromUrl && technicianUserId) {
        history.push(
          `/select-area?softwareId=${softwareIdFromUrl}&technicianId=${technicianUserId}`
        );
        return;
      }
      //Handling Post again with same tech.
      if (jobIdFromUrl && technicianUserId) {
        history.push(
          `/select-area?jobId=${jobIdFromUrl}&repost=true&technicianId=${technicianUserId}`
        );
        return;
      }
      //Handling Post again.
      if (jobIdFromUrl && repost) {
        history.push(`/select-area?jobId=${jobIdFromUrl}&repost=true`);
        return;
      }
      //Handling Posting a draft job.
      if (jobIdFromUrl) {
        history.push(`/select-area?jobId=${jobIdFromUrl}`);
        return;
      }
      if (technicianUserId) {
        history.push(`/select-area?technicianId=${technicianUserId}`);
      } else {
        history.push(`/select-area`);
      }
    } else {
      //Handling posting a job using technician's unique url while logged out.
      if (softwareIdFromUrl && technicianUserId) {
        history.push(
          `/select-problem?softwareId=${softwareIdFromUrl}&technicianId=${technicianUserId}`
        );
        return;
      }
      if (technicianUserId) {
        history.push(`/select-problem?technicianId=${technicianUserId}`);
      } else {
        history.push(`/select-problem`);
      }
    }
  };

  return (
    <>
      <div className={commonStyles.box}>
        <div className={commonStyles.title_group}>
          <span className={commonStyles.h2_title}>
            Let's get your problem solved!
          </span>
          {JobSliceState.SelectedCategory === SOFTWARE_CATEGORY && (
            <span className={styles.paragraph_subtitle}>
              Select your software's
            </span>
          )}

          {JobSliceState.SelectedCategory === IT_CATEGORY && (
            <span className={styles.paragraph_subtitle}>
              Select Your IT Issue
            </span>
          )}
        </div>

        <div className={commonStyles.boxes}>
          <div className={commonStyles.frame_category}>
            {JobSliceState.Subcategories.map((el, indexOfList) =>
               (
                <React.Fragment key={indexOfList}>
                  <ButtonWithIcon
                    className={
                      el.id === selectedSoftwareId ||
                      el.id === softwareIdFromUrl
                        ? commonStyles.selected_software_border +
                          " " +
                          commonStyles.small_category_button
                        : commonStyles.small_category_button
                    }
                    key={indexOfList}
                    onClick={() => handleProblem(indexOfList)}
                    id={el.id}
                    title={el.name}
                    icon={
                      <img
                        style={{ width: 20, height: 20 }}
                        src={el.blob_image}
                        alt=""
                      />
                    }
                  />
                </React.Fragment>
              ) 
            )}
            {/* {maxList >= JobSliceState.Subcategories.length ? null : (
              <div className={commonStyles.border_bottom}>
                <span
                  onClick={() => setMaxList(JobSliceState.Subcategories.length)}
                  className={commonStyles.link}
                >
                  <span className={commonStyles.link_frame}>See More</span>
                </span>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}
