// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header_Mobile_logo_image__1_AYh {\n  width: 182px;\n}\n", ""]);
// Exports
exports.locals = {
	"logo_image": "Header_Mobile_logo_image__1_AYh"
};
module.exports = exports;
