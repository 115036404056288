import apiClient from "./index";
// import { SESSION_EXPIRE_URL } from '../constants';
import { SECRET_KEY } from "../constants";
import { decryptResponse, getUserIdFromCookie } from "utils";

export async function updateUser(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/users/${data.userId}?cuId=${userId}`, data).then((response) => {
    if (response) {
      if(response?.data?.message == "Unauthorised"){
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
    }
    return Promise.reject();
  });
}
export async function updateUserBusinessDetails(data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .post(`/users/updateUserBusinessDetails/${data.userId}?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}


export async function getUserByParam(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/users/get_user_by_param?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
/**
 * Following API will delete invite user
 * @params : data (Type: Array), token (Type: String)
 * @return : data (Type: Array)
 * @author : Nafees
 **/
export async function deleteInvitesUserByParam(data) {
  const userId = getUserIdFromCookie()
  // console.log("deleteInvitesUserByParam", data);
  return apiClient
    .post(`/users/deleteInvitesUserByParam?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * Following API will fetch user as guest
 * @params : data (Type: Object), token (Type: String)
 * @return : data (Type: Object)
 * @author : Vinit
 **/

export async function getUserByParamAsGuest(data, token) {
  localStorage.setItem(SECRET_KEY, token);
  return apiClient.post(`/public/verify-user`, data).then((response) => {
    localStorage.removeItem(SECRET_KEY);
    // console.log("tetch token removed from user.api.js");
    if (response) {
      if(response?.data?.succes == false || response?.data?.status == false){
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
    }
    return Promise.reject();
  });
}

export async function deleteUserByParam(data) {
  const userId = getUserIdFromCookie()
  // console.log("deleteUserByParam>>>>>>. ", data);
  return apiClient.post(`/users/deleteUserByParam?cuId=${userId}`, data).then((response) => {
    if (response) {
      // console.log("deleteUserByParam >>>>>>>>>>>> ", response);
      return response.data;
    }
  });
}
export async function getUserById(id) {
  const userId = getUserIdFromCookie()
  return apiClient.get(`/users/retrieve/${id}?cuId=${userId}`).then((response) => {
    if (response) {
      if(response?.data?.message == "Unauthorised"){
        return response.data
      }
      const decryptedData = decryptResponse(response.data)
      return decryptedData;
    }
    return Promise.reject();
  });
}

export async function getTotalUserOfOrg(ownerId) {
  const userId = getUserIdFromCookie()
  return apiClient.get(`/users/getOrgUsers/${ownerId}?cuId=${userId}`).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function updateUserVolumeSettings(id, newVolume, muteStatus) {
  const userId = getUserIdFromCookie();
  try {
    console.log('updateUserVolumeSettings api', id, newVolume,muteStatus)
    const response = await apiClient.post(`/users/updateUserVolumeSettings/${id}?cuId=${userId}`, {
      volume: newVolume,
      isMuted: muteStatus,
    });
    console.log('updateUserVolumeSettings',response)
    if (response?.data?.status) {
        return response.data;
      }
    return Promise.reject();
  } catch (error) {
    console.error('Error updating volume settings:', error);
    return Promise.reject(error);
  }
}

