import React from "react";
import { Select } from "antd";

const DropDown = (props) => {
  return (
    <>
      <div className={props.inputDivClass}>
        <Select
          className={props.inputClass}
          name={props.name}
          onChange={props.onChange}
          defaultValue={props.defaultValue}
        >
          {props.dropDownOptions.map((ele, index) => {
            return (
              <Select.Option value={ele.value} key={index} className={props.inputClass}>
                {<>{ele.label}</>}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </>
  );
};

export default DropDown;