import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal } from "antd";
import styled from "styled-components";
import FormItem from "../../../../components/FormItem";
import { Button } from "react-bootstrap";
import Link from "components/AuthLayout/Link";
import { privacyPolicy } from "../../../../policy-pages/privacy-policy";
import { cookiePolicy } from "../../../../policy-pages/cookie-policy";
import { TermsCondition } from "../../../../policy-pages/conditions";
import { useAuth } from "../../../../context/authContext";
import PhoneInput from "react-phone-input-2";
import TextInput from "components/AuthComponents/TextInput";
import PasswordInput from "components/AuthComponents/PasswordInput";
import PasswordValidator from "components/AuthComponents/PasswordValidator";
import ValidatorModel from "components/AuthComponents/ValidatorModel";
import HeadingAndSubHeading from "components/HeadingAndSubHeading";
import * as AuthApi from "../../../../api/auth.api";
import { openNotificationWithIcon } from "../../../../utils";
import { Spin, Checkbox as AntCheckbox } from "antd";
import ValidatorModelForEmail from "../../../../components/AuthComponents/ValidateModelForEMail";
import mixpanel from "mixpanel-browser";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useSocket } from "../../../../context/socketContext";
import { emailRegExp, genders } from "../../../../constants/index";
import BasicDropDown from "components/common/BasicDropDown";

function TechRegister({
  onNext,
  setShowProgress,
  setUserInfo,
  userInfo,
  setProgressBarPercentage,
  setRegister,
}) {
  const registerRes = null;
  const { setUserToken } = useAuth();
  const storedUser = JSON.parse(sessionStorage.getItem("SSOSignupUser"));

  const [alertMessageEmail, setAlertMessageEmail] = useState("");
  const [alertRefMessageEmail, setAlertRefMessageEmail] = useState("");
  const [alertMessageFName, setAlertMessageFName] = useState("");
  const [alertMessageLName, setAlertMessageLName] = useState("");
  const [alertMessagePassword, setAlertMessagePassword] = useState("");
  const [alertMessagePhoneNumber, setAlertMessagePhoneNumber] = useState("");
  const [alertMessageGender, setAlertMessageGender] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPrivacyPolicyModalAvailable, setIsPrivacyPolicyModalAvaliable] =
    useState(false);
  const [isCookiesPolicyModalAvailable, setIsCookiesPolicyModalAvailable] =
    useState(false);
  const [pwModelVisible, setPwModelVisible] = useState(false);
  const [emModelVisible, setEmModelVisible] = useState(false);
  const [refModelVisible, setRefModelVisible] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [showRefer, setShowRefer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sendDataToGTM = useGTMDispatch();
  const { socket } = useSocket();
  const [redValidations, setRedValidations] = useState({
    isFirstNameValid: false,
    isLastNameValid: false,
    isPasswordValid: false,
    isPhoneNumber: false,
    isGenderValid: false,
    isBusinessName: false,
    isRefferBy: false,
    isEmailValid: false,
    isChecked: false,
    // Add more validation flags as needed
  });
  const [agreeTermsAndConditions, setAgreeTermsAndConditions] = useState(false);

  const handleCheckboxTermsAndConditions = () => {
    setAgreeTermsAndConditions(!agreeTermsAndConditions)
  }

  const updateValidation = (fieldName, isValid) => {
    setRedValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: isValid,
    }));
  };

  useEffect(() => {
    setShowProgress(false);
    setProgressBarPercentage(0);
  }, []);

  useEffect(() => {
    if (registerRes != null) {
      setUserToken(registerRes);
      window.location.reload();
    }
  }, [registerRes]);

  useEffect(() => {
    if (storedUser) {
      setUserInfo({
        ...userInfo,
        firstName: storedUser.firstName,
        lastName: storedUser.lastName,
        email: storedUser.email,
      });
    }
  }, []);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showPrivacyModal = () => {
    setIsPrivacyPolicyModalAvaliable(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleOkPrivacyModal = () => {
    setIsPrivacyPolicyModalAvaliable(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCheckboxChangeRefferBy = (e) => {
    setShowRefer(e.target.checked);
    setUserInfo({ ...userInfo, referred_code: "" });
    updateValidation("isRefferBy", false);
  };

  // useEffect(() => {
  //  console.log("user info tech ::::", userInfo);
  // }, [userInfo, showRefer]);

  const showCookiesModal = () => {
    setIsCookiesPolicyModalAvailable(true);
  };

  const handleCancelPrivacyModal = () => {
    setIsPrivacyPolicyModalAvaliable(false);
  };
  const handleCancelCookiesModal = () => {
    setIsCookiesPolicyModalAvailable(false);
  };

  const handleOkCookiesModal = () => {
    setIsCookiesPolicyModalAvailable(false);
  };

  const handleFirstName = async (e) => {
    const re = storedUser
      ? /^\s*[a-zA-Z0-9\s!#_.%&*\-{}]*\s*$/
      : /^[a-zA-ZÀ-ÖØ-öø-ÿ-' ]*$/;
    if (!re.test(String(e.target.value))) {
      setAlertMessageFName("No numbers or special characters are allowed.");
      updateValidation("isFirstNameValid", true);
      setUserInfo({ ...userInfo, firstName: e.target.value });
      return;
    } else if (e.target.value && e.target.value.length > 30) {
      updateValidation("isFirstNameValid", true);
      setAlertMessageFName("Maximum length is 30 characters.");
      setUserInfo({ ...userInfo, firstName: e.target.value });
      return;
    } else {
      updateValidation("isFirstNameValid", false);
      setAlertMessageFName("");
    }

    setUserInfo({ ...userInfo, firstName: e.target.value });
    updateValidation("isFirstNameValid", false);
  };

  const handleLastName = async (e) => {
    const re = storedUser
      ? /^\s*[a-zA-Z0-9\s!#_.%&*\-{}]*\s*$/
      : /^[a-zA-ZÀ-ÖØ-öø-ÿ-' ]*$/;
    if (!re.test(String(e.target.value))) {
      updateValidation("isLastNameValid", true);
      setAlertMessageLName("No numbers or special characters are allowed.");
      setUserInfo({ ...userInfo, lastName: e.target.value });
      return;
    } else if (e.target.value && e.target.value.length > 30) {
      updateValidation("isLastNameValid", true);
      setAlertMessageLName("Maximum length is 30 characters.");
      setUserInfo({ ...userInfo, lastName: e.target.value });
      return;
    } else {
      updateValidation("isLastNameValid", false);
      setAlertMessageLName("");
    }

    setUserInfo({ ...userInfo, lastName: e.target.value });
    updateValidation("isLastNameValid", false);
  };

  const validateNames = (type, value, nameType) => {
    const allowNumbers = storedUser; // Check if storedUser is true
  
    // Define the regular expression based on whether storedUser is true or false
    const re = allowNumbers
      ? /^\s*[a-zA-Z0-9\s!#_.%&*\-{}]*\s*$/
      : /^[a-zA-ZÀ-ÖØ-öø-ÿ-' ]*$/;
  
    if (re.test(value)) {
      // The name is valid
      updateValidation(type, false);
      return true;
      // You can proceed with the form submission or any other actions.
    } else {
      // The name is invalid
      openNotificationWithIcon(
        "info",
        "info",
        `No numbers or special characters are allowed in ${nameType}`
      );
      updateValidation(type, true);
      return false;
      // You may prevent the form submission or take other actions.
    }
  };
  

  const handleEmail = async (e) => {
    let re = emailRegExp;
    if (!re.test(e.target.value)) {
      updateValidation("isEmailValid", true);
      setAlertMessageEmail("Invalid Email type");
      setEmModelVisible(true);
    } else if (re.test(e.target.value)) {
      updateValidation("isEmailValid", false);
      setEmModelVisible(false);
      setAlertMessageEmail("");
    } else if (e.target.value && e.target.value.length > 70)
      setAlertMessageEmail("Maximum length is 70 characters.");
    else {
      updateValidation("isEmailValid", false);
      setAlertMessageEmail("");
      setEmModelVisible(false);
    }
    // updateValidation('isEmailValid', false);
    setUserInfo({ ...userInfo, email: e.target.value });
  };

  const handleEmailRefer = async (e) => {
    let re = emailRegExp;
    if (!re.test(e.target.value)) {
      updateValidation("isRefferBy", true);
      setRefModelVisible(true);
    } else if (re.test(e.target.value)) {
      setRefModelVisible(false);
      setAlertRefMessageEmail("");
    } else if (e.target.value && e.target.value.length > 70) {
      updateValidation("isRefferBy", true);
      setAlertRefMessageEmail("Maximum length is 70 characters.");
    } else {
      setAlertRefMessageEmail("");
    }
    setUserInfo({ ...userInfo, referred_code: e.target.value });
    updateValidation("isRefferBy", false);
  };

  /**
   * Following function is to handle change of phone number field in the form.
   * @author : Vinit
   */
  const handlePhoneNumber = (value, data) => {
    setDialCode(data.dialCode);
    setUserInfo({
      ...userInfo,
      phoneNumber: value.includes("+") ? value : "+" + value,
    });
    updateValidation("isPhoneNumber", false);
    return setAlertMessagePhoneNumber("");
  };

  const handleGender = (value) => {
    console.log("gender:", value);
    // if (value === "") {
    //   setAlertMessageGender("Please select you gender.");
    //   updateValidation("isGenderValid", true);
    //   setUserInfo({ ...userInfo, gender: value });
    //   return;
    // }

    // updateValidation("isGenderValid", false);
    // setAlertMessageGender("");
    setUserInfo({ ...userInfo, gender: value });
  };

  const onSignUp = async (value) => {
    let trimmedFname = userInfo.firstName.trim();
    let trimmedLname = userInfo.lastName.trim();

    if (trimmedFname === "") {
      updateValidation("isFirstNameValid", true);
      return setAlertMessageFName("First name is mandatory.");
    } else {
      updateValidation("isFirstNameValid", false);
    }
    if (!storedUser) {
      if (trimmedFname.split(" ").length > 1) {
        updateValidation("isFirstNameValid", true);
        return setAlertMessageFName("No empty space allowed in First Name.");
      } else {
        updateValidation("isFirstNameValid", false);
      }

      if (trimmedLname.split(" ").length > 1) {
        updateValidation("isLastNameValid", true);
        return setAlertMessageLName("No empty space allowed in Last Name.");
      } else {
        updateValidation("isLastNameValid", false);
      }
    }
    if (trimmedLname === "") {
      updateValidation("isLastNameValid", true);
      return setAlertMessageLName("Last name is mandatory.");
    } else {
      updateValidation("isLastNameValid", false);
    }

    if (trimmedFname && trimmedLname) {
      let firstNameValid = validateNames(
        "isFirstNameValid",
        trimmedFname,
        "first name"
      );
      let lastNameValid = validateNames(
        "isLastNameValid",
        trimmedLname,
        "last name"
      );

      if (!firstNameValid || !lastNameValid) {
        return;
      }
    } else if (trimmedFname) {
      let firstNameValid = validateNames(
        "isFirstNameValid",
        trimmedFname,
        "first name",
      );
      if (!firstNameValid) {
        return;
      }
    } else if (trimmedLname) {
      let lastNameValid = validateNames(
        "isLastNameValid",
        trimmedLname,
        "last name"
      );
      if (!lastNameValid) {
        return;
      }
    }

    if (alertMessageEmail.length > 0) {
      updateValidation("isEmailValid", true);
      return;
    } else {
      updateValidation("isEmailValid", false);
    }

    if (alertMessagePassword.length > 0) {
      updateValidation("isPasswordValid", true);
      return;
    } else {
      updateValidation("isPasswordValid", false);
    }

    if (userInfo.email === "") {
      // updateValidation('isEmailValid', true);
      return setAlertMessageEmail("Email is mandatory.");
    } else {
      updateValidation("isEmailValid", false);
    }

    if (!storedUser && userInfo.password === "") {
      updateValidation("isPasswordValid", true);
      return setAlertMessagePassword("Password is mandatory.");
    } else {
      updateValidation("isPasswordValid", false);
    }

    if (
      userInfo.phoneNumber === "" ||
      userInfo.phoneNumber.length === dialCode.length + 1
    ) {
      updateValidation("isPhoneNumber", true);
      return setAlertMessagePhoneNumber("Phone number is mandatory");
    } else if (userInfo.phoneNumber.length < 10 + dialCode.length) {
      updateValidation("isPhoneNumber", true);
      return setAlertMessagePhoneNumber("Invalid phone number!");
    } else setAlertMessagePhoneNumber("");

    // if (!userInfo.gender || userInfo.gender === "") {
    //   updateValidation("isGenderValid", true);
    //   return setAlertMessageGender("Gender is mandatory.");
    // } else {
    //   updateValidation("isGenderValid", false);
    // }

    if (
      userInfo.referred_code !== "" &&
      !emailRegExp.test(String(userInfo.referred_code))
    ) {
      updateValidation("isRefferBy", true);

      openNotificationWithIcon("error", "Invalid", `Referred By - email.`);
      return;
    } else {
      updateValidation("isRefferBy", false);
    }

    if (showRefer && userInfo.referred_code === "") {
      updateValidation("isRefferBy", true);
      openNotificationWithIcon("error", `Referred By - email is mandatory.`);
      return;
    } else {
      updateValidation("isRefferBy", false);
    }

    if (!agreeTermsAndConditions) {
      openNotificationWithIcon("error", "Invalid", `Please agree to the terms and conditions before proceeding.`);
      return
    }

    const emailChecked = await AuthApi.checkEmail({ email: userInfo.email });

    if (emailChecked.success) {
      setIsLoading(true);
      const fullName = userInfo.firstName
        .replace(/\s/g, "")
        .concat(userInfo.lastName.replace(/\s/g, ""));
      let techRegisterRes = await AuthApi.register({
        firstName: userInfo.firstName.replace(/\s/g, ""),
        lastName: userInfo.lastName.replace(/\s/g, ""),
        email: userInfo.email,
        password: userInfo.password,
        confirm_password: userInfo.password,
        phoneNumber: userInfo.phoneNumber,
        gender: userInfo.gender,
        userType: "technician",
        referred_code: userInfo.referred_code,
        provider: storedUser ? "google" : "email",
        uniqueName:fullName.toLowerCase(),
      });

      localStorage.setItem("tetch_token", techRegisterRes.token.accessToken);
      // console.log("My console for techRegisterRes", techRegisterRes);
      if (techRegisterRes.user) {
        let dataToSend = {
          tagName: "techOnboard",
          technicianObject: techRegisterRes.user,
        };
        socket.emit("send-GTM-tag-tech-onboard", dataToSend);
        // mixpanel code//
        mixpanel.identify(techRegisterRes.user.email);
        mixpanel.track("Technician - signup successfull");
        // mixpanel code//

        // Sending GA4 tag
        sendDataToGTM({
          event: "tech_registration",
          tech_id: techRegisterRes.user.technician.id,
          environment: process.env.REACT_APP_URL.split("/")[2],
        });

        setRegister(techRegisterRes.user);
        openNotificationWithIcon(
          "success",
          "Success",
          "Technician registered successfully."
        );
        setIsLoading(false);
        onNext();
      }
    } else {
      openNotificationWithIcon("error", "Error", "Email already registered.");
      updateValidation("isEmailValid", true);
    }
  };

  return (
    <Container className="tech-signup-page">
      <Modal
        title="Terms & Conditions"
        className="app-confirm-modal"
        closable={false}
        footer={[
          <button
            className="btn app-btn"
            key="submit"
            type="primary"
            onClick={handleCancel}
          >
            Close
          </button>,
        ]}
        visible={isModalVisible}
        onOk={handleOk}
      >
        {TermsCondition()}
      </Modal>

      <Modal
        title="Privacy Policy"
        className="app-confirm-modal"
        closable={false}
        footer={[
          <button
            className="btn app-btn"
            key="submit"
            type="primary"
            onClick={handleCancelPrivacyModal}
          >
            Close
          </button>,
        ]}
        visible={isPrivacyPolicyModalAvailable}
        onOk={handleOkPrivacyModal}
      >
        {privacyPolicy()}
      </Modal>

      <Modal
        title="Cookies Policy"
        className="app-confirm-modal"
        closable={false}
        footer={[
          <button
            className="btn app-btn"
            key="submit"
            type="primary"
            onClick={handleCancelCookiesModal}
          >
            Close
          </button>,
        ]}
        visible={isCookiesPolicyModalAvailable}
        onOk={handleOkCookiesModal}
      >
        {cookiePolicy()}
      </Modal>

      <div style={{ maxWidth: "665px", width: "100%" , gap: "20px"}}>
        <HeadingAndSubHeading
          heading={"Apply to be a Geek!"}
          subHeading={
            "Imagine using your tech smarts to make a great salary, on your own terms. You’ll be your own boss, and work from wherever you are, whenever you want. At Geeker, we’re looking for talented technicians like you, to help you make this into your reality (while helping others too!)"
          }
        />

        <Form className="tech-signup-form" onFinish={onSignUp} {...layout}>
          <FormSectionContainer className="tech-signup-form-section-contaier tech-signup-name-container">
            <Col className="tech-signup-name-container-inside tech-signup-column">
              <FormItem
                name="firstName"
                label="First Name"
                className="mt-3 mb-1"
              >
                <span></span>
                <TextInput
                  name="firstName"
                  placeholder="First Name"
                  value={userInfo.firstName}
                  type="text"
                  disabled={false}
                  className="tech-signup-input-field"
                  onChange={handleFirstName}
                  showRedBorder={redValidations.isFirstNameValid}
                />
              </FormItem>
              {(alertMessageFName !== "" || alertMessageLName !== "") && (
                <div className="tech-signup-error-message">
                  {alertMessageFName !== "" && (
                    <div className="input-error-msg">{alertMessageFName}</div>
                  )}
                </div>
              )}
            </Col>
            <Col className="tech-signup-name-container-inside tech-signup-column">
              <FormItem name="lastName" label="Last Name" className="mt-3 mb-1">
                <span></span>

                <TextInput
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  disabled={false}
                  value={userInfo.lastName}
                  className="tech-signup-input-field"
                  onChange={handleLastName}
                  showRedBorder={redValidations.isLastNameValid}
                />
              </FormItem>
              {(alertMessageFName !== "" || alertMessageLName !== "") && (
                <div className="tech-signup-error-message">
                  {alertMessageLName !== "" && (
                    <div className="input-error-msg">{alertMessageLName}</div>
                  )}
                </div>
              )}
            </Col>
          </FormSectionContainer>

          <FormSectionContainer className="tech-signup-form-section-contaier">
            <Col className="tech-signup-column tech-signup-other-container">
              <FormItem name="email" label="Email" className="mt-3 mb-1">
                <TextInput
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={userInfo.email}
                  disabled={storedUser}
                  className="tech-signup-input-field"
                  onChange={handleEmail}
                  onBlur={() => setEmModelVisible(false)}
                  showRedBorder={redValidations.isEmailValid}
                />

                <ValidatorModelForEmail visible={emModelVisible} height="90px">
                  <p style={{ fontSize: "15px", margin: 0 }}>
                    Please enter a Valid email!
                  </p>
                </ValidatorModelForEmail>
              </FormItem>
              {alertMessageEmail !== "" && (
                <div className="input-error-msg">{alertMessageEmail}</div>
              )}

              {!storedUser && (
                <FormItem
                  name="password"
                  label="Password"
                  className="mt-3 mb-1"
                >
                  <PasswordInput
                    name="Password"
                    placeholder="Password"
                    type="password"
                    className="tech-signup-input-field"
                    onFocus={() => setPwModelVisible(true)}
                    onBlur={() => setPwModelVisible(false)}
                    value={userInfo.password}
                    onChange={(e) => {
                      updateValidation("isPasswordValid", false);
                      setUserInfo({ ...userInfo, password: e.target.value });
                    }}
                    showError={redValidations.isPasswordValid}
                  />
                  <ValidatorModel visible={pwModelVisible}>
                    <PasswordValidator
                      inputText={userInfo.password}
                      setAlertMessagePassword={setAlertMessagePassword}
                    />
                  </ValidatorModel>
                </FormItem>
              )}
              {alertMessagePassword !== "" && (
                <div className="input-error-msg">{alertMessagePassword}</div>
              )}
              <Col className="mt-3">
                <FormItem
                  name="phonenumber"
                  label="Phone Number"
                  className="phn-num-font mb-20"
                >
                  <PhoneInput
                    value={userInfo.phoneNumber}
                    countryCodeEditable={false}
                    onChange={handlePhoneNumber}
                    country="us"
                    onlyCountries={["in", "gr", "us", "ca", "gb", "ph", "il"]}
                    className={`phn-input-tech-sign-up ${
                      redValidations.isPhoneNumber ? "error-input" : ""
                    }`}
                  />
                </FormItem>
                {alertMessagePhoneNumber !== "" && (
                  <div className="input-error-msg">
                    {alertMessagePhoneNumber}
                  </div>
                )}

                <FormItem name="gender" label="Gender [optional]" className="mt-3 mb-1">
                  <BasicDropDown
                    name="gender"
                    dropDownOptions={genders}
                    setValue={handleGender}
                    divClass="ant-form-item-control-input-content inputDivClass-gender-desktop"
                  />
                </FormItem>
                {/* {alertMessageGender !== "" && (
                  <div className="input-error-msg">{alertMessageGender}</div>
                )} */}

                <div className="d-flex mt-3">
                  <AntCheckbox
                    onChange={handleCheckboxChangeRefferBy}
                    className="personalUseCheckbox"
                    style={{ maxWidth: "428px" }}
                    id="business-check-btn"
                  >
                    <p
                      className="grey-color-text"
                      style={{ fontSize: "15px", display: "inline" }}
                    >
                      Were you introduced to our services through a referral?
                    </p>
                  </AntCheckbox>
                </div>
                
                {showRefer && (
                  <FormItem name="refrred" label="" className="mt-3 mb-1">
                    <TextInput
                      name="refrred"
                      placeholder="Referral's Email (optional)"
                      type="email"
                      disabled={false}
                      value={userInfo.referred_code}
                      className="tech-signup-input-field"
                      onChange={handleEmailRefer}
                      // onBlur={() => setRefModelVisible(false)}
                      showRedBorder={
                        redValidations.isRefferBy || refModelVisible
                      }
                    />

                    <ValidatorModelForEmail
                      visible={refModelVisible}
                      height="90px"
                    >
                      <p style={{ fontSize: "15px", margin: 0 }}>
                        Please enter a valid email!
                      </p>
                    </ValidatorModelForEmail>
                  </FormItem>
                )}
                {alertRefMessageEmail !== "" && (
                  <div className="input-error-msg">{alertRefMessageEmail}</div>
                )}
              </Col>
            </Col>
          </FormSectionContainer>

          <FormSectionContainer className="tech-signup-form-section-contaier mb-4">
            <Col className="text-center mt-3">
              <TerminaryRow>
                <Col className="d-flex align-items-center mb-3">
                  <AntCheckbox
                    onChange={handleCheckboxTermsAndConditions}
                    checked={agreeTermsAndConditions}
                    className="personalUseCheckbox"
                    id="termsAndConditions"
                  >
                    <span className="grey-color-text" style={{ fontSize: "15px", fontWeight: "400" }}>
                      I agree to the{' '}
                      <StyledLink onClick={(e) => {
                        e.preventDefault();
                        showModal();
                      }}>
                        Terms & Conditions
                      </StyledLink>{' '}
                      and{' '}
                      <StyledLink onClick={(e) => {
                        e.preventDefault();
                        showPrivacyModal();
                      }}>
                        Policy
                      </StyledLink>{' '}
                      and{' '}
                      <StyledLink onClick={(e) => {
                        e.preventDefault();
                        showCookiesModal();
                      }}>
                        Cookies
                      </StyledLink>

                    </span>
                  </AntCheckbox>
                </Col>
              </TerminaryRow>
            </Col>
          </FormSectionContainer>

          <FormSectionContainer className="tech-signup-form-section-contaier tech-signup-btn-container">
            <Col className="create-account-btn mb-3">
              <Button
                htmltype="submit"
                className="app-btn"
                type="primary"
                disabled={isLoading}
              >
                <span></span>
                {isLoading ? <Spin className="spinner" /> : <>Create Account</>}
              </Button>
            </Col>
            <Col>
              <label
                style={{ color: "#708390", fontSize: "15px", margin: 0 }}
                htmlFor="Signin"
              >
                {" "}
                Already have an account{" "}
                <Link to="/login" style={{ color: "#01D4D5" }}>
                  Sign In
                </Link>
              </label>
            </Col>
          </FormSectionContainer>
        </Form>
      </div>
    </Container>
  );
}

const Container = styled(Col)`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

const FormSectionContainer = styled(Row)`
  width: 100%;
  margin-bottom: none;

  @media screen and (max-width: 763px) {
    //   display:block !important;
    .ant-col-12 {
      width: 100% !important;
      max-width: none !important;
    }
  }
`;

const TerminaryRow = styled(Row)`
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;
  align-content: center !important;
  @media screen and (max-width: 763px) {
    display: block !important;
    .ant-col-12 {
      width: 100% !important;
      max-width: none !important;
    }
  }
`;

const StyledLink = styled.a`
  color: #01D4D5;
  text-decoration: underline;
  cursor: pointer;
`;

export default TechRegister;
