import apiClient from "./index";
import { getUserIdFromCookie } from "utils";


export async function uploadFile(data) {
    const userId = getUserIdFromCookie()
    return apiClient.post(`/jobfile?cuId=${userId}`, data).then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
  }
  export async function listOfUploadFile(data) {
      const userId = getUserIdFromCookie()
      return apiClient.post(`/jobfile/list?cuId=${userId}`, data).then((response) => {
        if (response) {
          return response.data;
        }
        return Promise.reject();
      });
    }

    export async function deleteFile(file) {
        const userId = getUserIdFromCookie()
        return apiClient.post(`/jobfile/deleteFile?cuId=${userId}`, file).then((response) => {
          if (response) {
            return response.data;
          }
          return Promise.reject();
        });
      }

      export async function downloadFile(file) {
          const userId = getUserIdFromCookie()
          return apiClient.post(`/jobfile/downloadFile?cuId=${userId}`, file).then((response) => {
            if (response) {
              return response.data;
            }
            return Promise.reject();
          });
        }