import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useLocation } from "react-router";
import { useUser } from "../context/useContext";
import { useTools } from "../context/toolContext";
import * as JobApi from "../api/job.api";
import { useMediaQuery } from "react-responsive";
import { useFetchInvite } from "../api/invite.api";
import { isMobile, isTablet } from "react-device-detect";
import * as PublicApi from "../api/public.api";

const PublicRoute = ({ component: C, props: cProps, ...rest }) => {
  const { user } = useUser();
  const location = useLocation();
  // console.log("Page location is ",location)
  let detailsJobId = false;
  let jobStatus = false;
  // const isMobile = useMediaQuery({ maxWidth: 480 });
  // const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isiPad = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const {
    setJobId,
    setTypeForDetails,
    setStepDeciderDashboard,
    setActiveMenu,
  } = useTools();
  const urlParams = new URLSearchParams(location.search);
  const inviteCode = urlParams.get("inviteCode") || "nothing";
  const { data: inviteData } = useFetchInvite(inviteCode);

  if (urlParams.get("jobId")) {
    detailsJobId = urlParams.get("jobId");
  }
  if (urlParams.get("status")) {
    jobStatus = urlParams.get("status");
  }
  if (detailsJobId && jobStatus === "acceptjob") {
    ChangeToJobDetailsPage(detailsJobId);
  }

  async function ChangeToJobDetailsPage(jobId) {
    let type = "noapply";
    const theJob = JobApi.retrieveJob(jobId);

    theJob.then((res) => {
      if (
        res.technician == undefined ||
        res.technician === "" ||
        res.technician == null
      ) {
        type = "apply";
      }
      setJobId(jobId);
      setTypeForDetails(type);
      setStepDeciderDashboard(6);
      setActiveMenu("job-reports");
      localStorage.removeItem("checkjobdata");
    });
  }
  if (urlParams.get("t") && urlParams.get("t") === "sub") {
    setStepDeciderDashboard(10);
  }

  let to = "/dashboard";
  if(user && user.userType && user.userType === 'technician'){
    to = "/dashboard/technician";    
  }

  const shortUrlId = urlParams.get("q");
  if (shortUrlId) {
    const fetchData = async () => {
      try {
        const redirectUrlResponse = await PublicApi.getShortenUrl(shortUrlId);
        const { redirectURL, isLinkOpened } = redirectUrlResponse.result;

        console.log('redirectUrl :: :::');
        if (!isLinkOpened) {
          await PublicApi.updateShortenUrl(shortUrlId)
        }
        window.location.href = redirectURL;
      } catch (error) {
        console.error('Error fetching shortened URL:', error.message);
      }
    };
    fetchData();
  }

  // let to = user && user.usertype === "technician" ? "/dashboard/technician" : "/dashboard";

  // useEffect(()=>{
  //   if(user && user.usertype === "technician") {
  //     console.log("Looking for URL ", {to})
  //     to = `/dashboard/${user.usertype}`
  //   }
  // },[user])
  
  if (
    user &&
    user.userType === "technician" &&
    user.technician &&
    user.technician.registrationStatus === "softwares"
  ) {
    console.log("LoginCallback redirecting to /technician/register_steps?t=softwares publicRoute of line no. 71")
    // console.log("user private ::::::::", user);
    to = "/technician/register_steps?t=softwares";
  }
  // if (
  //   user &&
  //   user.userType === "customer" &&
  //   location &&
  //   location.pathname &&
  //   location.pathname === "/technician-details"
  // ) {
  //   to = `/technician-details-setup${location.search}`;
  // }
  const currentPath = location.pathname;
  const pathSegments = currentPath.split('/');
  const techUniqueName = pathSegments.filter(Boolean).pop();
 
  
  if ( user && user.userType === "customer" && location && currentPath && pathSegments && pathSegments.includes("tech-profile") && techUniqueName) {
    to = `/tech/${techUniqueName}`;
  }

  

  if (urlParams.get("slackJobid")) {
    to = `${to}?slackJobid=${urlParams.get("slackJobid")}`;
  }
  if (urlParams.get("message")) {
    to = `${to}?message=${urlParams.get("message")}`;
  }

  if (
    user &&
    user.userType === "customer" &&
    location &&
    location.pathname &&
    location.pathname === "/business-plan"
  ) {
    to = `/buy-business-plan${location.search}&page=CompleteYourPurchase`;
  }

  if (
    user &&
    user.userType === "customer" &&
    location &&
    location.pathname &&
    location.pathname === "/customer/start-profile-setup"
  ) {
    to = `/customer/profile-setup${location.search}`;
  }

  if (
    !user &&
    location &&
    location.pathname &&
    location.pathname === "/customer/start-profile-setup" &&
    (isMobile || isiPad || isTablet) &&
    !localStorage.getItem("mobile")
  ) {
    return <Redirect to="/create-job" />;
  }

  if (
    !user &&
    location &&
    location.pathname &&
    location.pathname === "/customer/register" &&
    inviteData &&
    inviteData?.inviteCode &&
    (isMobile || isiPad || isTablet) &&
    !localStorage.getItem("mobile")
  ) {
    console.log("inviteCode>>>>>>>>>>>>>", inviteData?.inviteCode);
    return (
      <Redirect
        to={`/customer/mobile-sign-up?inviteCode=${inviteData?.inviteCode}`}
      />
    );
  }

  if (urlParams.get("chatScreen")) {
    const jobID = urlParams.get("chatScreen");
    window.sessionStorage.setItem("chatScreen", jobID);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? (
          <C {...props} {...cProps} match={rest.computedMatch} />
        ) : (
          <Redirect to={to} />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  props: PropTypes.object.isRequired,
};

export default PublicRoute;
