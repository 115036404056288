import React from 'react'
import Subscription from '../Subscription'

function VipMemberShip({user}) {
  return (
    <>
     <Subscription user={user} VipMemberShip={true} />
    </>
  )
}

export default VipMemberShip