import styled from "styled-components";

const LayoutContainer = styled.div`
  display: flex;
  padding-top: var(--5, 40px);
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  height: 100vh;
  width: 100vw;

  //width: 23.4375rem;
  //height: 50.75rem;

  background: linear-gradient(180deg, #e8f5ff 10.42%, #fff 100%);
`;

const Header = styled.div`
  width: 100vw;
  height: 3.375rem;
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex: 1 0 0;
  align-self: stretch;
`;

const Footer = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 1.4375rem;
  flex-shrink: 0;

  position: relative;
  background: #063f4b;
`;

const CompanyLogo = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: var(--1, 0.5rem);

  img {
    width: 5.625rem;
    height: 2.0625rem;
  }
`;

const NavigationBackIcon = styled.div`
  cursor: pointer;

  width: 3.0625rem;
  height: 3.1875rem;
  flex-shrink: 0;
`;

export {
  LayoutContainer,
  Header,
  Content,
  Footer,
  CompanyLogo,
  NavigationBackIcon,
};
