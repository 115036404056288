import React, { useEffect, useState } from "react";
import CheckInCircle from "components/CheckInCircle";
import goodLady from "../../../../../assets/images/goodgirl.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const YourJobLabel = ({ job,buttonKeyForChatPanel }) => {
  return (
    <>
      <div className="your-job-main-container d-flex justify-content-center align-items-center flex-column" style={buttonKeyForChatPanel ? {top:"1185px",bottom:"unset"} : {}}>
        <div className="your-job-container">
          <div className="top-label-div">
            <div className="top-label-div-icon">
              <CheckInCircle checkStyle={{ color: "black" }} />
            </div>
            <div className="top-label-div-text">
              <span className="top-label-div-text-2">
                Get help in 10 minutes
              </span>
            </div>
          </div>
          <div className="m-btm-label">
            <span className="your-job-text">Your Job</span>
          </div>
          <div className="m-btm-label d-flex software-name-div  align-items-center">
            <img
              className="image-software-label "
              src={job?.software?.blob_image}
            />
            <div className="d-flex justify-content-center align-items-center">
              <Box className="common-div-label mr-1">
                <Typography
                  className="software-label-one"
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  {job?.software?.name.split(" ")[0]}
                </Typography>
              </Box>
              {job?.software?.name?.split(" ")[1] && (
                <Box className="software-second-name-div">
                  <Typography
                    className="software-label-two"
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    {job?.software?.name?.split(" ")[1]}
                  </Typography>
                </Box>
              )}
            </div>
          </div>
          <div className="label-desc">
            <span className="label-desc-span">
              {job?.issueDescription && job?.issueDescription?.length > 80
                ? job?.issueDescription.substring(0, 65) + "..."
                : job?.issueDescription}
            </span>
          </div>
        </div>
        <img src={goodLady} className="good-girl-img" />
      </div>
    </>
  );
};

export default YourJobLabel;
