import React from "react";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import { openNotificationWithIcon } from "../../utils/index";
import { deleteInvitesUserByParam } from "../../api/users.api";
import { useSocket } from "../../context/socketContext";

function InviteDeleteUserModal({
  setDeleteModal,
  deleteModal,
  selectDeleteRows,
  setRefreshInvitesList,
  setDeleteButton,
  setSelectAll,
}) {
  const randomInt = Math.floor(Math.random() * 100) + 1;
  const { socket } = useSocket();

  const deleteInviteUsers = async () => {
    setDeleteModal(false);
    let response = await deleteInvitesUserByParam(selectDeleteRows);
    setSelectAll(false);
    setRefreshInvitesList(randomInt);
    setDeleteButton(false);
    socket.emit("deleteInviteUsers", response?.deletedUsers);
    if (response.success) {
      openNotificationWithIcon("success", "Users deleted successfully");
    }
  };

  const handleCancel = () => {
    // Handle the "No" button action here
    setDeleteModal(false);
  };

  return (
    <Modal
      className=""
      closable={true}
      onCancel={handleCancel}
      visible={deleteModal}
      maskStyle={{ backgroundColor: "#DCE6EDCF" }}
      maskClosable={true}
      width={800}
      footer={[
        <div className="modal-flex-get-help-now">
          <Button
            className="btn app-btn app-btn-light-blue modal-footer-btn"
            onClick={() => {
              setDeleteModal(false);
            }}
            key="no"
          >
            <span></span>No
          </Button>

          <Button
            id="confirm-create-new"
            className="btn app-btn job-accept-btn modal-footer-btn"
            onClick={deleteInviteUsers}
            key="yes"
          >
            <span></span>Yes
          </Button>
        </div>,
      ]}
    >
      <div className="">
        <span
          className="inviteUserDelete"
          style={{ fontSize: 20, paddingTop: "40px" }}
        >
          Are you sure you want to delete selected users ?
        </span>
      </div>
    </Modal>
  );
}

export default InviteDeleteUserModal;
