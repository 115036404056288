import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const InputField = ({
  hiddenEyeStyle,
  inputType,
  placeHolder,
  inputClass,
  inputDivClass,
  showEye,
  value,
  onChange,
  name,
  showPassword,
  setShowPassword,
  onClick,
}) => {
  const toggleEyeIcon = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className={inputDivClass}>
        {/* password visible */}
        {showEye && showPassword && (
          <VisibilityIcon className={hiddenEyeStyle} onClick={toggleEyeIcon} />
        )}

        {/* password not visible */}
        {showEye && !showPassword && (
          <VisibilityOffIcon
            className={hiddenEyeStyle}
            onClick={toggleEyeIcon}
          />
        )}

        <input
          type={inputType}
          placeholder={placeHolder}
          className={inputClass}
          value={value}
          onChange={onChange}
          name={name}
          onClick={onClick}
        />
      </div>
    </>
  );
};

export default InputField;
