import apiClient from "./index";

export async function getSoftwareCategoryList(params) {
  return apiClient.get("/software-category", { params }).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
