import React from "react";
import { withRouter } from "react-router-dom";
import { useUser } from "../../../../../context/useContext";
import Routes from "../../../../../router";
import Layout from "../../../partial/layout";

function Start(props) {
  const { user } = useUser();
  const appTheme = "ThemeLight";
  return <Layout id={appTheme} content={<Routes user={user} {...props} />} />;
}

export default withRouter(Start);
