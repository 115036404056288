import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from "./authContext";
import axios from 'axios';
import * as UserApi from "../api/users.api";

const VolumeContext = createContext();

export const VolumeProvider = ({ children }) => {
const { user } = useAuth(); 

const [volume, setVolume] = useState(user?.volume || 1);
const [isMuted, setIsMuted] = useState(user?.isMuted || false);

useEffect(() => {
    const fetchUserVolumeSetting = async () => {
      try {
        if(user){
        // Fetch owner user information
        console.log("fetchUserVolumeSetting", user);
        // const userInfo = await UserApi.getUserById(user?.id); 
        // console.log("fetchUserVolumeSetting ::userInfo", userInfo);

        setVolume(user?.volume || 1);  // Default to 1 if volume is not available
        setIsMuted(user?.isMuted || false);  // Default to false if isMuted is not available
    }
    } catch (error) {
        console.error('Error fetching volume settings:', error);
      }
    };

    fetchUserVolumeSetting();
  }, [user]);
  
  const updateVolume = async(newVolume, muteStatus) => {
    console.log("toggleMute:: updateVolume",newVolume,muteStatus);
    
    setVolume(newVolume);
    setIsMuted(muteStatus);
    await UserApi.updateUserVolumeSettings(user?.id, newVolume, muteStatus);
  };

  const toggleMute = () => {
    console.log("toggleMute:: inside");
    const newMuteStatus = !isMuted;
    console.log("toggleMute:: newMuteStatus",newMuteStatus);
    setIsMuted(newMuteStatus);
    console.log("toggleMute:: newMuteStatus both",volume,newMuteStatus);
    updateVolume(volume, newMuteStatus);
  };


  const contextValue = {
    volume,
    setVolume,
    isMuted,
    toggleMute,
    updateVolume,
    effectiveVolume: isMuted ? 0 : volume
  };

  return (
    <VolumeContext.Provider value={contextValue}>
      {children}
    </VolumeContext.Provider>
  );
};

export const useVolume = () => {
  const context = useContext(VolumeContext);
  if (context === undefined) {
    throw new Error('useVolume must be used within a VolumeProvider');
  }
  return context;
};

// Make sure to export VolumeContext if needed elsewhere
export { VolumeContext };