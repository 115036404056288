import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import PrinterSVG from "assets/icons/printer.svg";
import FolderSVG from "assets/icons/folder.svg";
import NetworkSVG from "assets/icons/network.svg";
import ShieldSVG from "assets/icons/shield.svg";
import RightSVG from "assets/icons/righticon.svg";
import GmailSVG from "assets/icons/gmail.svg";
import PowerBISvg from "assets/icons/power_bi.svg";
import PowerPointSVG from "assets/icons/power_point.svg";
import QuickBookSVG from "assets/icons/quick_book.svg";
import OutlookSVG from "assets/icons/outlook.svg";
import ExcelSVG from "assets/icons/excel.svg";
import CheckSVG from "assets/icons/check_circle.svg";

const PrinterIcon = (props) => <ReactSVG src={PrinterSVG} {...props} />;
const FolderIcon = (props) => <ReactSVG src={FolderSVG} {...props} />;
const NetworkIcon = (props) => <ReactSVG src={NetworkSVG} {...props} />;
const ShieldIcon = (props) => <ReactSVG src={ShieldSVG} {...props} />;
const RightArrowIcon = (props) => <ReactSVG src={RightSVG} {...props} />;
const GmailIcon = (props) => <ReactSVG src={GmailSVG} {...props} />;
const PowerBIcon = (props) => <ReactSVG src={PowerBISvg} {...props} />;
const PowerPointIcon = (props) => <ReactSVG src={PowerPointSVG} {...props} />;
const QuickBookIcon = (props) => <ReactSVG src={QuickBookSVG} {...props} />;
const OutlookIcon = (props) => <ReactSVG src={OutlookSVG} {...props} />;
const ExcelIcon = (props) => <ReactSVG src={ExcelSVG} {...props} />;
const CheckIcon = (props) => <ReactSVG src={CheckSVG} {...props} />;

const GroupIcons = styled(ReactSVG)`
  display: flex;
  width: 2rem;
  padding: 0.5rem 0.44038rem 0.375rem 0.43463rem;
  justify-content: center;
  align-items: center;

  border-radius: 0.45456rem;
  background: rgba(231, 231, 231, 0.5);

  svg {
    width: 1.125rem;
    height: 1.125rem;
    flex-shrink: 0;
  }
`;

export {
  PrinterIcon,
  CheckIcon,
  FolderIcon,
  NetworkIcon,
  ShieldIcon,
  RightArrowIcon,
  GmailIcon,
  PowerBIcon,
  PowerPointIcon,
  QuickBookIcon,
  OutlookIcon,
  ExcelIcon,
  GroupIcons,
};
