import React from "react";

import {
  ButtonContainer,
  Group,
} from "../../../Components/styled.components/Button_with_icon.styled";
import styles from "../../../css.module/Button_with_icon.module.css";

const ButtonWithIcon = (props) => (
  <>
    <ButtonContainer {...props}>
      <Group>
        {props.icon && props.icon}
        <h1 className={styles.h1 + " pl-1"}>{props.title}</h1>
      </Group>
    </ButtonContainer>
  </>
);
export default ButtonWithIcon;
