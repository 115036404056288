import Button from "@mui/material/Button";
import React, { useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import hiddenEye from "../../../../../assets/images/hiddeneye.png";
import Heading from "../components/Heading";
import InputField from "../components/InputField";
import SubHeading from "../components/SubHeading";
import * as AuthApi from "../../../../../api/auth.api";
import * as JobApi from "../../../../../api/job.api";
import * as CustomerApi from "../../../../../api/customers.api";
import * as UserApi from "../../../../../api/users.api";
import { INACTIVE_ACCOUNT_STATUS_MSG } from "../../../../../constants";
import {
  isLiveUser,
  openNotificationWithIcon,
  checkAndHandleDateMatchForSchedule,
  fetchChatOrCreate,
  checkPendingStatus,
  checkPendingStatusOfASAP,
} from "utils";
import { useJob } from "../../../../../context/jobContext";
import { useNotifications } from "../../../../../context/notificationContext";
import { useSocket } from "../../../../../context/socketContext";
import { Modal, Spin } from "antd";
import { getUserIdFromCookie, setCookie } from "../../../../../utils";

const SignIn = ({
  setUserId,
  userEmail,
  setToken,
  jobIdFromUrl,
  setMobileSignUpStepNumber,
  signUpFlowStepsObj,
  job,
  setUserEmail,
  techIdFromUrl,
}) => {
  const { socket } = useSocket();
  const { createNotification, fetchNotifications } = useNotifications();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const { updateJob } = useJob();
  const [isLoading, setIsLoading] = useState(false);
  const [userFirstName, setUserFirstName] = useState();
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const [pendingJobModal, setPendingJobModal] = useState(false);
  const [createJobStatus, setCreateJobStatus] = useState(false);
  const [lastPendingSoftware, setLastPendingSoftware] = useState("");
  const [inProgressMessage, setInProgressMessage] = useState("");
  const [scheduleMsg, setScheduleMsg] = useState(false);
  const [jobIdDecline, setJobIdDecline] = useState(false);
  const [customerValid, setCustomerValid] = useState(false);
  const [loginUser, setLoginUser] = useState();
  const [latestPendingJobToUpdate, setLatestPendingJobToUpdate] = useState();
  const [disableBackToDashbord, setDisableBackToDashbord] = useState(false);
  // const InprogressJobMsg = pendingJobModal ? (
  //   <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
  //     {inProgressMessage}
  //   </span>
  // ) : (
  //   <span></span>
  // );

  const message = scheduleMsg ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      One of your previous job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b> is already
      scheduled with a technician. Are you sure you want to create a new job
      post?if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  ) : (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      We are still looking for a technician for your existing job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b>. Are you sure
      you want to create a new job post? if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  );

  useEffect(() => {
    if (localStorage.getItem("userEmail")) {
      setUserEmail(localStorage.getItem("userEmail"));
      setUserFirstName(localStorage.getItem("userfirstName"));
    } else {
      window.location.href = `mobile?jobId=${jobIdFromUrl}&page=InputEmail`;
    }
  }, []);

  const checkCustomerHaveCard = async (user, customerTypeValid) => {
    console.log("etheaarea")
    try {

      if (user && !user?.activeStatus) {
        await updateJob(jobIdFromUrl, {
          customer: user?.customer?.id,
          guestJob: false,
          cardPreAuthorization: false,
        });
        openNotificationWithIcon("info", "Info", INACTIVE_ACCOUNT_STATUS_MSG);
        window.location.href = "/";
      }
      if (user && user.customer) {
        mixpanel.identify(user.email);
        const ownerId = user?.ownerId;
        
        let ownerStripeId = "";
        if (ownerId) {
          const ownerUserDetails = await UserApi.getUserById(ownerId);
      
          if (ownerUserDetails?.customer?.stripe_id) {
            ownerStripeId = ownerUserDetails?.customer?.stripe_id;
          } else {
            openNotificationWithIcon(
              "info",
              "Info",
              `Please contact your organization owner to add card to proceed with this job!`
            );
            window.location.href = "/";
          }
        }

        const userIdFromCookie = getUserIdFromCookie()
        if(userIdFromCookie){
          const data = await JobApi.scheduleAcceptedJobs({
            customer: user?.customer?.id,
          });
          const primaryScheduleTimes = data.primaryScheduleTimes;
          // Call the function to check and handle date match
          checkAndHandleDateMatchForSchedule(
            primaryScheduleTimes,
            job?.primarySchedule,
            jobIdFromUrl,
            user
          );
        }

        let customer_info =
          await CustomerApi.checkIfOrganisationHasSubscription({
            user: user,
            liveUser: user.customer.customerType === "live",
          });
        if (
          customer_info.has_card_or_subscription === false &&
          customerTypeValid
        ) {
          // mixpanel code//
          mixpanel.track("Customer - Ask Credit Card");
          mixpanel.people.set({
            $first_name: user.firstName,
            $last_name: user.lastName,
          });
          // mixpanel code//
          const updatedJob = await updateJob(jobIdFromUrl, {
            customer: user.customer.id,
            guestJob: false,
            cardPreAuthorization: false,
          });
          
          // setMobileSignUpStepNumber(signUpFlowStepsObj["AddCard"])
          if (techIdFromUrl) {
            window.location.href = `mobile?jobId=${jobIdFromUrl}&page=AddCard&technicianId=${techIdFromUrl}`;
          } else {
            window.location.href = `mobile?jobId=${jobIdFromUrl}&page=AddCard`;
          }
        } else {
          const updatedJob = await updateJob(jobIdFromUrl, {
            customer: user.customer.id,
            guestJob: false,
            cardPreAuthorization: false,
          });
        
          // setMobileSignUpStepNumber(signUpFlowStepsObj["PhoneNumber"])
          if (techIdFromUrl) {
            window.location.href = `mobile?jobId=${jobIdFromUrl}&page=PhoneNumber&technicianId=${techIdFromUrl}`;
          } else {
            window.location.href = `mobile?jobId=${jobIdFromUrl}&page=PhoneNumber`;
          }
        }
      } 
    } catch (err) {
      console.log("error in checkForCard", err);
    }
  };
  /**
   * Function will check if there are any pending jobs of the customer else it will call push_to_profile_setup function to find technician.
   * @author : Nafees
   */
//  const checkPendingJobs = async (loginUser, customerTypeValid) => {
//     try {
    
//       if (loginUser && loginUser?.customer) {
//         const latestpendingJobs = await JobApi.latestpendingJobs({
//           customer: loginUser.customer.id,
//         });
       
//         setLatestPendingJobToUpdate(latestpendingJobs);

//         let pendingJobs = await checkPendingStatusOfASAP(loginUser);
        
//         if (pendingJobs.schedule_accepted) {
//           setScheduleMsg(true);
//         }
//         let pendingJobsArray=(latestPendingJobToUpdate?.statusJobs?.filter(entry => desiredStatuses.includes(entry.status)))
//         console.log("pending jobs ::", pendingJobsArray[0].jobs[0]._id);
         
//         setJobIdDecline(pendingJobsArray[0].jobs[0]);
//         //console.log("inside the latestJob1212::", lastPendingJob);
//         if (pendingJobs.success) {
//           setLastPendingSoftware(pendingJobs.name);
//           if (
//             pendingJobs.status === "Inprogress" ||
//             pendingJobs.status === "Accepted"
//           ) {
//             setPendingJobModal(true);
//             setInProgressMessage(
//               <span
//                 className="div-font"
//                 style={{ fontSize: 20, paddingTop: "40px" }}
//               >
//                 One of your job of{" "}
//                 <b style={{ fontWeight: "bold" }}>{pendingJobs.name}</b> is
//                 already{" "}
//                 <b style={{ fontWeight: "bold" }}>{pendingJobs.status}</b> with
//                 a technician. Please complete that job first then post a new
//                 job.
//               </span>
//             );
//           } else {
//             setCustomerConfirm(true);
//           }
//         } else {
//           checkCustomerHaveCard(loginUser, customerTypeValid);
//         }
//       }
//     } catch (e) {
//       console.log("Error in checkPendingJobs ", e);
//     }
//   };

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const loginResponse = await AuthApi.login({
      email: userEmail,
      password: password,
    });

    if (loginResponse && loginResponse.success) {
      openNotificationWithIcon(
        "success",
        "Success",
        "User logged in successfully!"
      );
      setCookie(loginResponse.user);
      setToken(loginResponse.token.accessToken);
      setUserId(loginResponse.user.id);
      
      localStorage.removeItem("userEmail");
      sessionStorage.removeItem("ssoUserDetails");
      
      if (loginResponse?.user?.userType === "customer") {
        if (jobIdFromUrl) {
          // const customerTypeValid = (loginResponse.user.customer.customerType ? loginResponse.user.customer.customerType !== 'test' : true);
          const customerTypeValid = true;
       
          setCustomerValid(customerTypeValid);
          setLoginUser(loginResponse.user);
          //checkPendingJobs(loginResponse.user, customerTypeValid);
           checkCustomerHaveCard(loginResponse.user, customerTypeValid)
        } else {
          if (loginResponse.user) {
            let liveUser = await isLiveUser(loginResponse.user);
            let customer_info =
              await CustomerApi.checkIfOrganisationHasSubscription({
                user: loginResponse.user,
                liveUser: liveUser,
              });
            
            if (customer_info.has_card_or_subscription) {
              window.location.href = `/`;
            } else {
              window.location.href = `mobile?jobId=${jobIdFromUrl}&page=PhoneNumber`;
            }
          }
        }
      }
    } else {
      openNotificationWithIcon("error", "Error", "Incorrect Password");
      setIsLoading(false);
    }
  };

  // const closePendingModal = () => {
  //   setCustomerConfirm(false);
  //   setPendingJobModal(false);
  // };

  const backToDashBoardStatus = async () => {
    const updateJobData = {
      cardPreAuthorization: false,
      customer: loginUser?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };

  const push_to_job_summary = async () => {
    setCreateJobStatus(true);
    await updateJob(jobIdDecline, { status: "Declined" });
   //checkCustomerHaveCard(loginUser, customerValid);
  };

  const backToDashBoard = async () => {
    setDisableBackToDashbord(true);
    const updateJobData = {
      cardPreAuthorization: false,
      customer: loginUser?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };

  return (
    <>
      {/* <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={pendingJobModal}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="mobile-dsn-button-css"
              onClick={backToDashBoardStatus}
              key="no"
            >
              <span></span>Back To Dashbord
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {InprogressJobMsg}
          </span>
        </div>
      </Modal>
      <Modal
        className="get-help-now-modal"
        closable={true}
        visible={customerConfirm}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="mobile-dsn-button-css"
              onClick={backToDashBoard}
              key="no"
              disabled={createJobStatus || disableBackToDashbord}
            >
              {disableBackToDashbord ? (
                <Spin size="small" />
              ) : (
                <span>Back To Dashbord</span>
              )}
            </Button>

            <Button
              id="confirm-create-new"
              className="mobile-dsn-button-css"
              onClick={push_to_job_summary}
              key="yes"
              disabled={createJobStatus || disableBackToDashbord}
            >
              {createJobStatus ? (
                <Spin size="small" />
              ) : (
                <span>Create New</span>
              )}
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {message}
          </span>
        </div>
      </Modal> */}
      <div className="headingDiv pl-20-max-width-280 pr-20-max-width-280">
        <div className="d-flex flex-column heading-div">
          <Heading text={`Welcome Back, ${userFirstName}`} />
          <SubHeading text={"Enter your password to get tech help."} />
        </div>
        <form onSubmit={submitForm} className="w-100percent">
          <div className="create-account-main-container">
            <div className="create-account-main-email">
              <span className="create-account-main-text font-nova">
                {userEmail}
              </span>
            </div>
            <InputField
              hiddenEye={hiddenEye}
              showEye={true}
              hiddenEyeStyle={"hidden-icon"}
              inputType={showPassword ? "text" : "password"}
              placeHolder={"Password"}
              inputDivClass={"inputDivClass-password"}
              inputClass={"inputClass-Firstname"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name={"password"}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <Button
              variant="contained"
              onClick={submitForm}
              className="contained-signup-btn"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spin className="spinner spinner-pos" />
              ) : (
                <span className="contained-signup-btn-text">Log In</span>
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignIn;
