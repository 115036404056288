import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  INACTIVE_ACCOUNT_STATUS_MSG,
  PAYMENT_TYPES,
  PURCHASE_BTN_TITLE,
  PURCHASE_CONFIRMATION_TITLE,
  PURCHASE_REC_HEADING,
  VIP_BTN_TITLE,
} from "../../../../constants/index";
import "../JobRecordings/jobrecordings.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import PopCornBucketIcon from "../../../../assets/images/popcorn.svg";
import { chargeUser } from "api/stripeAccount.api";
import { openNotificationWithIcon, returnStripeId } from "utils";
import Loader from "components/Loader";
import { Spin } from "antd";
import Tooltip from '@mui/material/Tooltip';
const JobRecordingAction = React.memo(({ user, job,setMeetingRecordingDetail,meetingRecordingDetail }) => {
  const [purchaseRecording, setPurchaseRecording] = useState(false);
  return (
    <>
        <Tooltip
          title={!user?.activeStatus ? INACTIVE_ACCOUNT_STATUS_MSG :""}
          placement="top-end"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: "16px",
                fontWeight: "bold",
                padding: "7px",
              },
            },
          }}>
      <div className={"d-flex flex-row justify-content-end p-3"}>
          <Button
            id="purchase-btn-style"
            className="mx-3"
            variant="outlined"
            style={
              !user?.activeStatus ? { opacity: 0.5} : {}
            }
            disabled={!user?.activeStatus}
            onClick={() => setPurchaseRecording(true)}
          >
            {PURCHASE_BTN_TITLE}
          </Button>
        {/* <Button id="vip-btn-style" variant="contained">
          {VIP_BTN_TITLE}
        </Button> */}
      </div>
      </Tooltip>
      <PurchaseRecordingModal
        purchaseRecording={purchaseRecording}
        setPurchaseRecording={setPurchaseRecording}
        setMeetingRecordingDetail={setMeetingRecordingDetail}
        meetingRecordingDetail={meetingRecordingDetail}
        user={user}
        job={job}
        />
    </>
  );
});

const PurchaseRecordingModal = React.memo(({
  purchaseRecording,
  setPurchaseRecording,
  job,
  user,
  setMeetingRecordingDetail,
  meetingRecordingDetail
}) => {
  const [loader, setLoader] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const closeModal = () => {
    setPurchaseRecording(false);
  };

  /**
   *
   * @returns null
   * @description : This event is called when customer  want to purchase meeting recordings
   * @author : Jagroop
   */
  const chargeForRecording = React.useCallback(async () => {
    try {
      setLoader(true);
      const { stripeId = "" } = await returnStripeId(user);
      const userId = user?.id;
      const jobId = job?.id;
      const isLiveUser = user?.customer?.customerType == "live" ? true : false;

      if (stripeId != "" && userId && jobId) {
        const chargeObj = {
          stripe_id: stripeId,
          userId: userId,
          type: PAYMENT_TYPES["RECORDING_PURCHASE"],
          jobId: jobId,
          liveUser: isLiveUser,
          recordingIds :meetingRecordingDetail.recordingIds
        };
        const chargeRes = await chargeUser(chargeObj);
        const message = chargeRes["message"] || "Something went wrong !!";
        if (chargeRes.success) {
          openNotificationWithIcon("success", message);
          setMeetingRecordingDetail((prev) => ({ ...prev, isLocked:false }))
          closeModal();
          setLoader(false);
          
          return;
        }
        openNotificationWithIcon("error", message);
        setLoader(false);
      }
    } catch (error) {
      openNotificationWithIcon("error", "Something went wrong !!");
      setLoader(false);
      console.log("error while charging for recordings", { error: error });
    }
  },[]);

  return (
    <>
      <Modal
        className="buy-session-video-modal"
        open={purchaseRecording}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Spin spinning={loader}>
            <ClearIcon
              style={{ float: "right", cursor: "pointer" }}
              onClick={closeModal}
            ></ClearIcon>
            <div className="d-flex flex-column align-items-center p-2">
              <p className="purchase-rec-head-style">
                {PURCHASE_REC_HEADING}{" "}
                <span>
                  <img
                    src={PopCornBucketIcon}
                    alt="popcorn-bucket-icon"
                    height="40px"
                    width="40px"
                  />
                </span>
              </p>
              <p className="font-weight-bold purchase-confirmation-style m-0 p-0">
                {PURCHASE_CONFIRMATION_TITLE}
              </p>
              <div className={"d-flex flex-wrap-reverse justify-content-center gap-10 p-3"}>
                <Button
                  id="purchase-btn-style"
                  variant="outlined"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  id="vip-btn-style"
                  variant="contained"
                  onClick={chargeForRecording}
                >
                  Complete Purchase!
                </Button>
              </div>
            </div>
          </Spin>
        </Box>
      </Modal>
    </>
  );
});
export default JobRecordingAction;
