import { decryptResponse, getUserIdFromCookie } from "utils";
import apiClient from "./index";


export async function retrieveTechnicianBysomeParams(data) {
  const userId = getUserIdFromCookie()
  // console.log(data, ">>>data");
  return apiClient
    .post(`/technicians/getTechByParams?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function retrieveTechnician(technicianId) {
  const userId = getUserIdFromCookie()
  return apiClient.get(`/technicians/${technicianId}?cuId=${userId}`).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function updateTechnician(technicianId, data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .put(`/technicians/${technicianId}?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

export async function updateTechnicianWithParams(technicianId, data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .put(`/technicians/params/${technicianId}?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}


/**
 * @params : technicianId: Type(String), data: Type(Object)
 * @response : api that updates technician object with result as pass or fail for each software after submission of test
 * @author :Kartik
 **/
export async function updateTechnicianResult(technicianId, data) {
  const userId = getUserIdFromCookie()
  return apiClient
    .put(`/technicians/updateResult/${technicianId}?cuId=${userId}`, data)
    .then((res) => {
      if (res) {
        return res.data;
      }
      return Promise.reject();
    });
}


export async function getTechnicianRating(params) {
  const userId = getUserIdFromCookie()
  return apiClient
    .get(`/technicians/rating/${params.technician}?cuId=${userId}`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return Promise.reject();
    });
}

/**
 * @params : data: Type(Object)
 * @response : api that sends response to backend to get online technicians
 * @author :Sahil
 **/

export async function getOnlineTechnicians(data) {
  const userId = getUserIdFromCookie()
  return apiClient.post(`/technicians/onlineTechs?cuId=${userId}`, data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

/**
 * @params : data: Type(Object)
 * @response : api that get tech details by user id
 * @author :Kartar Singh
 **/

export async function getTechnicianDetailesByUserId(id) {
  // console.log("data<<",id)
  return apiClient
    .get(`/public/getTechByUserid/${id}`)
    .then((response) => {
      if (response) {
        if(response?.data?.succes == false || response?.data?.status == false){
          return response.data
        }
        const decryptedData = decryptResponse(response.data)
        return decryptedData;
      }
      return Promise.reject();
    });
}

/**
 * @params : data: Type(String)
 * @response : api that get tech details by user unique name
 * @author :Mritunjay 
 **/

export async function getTechnicianDetailesByUniqueName(name) {
  return apiClient
    .get(`/public/getTechByUniqueName/${name}`)
    .then((response) => {
      if (response) {
        if(response?.data?.succes == false || response?.data?.status == false){
          return response.data
        }
        const decryptedData = decryptResponse(response.data)
        return decryptedData;
      }
      return Promise.reject();
    });
}

/**
 * @params : data: Type(Object)
 * @response : api that sends email to admin
 * @author :Kartar Singh
 **/

export async function sendEmailToAdminsForTechReview(data) {
  const userId = getUserIdFromCookie()
  
  return apiClient
    .post(`/technicians/sendEmailToReview?cuId=${userId}`, data)
    .then((response) => {
      if (response) {
        // console.log("response >>>>>>", response);
        return response.data;
      }
      return Promise.reject();
    });
}
