import React, { useState, useEffect } from "react";

import * as CustomerApi from "../../../../api/customers.api";

import * as JobApi from "../../../../api/job.api";

import { isLiveUser } from "../../../../utils";

import { Table, Modal } from "antd";

import { Row, Col, Button } from "react-bootstrap";

import { openNotificationWithIcon } from "../../../../utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCreditCard } from "@fortawesome/free-regular-svg-icons";

import AddCardForm from "./addCardForm";
import ReChargePayment from "./reChargePayment";
import { useSocket } from '../../../../context/socketContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa'; // Import the icon component
import { useTools } from '../../../../context/toolContext';
import TooltipMessage from './ReactiveToolTip';
let liveUser = true;

const CheckoutForm = ({
  user,

  value,

  onNext,

  onPrev,

  isModalOpen,

  setIsModalOpen,

  cardsInfo,

  setCardsInfo,

  newCardAdded,

  setNewCardAdded,

  showCards,

  setDisableButton,
}) => {
  const [cardInfoUpdated, setCardInfoUpdated] = useState(cardsInfo);
  const { setMakepayment, makepayment } = useTools();

  const [cards_to_display, setCardsToDisplay] = useState([]);
  const [reCharge, setRecharge] = useState(false);

  const [isInprogressJobs, setIsInprogressJobs] = useState(false);
  const [hideRecharge, setHideRecharge] = useState(false);
  const [jobRecords, setJobRecords] = useState([]);
  const { socket } = useSocket();

  useEffect(() => {
    setCardInfoUpdated(cardsInfo);
  }, [cardsInfo]);

  function markCardButtonClick(record) {
    const inProgressJobs = async () => {
      const pendingJobsFinal = await JobApi.getInprogressJobs({
        id: user.customer.id,
      });

      if (pendingJobsFinal > 0) {
        //setIsInprogressJobs(true)

        callNotification();
      } else {
        markCardAsDefault(record);
      }
    };

    inProgressJobs();
  }

  function removeButtonClick(record) {
    if (user && user.customer) {
      const inProgressJobs = async () => {
        const pendingJobsFinal = await JobApi.getInprogressJobs({
          id: user.customer.id,
        })
        if (pendingJobsFinal > 0) {
          //setIsInprogressJobs(true)

          callNotification();
        } else {
          removeCustomerCard(record);
        }
      };

      inProgressJobs();
    }
  }

  // useEffect(() => {

  //   if (user && user.customer) {

  //     const inProgressJobs = async () => {

  //       const pendingJobsFinal = await JobApi.getInprogressJobs({

  //         id: user.customer.id,

  //       });

  //       if (pendingJobsFinal > 0) {

  //         setIsInprogressJobs(true)

  //       }

  //     };

  //     inProgressJobs();

  //   }

  //   pendingJobs();

  // }, [user]);

  useEffect(() => {
    (async () => {
      if (user) {
        liveUser = await isLiveUser(user);
        let res = await JobApi.paymentFailedJobCustomer(user?.customer?.id);
        setJobRecords(res?.recordsExist);
      }
      socket.on("inativeUserAccountBadge", async () => {
        let res = await JobApi.paymentFailedJobCustomer(user?.customer?.id);
        setJobRecords(res?.recordsExist);
      });
    })();
  }, [socket, user]);

  const showModal = () => {
    // setIsModalVisible(true);
  };

  const markCardAsDefault = (card_id) => {
    Modal.confirm({
      title: "Are you sure you want to make this card as default?",

      okText: "Yes",

      className: "app-confirm-modal",

      cancelText: "No",

      async onOk() {
        await CustomerApi.updateDefaultCard({
          liveUser: liveUser,

          card_id: card_id,

          customer_id: user.customer.stripe_id,
        });

        fetchMyCardsInfo();
      },
    });
  };

  const removeCustomerCard = (card_id) => {
    Modal.confirm({
      title: "Are you sure you want to remove this card?",

      okText: "Yes",

      className: "app-confirm-modal",

      cancelText: "No",

      async onOk() {
        await CustomerApi.removeCard({
          liveUser: liveUser,

          card_id: card_id,

          customer_id: user.customer.stripe_id,
        });

        fetchMyCardsInfo();
      },
    });
  };

  const columns = [
    {
      title: "",

      dataIndex: "last4",

      render: (text, record) => (
        <>
          {" "}
          {record.default_card ? (
            <a href="#">
              {" "}
              <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
              {"Credit Card ************" + text}
              <span className="default_card_tag">Default</span>
              {" "}
            </a>
          ) : (
            <>
              {" "}
              <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
              {"Credit Card ************" + text}{" "}
            </>
          )}
          {" "}
        </>
      ),
    },

    {
      title: "",

      dataIndex: "exp_year",

      render: (text, record) => (
        <>
          {" "}
          <div>
            {" "}
            {!record.default_card && (
              <Button
                className="btn app-btn app-btn-light-blue app-btn-small mr-md-3"
                onClick={(e) => {
                  markCardButtonClick(record.id);
                }}
              >
                Mark as default<span></span>
                {" "}
              </Button>
            )}
            {" "}
            <Button
              className="btn app-btn app-btn-light-blue app-btn-small "
              onClick={(e) => {
                removeButtonClick(record.id);
              }}
            >
              Remove<span></span>
              {" "}
            </Button>
            {" "}
          </div>
          {" "}
        </>
      ),

      onHeaderCell: (column) => {
        return {
          onClick: () => {
            showModal();
          },
        };
      },
    },
  ];

  async function fetchMyCardsInfo() {
    if (user && user.customer.stripe_id && user.customer.stripe_id !== "") {
      const customer_info = await CustomerApi.getStripeCustomerCardsInfo({
        liveUser: liveUser,

        stripe_id: user.customer.stripe_id,
      });

      if (customer_info && customer_info.data) {
        setCardsInfo(customer_info.data);

        setCardInfoUpdated(() => {
          return [...customer_info.data];
        });
      }
    }
  }

  useEffect(() => {
    fetchMyCardsInfo();
  }, [user]);

  const callNotification = () => {
    return openNotificationWithIcon(
      "error",

      "Error",

      "You currently have an active job. Please add another card and set it as the default to replace the existing one."
    );
  };

  useEffect(() => {
    const seen = new Set();

    if (cardInfoUpdated.length > 0) {
      const cards_data = cardInfoUpdated.filter((el) => {
        const duplicate = seen.has(el.fingerprint);

        seen.add(el.fingerprint);

        return !duplicate;
      });

      setCardsToDisplay(cards_data);
    }
  }, [cardInfoUpdated]);

  return (
    <Row>
      {" "}
      <ReChargePayment
        user={user}
        setRecharge={setRecharge}
        reCharge={reCharge}
        setHideRecharge={setHideRecharge}
        setJobRecords={setJobRecords}
        jobRecords={jobRecords}
        setMakepayment={setMakepayment}

      />

      <AddCardForm
        user={user}
        cardsInfo={cardInfoUpdated}
        setCardsInfo={setCardInfoUpdated}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setNewCardAdded={setNewCardAdded}
        setDisableButton={setDisableButton}
      />
      {" "}
      {showCards && (
        <Col md="12" className="payment-methods-outer p-0 pb-4">
          {" "}
          <Row>
            {" "}
            <Col md="6" className="text-left pl-5 py-3">
              <h6>Payment Methods</h6>
              {" "}
            </Col>
            {" "}
            <Col md="6" className="pr-md-5 py-3 d-flex justify-content-end align-items-center">
              {" "}
              <Button
                className="btn app-btn app-btn-small app-btn-light-blue-remove mr-2 rechargebtn mobile-button"
                onClick={() => setIsModalOpen(true)}
              >
                Add Method<span></span>
                {" "}
              </Button>
              {" "}
              {makepayment && (
                <>
                  <Button
                    className="btn app-btn app-btn-small app-btn-light-blue-remove rechargebtn mobile-button"
                    onClick={() => setRecharge(true)}
                  >
                    Reactivate my account<span></span>
                  </Button>
                  <TooltipMessage />
                </>
              )}

            </Col>
            {" "}
            <Col md="12" className="px-4 px-md-5">
              {" "}
              {cardInfoUpdated.length > 0 ? (
                <>
                  {" "}
                  <Table
                    dataSource={cards_to_display}
                    columns={columns}
                    rowKey="id"
                    className="myCardTable"
                    pagination={true}
                  />
                  {" "}
                </>
              ) : (
                <table className="table empty-table">
                  {" "}
                  <tbody>
                    {" "}
                    <tr>
                      {" "}
                      <td colSpan="2" className="text-center">
                        No cards available. Please click
                        on Add Method button to add card.
                        {" "}
                      </td>
                      {" "}
                    </tr>
                    {" "}
                  </tbody>
                  {" "}
                </table>
              )}
              {" "}
            </Col>
            {" "}
          </Row>
          {" "}
        </Col>
      )}
      {" "}
    </Row>
  );
};

export default CheckoutForm;
