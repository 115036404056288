import React, { lazy, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateProblem } from "../../../../../stores/devices/mobile/Job/Slice";
import commonStyles from "../../../../css.module/common.module.css";
import { useUser } from "../../../../../context/useContext";
import { useLocation } from "react-router";
import * as JobService from "../../../../../api/job.api";

const ButtonWithIcon = lazy(
  () => import("../../../components/Buttons/ButtonWithIcon")
);
// const SeeMoreLink = lazy( () => import("../../../../Components/styled.components/link.styled").then() );
export default function StepThree() {
  const history = useHistory();
  const dispatch = useDispatch();
  const MobileJobSlice = useSelector((state) => state.JobSlice);
  // const [maxList, setMaxList] = useState(3);
  const { user } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const technicianUserId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const softwareIdFromUrl = queryParams.get("softwareId")
    ? queryParams.get("softwareId")
    : false;
  const repost = queryParams.get("repost") ? queryParams.get("repost") : false;
  const [subOption, setSubOption] = useState();

  useEffect(() => {
    (async () => {
      if (jobIdFromUrl) {
        const jobData = await JobService.retrieveJob(jobIdFromUrl);
        if (jobData) {
          // console.log("subOption from previous job ");
          setSubOption(jobData.subOption);
        }
      }
    })();
  }, []);

  const handleSelectProblem = async (Problem) => {
    await dispatch(updateProblem(Problem));
    if (user) {
      //Handling posting a job using technician's unique url while logged in.
      if (softwareIdFromUrl && technicianUserId) {
        history.push(
          `/issue-description?softwareId=${softwareIdFromUrl}&technicianId=${technicianUserId}`
        );
        return;
      }
      //Handling Post again with same tech.
      if (jobIdFromUrl && technicianUserId) {
        history.push(
          `/issue-description?jobId=${jobIdFromUrl}&repost=true&technicianId=${technicianUserId}`
        );
        return;
      }
      //Handling Post again.
      if (jobIdFromUrl && repost) {
        history.push(`/issue-description?jobId=${jobIdFromUrl}&repost=true`);
        return;
      }
      //Handling Posting a draft job.
      if (jobIdFromUrl) {
        history.push(`/issue-description?jobId=${jobIdFromUrl}`);
        return;
      }
      if (technicianUserId) {
        history.push(`/issue-description?technicianId=${technicianUserId}`);
      } else {
        history.push(`/issue-description`);
      }
    } else {
      //Handling posting a job using technician's unique url while logged out.
      if (softwareIdFromUrl && technicianUserId) {
        history.push(
          `/select-description?softwareId=${softwareIdFromUrl}&technicianId=${technicianUserId}`
        );
        return;
      }
      if (technicianUserId) {
        history.push(`/select-description?technicianId=${technicianUserId}`);
      } else {
        history.push(`/select-description`);
      }
    }
  };

  return (
    <>
      <div className={commonStyles.box}>
        <div className={commonStyles.title_group}>
          <span className={commonStyles.h2_title}>
            Let's get your{" "}
            {MobileJobSlice.Selected ? MobileJobSlice.Selected.name : null}{" "}
            Problem solved!
          </span>
          <span className={commonStyles.paragraph_subtitle}>
            Select your issue. We'll find the right expert. If you don't see it,
            click 'other/not sure'.
          </span>
        </div>
        <div className={commonStyles.boxes}>
          <div className={commonStyles.frame_category}>
            {MobileJobSlice.Selected &&
              MobileJobSlice.Selected.sub_option.map((el, stateIndex) =>
                (
                  <ButtonWithIcon
                    className={
                      el.name === subOption
                        ? commonStyles.selected_software_border +
                          " " +
                          commonStyles.small_category_button
                        : commonStyles.small_category_button
                    }
                    onClick={() => handleSelectProblem(el)}
                    key={stateIndex}
                    title={el.name}
                  />
                ) 
              )}
            {/* {maxList >= MobileJobSlice.Selected.sub_option.length ? null : (
              <div className={commonStyles.border_bottom}>
                <span
                  onClick={() =>
                    setMaxList(MobileJobSlice.Selected.sub_option.length)
                  }
                  className={commonStyles.link}
                >
                  <span className={commonStyles.link_frame}>See More</span>
                </span>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}
