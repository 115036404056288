import React from "react";
import Logo from "../Logo";
// import { Container, Row, Col } from "react-bootstrap";
import LogoWithoutClick from "../Logo/LogoWithoutClickability";

import DownArrow from "assets/images/down-arrow.png";

const logoutGuestUser = () => {
  localStorage.removeItem("mobile");
  localStorage.removeItem("token");
  localStorage.removeItem("tetch_token");
  window.location.href = "/";
};

const LogoWithHeading = ({ heading, user, jobFlowStepNumber, currentTab }) => {
  return (
    <>
      <div className="container w-90 justify-content-center">
        <div className="col grid-center mb-5">
          <div className="col-md-6 col-sm-6 mb-3 grid-center">
            {jobFlowStepNumber && jobFlowStepNumber == 1 ? (
              <LogoWithoutClick fromJobFlow={true} />
            ) : (
              <Logo fromJobFlow={true} user={user} />
            )}
          </div>
          <div className="col-md-8 col-sm-8 grid-center">
            <span
              className={
                "headingWithLogo headingWithLogo-max-width-600 " +
                (heading === "Help should arrive in less than:" || heading === "Job Summary"
                  ? "font-size-32-imp"
                  : "")
              }
            >
              {heading}
            </span>
          </div>
          {jobFlowStepNumber === 2 && currentTab === "createYourAccount" && (
            <div className="d-flex justify-content-center w-100p">
              <span
                className={
                  "headingWithLogo headingWithLogo-max-width-600 color-turcose-imp"
                }
              >
                Reminder! First 6 minutes are free
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LogoWithHeading;
