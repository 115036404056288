import logo from "assets/images/newLogo.png";
import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styles from "../css.module/Header_Mobile.module.css";
import {
  ChevronLeftDiv,
  HeaderMobileDiv,
  LogoDiv,
} from "./styled.components/header.styled";
import GeekerLogo from "utils/GeekerLogo";

const HeaderMobile = ({ showBackButton, setShowHeaderAndFooter }) => {
  const pageHistory = useHistory();
  const location = useLocation();
  const technicianId = new URLSearchParams(location.search).get("technicianId");

  const handleGoBack = () => {
    switch (location.pathname) {
      case "/select-software":
        // pageHistory.push("/create-job");
        pageHistory.push(technicianId ? `/create-job?technicianId=${technicianId}` : "/create-job");
        break;
      case "/select-problem":
        // pageHistory.push("/select-software");
        pageHistory.push(technicianId ? `/select-software?technicianId=${technicianId}` : "/select-software");
        break;
      case "/select-description":
        // pageHistory.push("/select-problem");
        pageHistory.push(technicianId ? `/select-problem?technicianId=${technicianId}` : "/select-problem");
        break;
      case "/post-job":
        setShowHeaderAndFooter(false);
        pageHistory.push("/");
        break;
      case "/select-category":
        pageHistory.push(`/post-job${location.search}`);
        break;
      case "/select-area":
        pageHistory.push(`/select-category${location.search}`);
        break;
      case "/issue-description":
        pageHistory.push(`/select-area${location.search}`);
        break;
      default:
        return null;
    }
  };

  return (
    <HeaderMobileDiv>
      {showBackButton && (
        <ChevronLeftDiv onClick={() => handleGoBack()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="51"
            viewBox="0 0 49 51"
            fill="none"
          >
            <path
              d="M27 30.061L21 24.061L27 18.061"
              stroke="#708390"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ChevronLeftDiv>
      )}
      <LogoDiv>
        <GeekerLogo className={styles.logo_image} logo={logo} CoBrandingWidth="200px" />
      </LogoDiv>
    </HeaderMobileDiv>
  );
};

export default HeaderMobile;
