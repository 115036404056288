import { configureStore } from "@reduxjs/toolkit";
import MobileJobSlice from "./devices/mobile/Job/Slice";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const persistedState = loadState();

// configure redux toolkit store
const store = configureStore({
  preloadedState: persistedState,
  reducer: {
    JobSlice: MobileJobSlice,
  },
});

store.subscribe(() => {
  saveState(store.getState());
});
export default store;
