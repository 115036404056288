import React from "react";
import ProgressCheck from "../ProgressCheck";

const ProgressBar = ({ mobileSignUpStepNumber, page }) => {
  return (
    <div className="d-flex justify-content-center flex-gap">
      {/* Two options for checkColor */}
      {/* grey:#2F3F4C */}
      {/* white */}

      {/* Two options for classForRoundDiv */}
      {/* progressBarTurcoseBackground */}
      {/* progressBarGreyBackground */}
      <ProgressCheck
        classForRoundDiv={`d-flex justify-content-center align-items-center progressBarTurcoseBackground`}
        checkColor={`white`}
        checkHeight={"6"}
        checkWidth={"6"}
      />
      <ProgressCheck
        classForRoundDiv={`d-flex justify-content-center align-items-center ${
          page === "SignIn" ||
          page === "SignUp" ||
          page === "AddCard" ||
          page === "PhoneNumber" ||
          page === "ScheduleJobSummary"
            ? "progressBarTurcoseBackground"
            : "progressBarGreyBackground"
        }`}
        checkColor={`${
          page === "SignIn" ||
          page === "SignUp" ||
          page === "AddCard" ||
          page === "PhoneNumber" ||
          page === "ScheduleJobSummary"
            ? "white"
            : "#2F3F4C"
        }`}
        checkHeight={"6"}
        checkWidth={"6"}
      />
      <ProgressCheck
        classForRoundDiv={`d-flex justify-content-center align-items-center ${
          page === "PhoneNumber" ||
          page === "ScheduleJobSummary" ||
          page === "AddCard"
            ? "progressBarTurcoseBackground"
            : "progressBarGreyBackground"
        }`}
        checkColor={`${
          page === "PhoneNumber" ||
          page === "ScheduleJobSummary" ||
          page === "AddCard"
            ? "white"
            : "#2F3F4C"
        }`}
        checkHeight={"6"}
        checkWidth={"6"}
      />
      <ProgressCheck
        classForRoundDiv={`d-flex justify-content-center align-items-center ${
          page === "ScheduleJobSummary" || page === "PhoneNumber"
            ? "progressBarTurcoseBackground"
            : "progressBarGreyBackground"
        }`}
        checkColor={`${
          page === "ScheduleJobSummary" || page === "PhoneNumber"
            ? "white"
            : "#2F3F4C"
        }`}
        checkHeight={"6"}
        checkWidth={"6"}
      />
    </div>
  );
};

export default ProgressBar;
