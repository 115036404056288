import React from "react";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
const TechAlreadyInMeetModal = ({ setAlreadyInJob, alreadyInJob }) => {
  const MESSAGE =
    "You'll need to complete your current job before you can accept a new one.";

  const offTechAlreadyInMeetModal = () => {
    setAlreadyInJob(false);
  };

  const backToDashboard = () => {
    window.location.href = "/dashboard/technician";
  };

  return (
    <Modal
      style={{ top: 40 }}
      closable={true}
      onCancel={offTechAlreadyInMeetModal}
      visible={alreadyInJob}
      maskStyle={{ backgroundColor: "#DCE6EDCF" }}
      maskClosable={true}
      width={800}
      footer={[
        <Button
          className="btn app-btn app-btn-light-blue modal-footer-btn"
          onClick={backToDashboard}
          key="back-to-dashboard"
        >
          <span></span>Back To Dashbord
        </Button>,

        <Button
          id="confirm-create-new-job-btn"
          className="btn app-btn job-accept-btn modal-footer-btn"
          onClick={offTechAlreadyInMeetModal}
          key="close"
        >
          <span></span>Close
        </Button>,
      ]}
    >
      <div className="">
        <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
          {MESSAGE}
        </span>
      </div>
    </Modal>
  );
};

export default TechAlreadyInMeetModal;
