import React, { useEffect, useState } from "react";
import logo from "../../../../../assets/images/newLogo.png";
import GeekerLogo from "utils/GeekerLogo";
import { getPartnerWithShortName } from "api/partner.api";

const HeadingWithLogo = (props) => {
  const customerPartner = props?.job?.customerPartner;
  const [partnerLogo, setPartnerLogo] = useState(null);

  useEffect(() => {
    async function fetchPartnerLogo() {
      if (customerPartner) {
        const response = await getPartnerWithShortName(customerPartner);
        if (response.success) {
          const newLogo = response.data.coBrandingLogoUrl;
          setPartnerLogo(newLogo);
        }
      }
    }
    fetchPartnerLogo();
  }, [customerPartner]);

  return (
    <div className="heading-with-logo-main-div">
      {partnerLogo ? (
        <GeekerLogo className="help-on-its-way-logo" logo={partnerLogo} width="350px" />
      ) : (
        <GeekerLogo className="help-on-its-way-logo" logo={logo} CoBrandingWidth="250px" />
      )}
    </div>
  );
};

export default HeadingWithLogo;
