import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import SubscriptionFlipCard from "components/SubscriptionFlipCard";
import { getAllPlans } from "../../../../api/subscription.api";
import { isLiveUser } from "../../../../utils";
import Loader from "../../../../components/Loader";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {EXCLUDED_PLAN_NAMES_VIP} from "../../../../constants"
import SubscriptionCard from "../SubscriptionCard";

const SubscriptionPlansModal = ({
  buyPlanInit,
  user,
  showSubscriptionPlansModal,
  setShowSubscriptionPlansModal,
}) => {
  const [allPlans, setAllPlans] = useState();

  useEffect(() => {
    (async () => {
      let liveUser = await isLiveUser(user);
      const allPlandata = await getAllPlans({ liveUser: liveUser });

      let filterPlans = [];
      for (let i = 0; i <= allPlandata.data.length - 1; i++) {
        if (
          allPlandata.data[i] &&
          allPlandata.data[i]["metadata"] &&
          Object.keys(allPlandata.data[i]["metadata"]).length > 0
        ) {
          if (
            allPlandata.data[i]["price"] &&
            allPlandata.data[i]["price"]["type"] &&
            allPlandata.data[i]["price"]["type"] === "recurring"
          ) {
            filterPlans.push(allPlandata.data[i]);
          }
        }
      }
      filterPlans.sort((p1, p2) => p1.price.unit_amount - p2.price.unit_amount);
      setAllPlans(filterPlans);
    })();
  }, []);

  const buySubscriptionPlan = (singlePlanDetails) => {
    // console.log("My console for buying singlePlanDetails", singlePlanDetails);
    if (singlePlanDetails) {
      buyPlanInit(
        singlePlanDetails.id,
        singlePlanDetails.name,
        singlePlanDetails.price.id,
        singlePlanDetails.metadata.total_minutes,
        singlePlanDetails.metadata.discount
      );
      setShowSubscriptionPlansModal(false);
    }
  };

  return (
    <>
   
      <Modal
        closable={false}
        visible={showSubscriptionPlansModal}
        footer={null}
        width={1500}
        className="subscription-plan-modal"
      >
        <div className="position-absolute subscription-modal-close-btn-div">
          <IconButton
            className="subscription-modal-close-btn"
            onClick={() => setShowSubscriptionPlansModal(false)}
          >
            <Close />
          </IconButton>
        </div>
        <Container>
          <Row className="d-flex justify-content-center align-items-center" style={{gap:'30px'}}>
            
            {allPlans ? (
              /* Show data when exist */
              allPlans
                .filter((ele) => !EXCLUDED_PLAN_NAMES_VIP.includes(ele.id)) // Exclude specific plans
                .map((ele) => {
                  return (
                    <Col key={ele.id}>
                      <SubscriptionFlipCard
                        planData={ele}
                        user={user}
                        onYes={buySubscriptionPlan}
                        showSubscriptionPlansModal={showSubscriptionPlansModal}
                      />
                    </Col>
                  );
                })
            ) : (
              /* Show loader until data is available */
              <Loader height="100%" className="mt-5" />
            )}
          </Row>
        </Container>

      </Modal>
    </>
  );
};

export default SubscriptionPlansModal;
