import apiClient from "./index";

export async function ReferpeopleThroughEmail(data) {
  return apiClient.post("/services/refer-people", data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}

export async function klaviyoTrack(data) {
  return apiClient.post("/services/klaviyo-track", data).then((response) => {
    if (response) {
      return response.data;
    }
    return Promise.reject();
  });
}
