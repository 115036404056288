import apiClient from "./index";

// This will remove the uploaded video from server ~ Jagroop
export async function removeVideoResume(data) {
  try {
    let response = await apiClient.post("/uploads/remove-video", data);
    return response.data;
  } catch (err) {
    console.log("error in removing video resume ::: ", err);
    return false;
  }
}
export async function removeProfilePic(data) {
  try {
    let response = await apiClient.post("/uploads/remove-profile", data);
    return response.data;
  } catch (err) {
    console.log("error in removing profile ::: ", err);
    return false;
  }
}
export async function removeResume(data) {
  try {
    let response = await apiClient.post("/uploads/remove-resume", data);
    return response.data;
  } catch (err) {
    console.log("error in removing resume ::: ", err);
    return false;
  }
}
// This API is used to upload video in the backend server ~ Jagroop
export async function uploadVideo(data) {
    try {
      let response = await apiClient.post("/uploads/videoResume", data);
      return response.data;
    } catch (err) {
      console.log("error in upload video resume ::: ", err);
      return false;
    }
  }
