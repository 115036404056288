import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateDescription } from "../../../../../stores/devices/mobile/Job/Slice";
import { Button } from "react-bootstrap";
import { openNotificationWithIcon } from "../../../../../utils";
import commonStyles from "../../../../css.module/common.module.css";
import { handleGetHelpNow } from "../../../JobFlow";
import styles from "../../../../css.module/four.module.css";
import { useUser } from "../../../../../context/useContext";
import { useLocation } from "react-router";
import * as JobService from "../../../../../api/job.api";

export default function StepFour() {
  const dispatch = useDispatch();
  const MobileJobSlice = useSelector((state) => state.JobSlice);
  const history = useHistory();
  const [description, setDescription] = useState("");
  // const [placeholder, setPlaceholder ] = useState('');
  // const [ maxWordCount ] = useState( 500 );
  // const [ wordCounter, setWordCounter ] = useState( 0 );
  const submitButton = useRef();
  const { user } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const technicianUserId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;

  useEffect(() => {
    (async () => {
      if (jobIdFromUrl) {
        const jobData = await JobService.retrieveJob(jobIdFromUrl);
        if (jobData) {
          // console.log("issue description from previous job ", {
          //   issueDescription: jobData.issueDescription,
          //   jobId: jobIdFromUrl,
          // });
          setDescription(jobData.issueDescription);
          return;
        }
      }
      // setPlaceholder(MobileJobSlice.Problem.placeholder);
      if (MobileJobSlice.Description.length > 0) {
        setDescription(MobileJobSlice.Description);
      }
    })();
  }, []);

  // const handleKeyUpEvent = ( e ) => {
  //   const count = e.target ? e.target.value.length : e.key.value.length;
  //   if (count <= 500) {
  //     setWordCounter( count );
  //   } else {
  //     setDescription( e.target ? e.target.value.toString().substring( 0, 500 ) : e.key.toString().substring( 0, 500 ) );
  //   }
  // };

  const handleSubmit = async () => {
    if (description.trim().length === 0) {
      openNotificationWithIcon("error", "Description should not be empty");
      return;
    }
    await dispatch(updateDescription(description));
    submitButton.current.setAttribute("disabled", "disabled");
    handleGetHelpNow(
      history,
      user ? user : false,
      technicianUserId,
      jobIdFromUrl
    );
  };

  return (
    <>
      <div className={commonStyles.page_four_box}>
        <div className={commonStyles.title_group}>
          <span className={commonStyles.h2_title}>
            Tell us more about your issue
          </span>
          <span className={commonStyles.paragraph_subtitle}>
            This helps us connect you with the right specialist.
          </span>
        </div>
        <div className={commonStyles.page_four_boxes}>
          {/* <textarea className={commonStyles.page_four_text_area} value={description} onChange={( e ) => setDescription( e.target ? e.target.value : e?.key.value )} placeholder={placeholder ? `Sample Description: ${placeholder}` : ''} /> */}
          <textarea
            className={commonStyles.page_four_text_area}
            value={description}
            onChange={(e) =>
              setDescription(e.target ? e.target.value : e?.key.value)
            }
            placeholder={
              MobileJobSlice.Problem.placeholder
                ? `Sample Description: ${MobileJobSlice.Problem.placeholder}`
                : ""
            }
          />
          <Button
            ref={submitButton}
            onClick={(event) => handleSubmit(event)}
            className={styles.getHelpNowButton}
          >
            Get Help Now
          </Button>
        </div>
      </div>
    </>
  );
}
