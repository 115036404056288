import React from "react";

const ProgressCheck = ({
  classForRoundDiv,
  checkColor,
  checkHeight,
  checkWidth,
}) => {
  return (
    <div className={classForRoundDiv}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={checkWidth}
        height={checkHeight}
        viewBox="0 0 6 6"
        fill="none"
      >
        <path
          d="M0.90918 3.33166L2.40848 4.57479L5.09145 1.42554"
          stroke={checkColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ProgressCheck;
